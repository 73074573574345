@import 'controls/controls.component.scss';
@import 'progress/progress.component.scss';
@import 'header/header.component.scss';

.wizard-content {
	padding-bottom: 30px;
	.header {
		h2 {
			margin: 16px 0 20px;
			padding: 5px 0px 2px 40px;
			background-repeat: no-repeat;
			background-position: left;
		}
	}
}
