div.numEditRow {
	display: flex;
	justify-content: flex-start;
}

.flexContainer {
	display: flex;
	flex-wrap: unset;
	gap: 16px;
	align-items: baseline;
	margin-top: 4px;
	.query-section-item-value {
		padding-top: 6px;
	}
	&.model {
		flex-direction: column;
		justify-content: flex-start;
		gap: 8px;
		.query-section-item-operation {
			align-items: baseline;
		}
	}
	.flex-container {
		gap: 8px;
	}
}
.flexContainerController {
	display: flex;
	justify-content: space-around;
	align-items: baseline;
	height: 100%;
	width: 100%;

	& .set,
	& .unset {
		max-height: 30px;
	}
}
