$titleFontSize: 14px;
$titleFontWeight: 600;
$bodyFontSize: 12px;
$marginTop: 8px;

.PriorityContentItem {
	> div {
		margin-bottom: 16px;
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.PriorityInformation {
	> * {
		margin-right: 6px;
		&:last-child {
			margin-right: 0;
		}
	}
}

.PriorityPair {
	font-weight: 400;
}

.PriorityKey {
	font-weight: 600;
}
