.le-flex-h-panel {
	width: 100%;
	display: flex;
	box-sizing: border-box;
	flex-wrap: wrap;
	padding: 0.5em;

	&.main-container {
		padding: 0;
	}

	&.push-left {
		justify-content: flex-start;
	}

	&.push-right {
		justify-content: flex-end;
	}

	&.center-h {
		justify-content: center;
	}

	&.center-v {
		align-items: center;
	}

	&.fill-space {
		flex: 1;
	}

	&.center {
		align-items: center;
		justify-content: center;
	}

	&.flex-content {
		flex: 1;
	}

	&.space-middle {
		justify-content: space-between;
	}

	&.space-around {
		justify-content: space-around;
	}
}

.le-flex-v-panel {
	width: 100%;
	display: flex;
	box-sizing: border-box;
	flex-wrap: wrap;
	flex-direction: column;
	padding: 0.5em;

	&.main-container {
		padding: 0;
	}

	&.center-h {
		align-items: center;
	}

	&.center-v {
		justify-content: center;
	}

	&.center {
		align-items: center;
		justify-content: center;
	}

	&.fill-space {
		flex: 1;
	}
}

.le-flex-grid {
	width: 100%;
	list-style: none;
	margin: 2em;
	display: grid;
	grid-gap: 16px;
	grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
}
