@import 'common/node_modules/@dnb-uux-design-system/design-tokens/build/styles/_variables.scss';

div#CustomizeColumnContextMenuId {
	width: 270px;
	padding: 12px 16px 0px;
	color: $color-gray-primary;
	background-color: $color-background-white;
	box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
	0px 1px 1px 2px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

	&.show {
		opacity: 1;
	}
}
