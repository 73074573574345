.configBar {
	display: flex;
	flex-direction: row;
	align-items: stretch;
	justify-content: space-between;
	margin-bottom: 24px;
}

.configBarLeft {
	display: flex;
	align-items: center;
	gap: 16px;
}

.searchInput {
	width: 20em;
	font: inherit;
	border: 1px solid #ccc;
	border-radius: 4px;
	padding: 0.2em 0.8em;
}

.tableWrapper {
	transition: none;
	height: calc(
		100vh - 420px
	); /* This is very hand wavy, but design requested this functionality and the other ways I can think to implement it are even more flaky */
}

.tableWidthWrapper {
	width: calc(100vw - 110px);
}

.paginationWrapper {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	margin-top: 1em;
}
