.checkbox {
	min-width: 13px;
	width: 13px;
	height: 13px;
	background-image: url('./unchecked.svg');
	appearance: inherit;
}

.checkbox.checked {
	background-image: url('./checked.svg');
}

.checkbox.indet {
	background-image: url('./indet.svg');
}
