@import 'login/login.component.scss';
@import 'login/tenants/tenants.component.scss';
@import 'login/saml/saml.component.scss';
@import 'login/saml/logout/logout.component.scss';
@import 'login/saml/error/error.component.scss';

body {
	opacity: 0;
	transition: opacity 166ms ease;

	&.initialized {
		opacity: 1;
	}

	.login-body,
	.update-password-body {
		padding-top: 4em;
		img {
			width: 100%;
			//margin-top: 80px;
		}
		i.fa-spinner {
			vertical-align: baseline;
			margin-left: 0.6em;
		}
		.company-regmark {
			margin-left: 5px;
		}
	}
	.update-password-body {
	}
	.login-content {
		padding-left: 40px;
		border-left: #e7e7e7 solid 1px;
	}
	.form-signin .form-control.error:not(.ng-not-empty) {
		border: #f03213 solid 2px;
	}
}

@media only screen and (max-width: 480px) {
	body {
		.login-content {
			border-left: 0px;
		}
	}
}
