.attr-sidebar {
	font-size: 16px;
	padding: 0 24px;
	border-right: 1px solid lightgray;
	max-width: 300px;
	overflow-wrap: break-word;
}

.attr-sidebar-header {
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 16px;
}

.attr-sidebar-create-button {
	padding: 10px 12px;
	background: none;
	border: 1px solid #aaa;
	border-radius: 6px;

	&-disabled {
		cursor: not-allowed;
	}
}

.attr-group-btn {
	margin: 16px 0;
	cursor: pointer;

	&.active {
		font-weight: bold;
	}

	&-disabled {
		cursor: not-allowed;
	}
}

.attr-sidebar-pagination {
	font-weight: bold;

	button {
		border: none;
		background: none;
	}
}
