@mixin box-shadow($fmt) {
    -webkit-box-shadow: $fmt;
    box-shadow: $fmt;
}
/**
 * simple tooltip, placeholder data-attr name until we clean up qtip plugin code (remove it)
 * current default is to the right, use classnames to make left, top, bottom versions, color variations, etc.
 * stooltip does not have arrow.
 */

[data-stooltip] {
    position: relative;
    &:after {
        content: attr(data-stooltip);
        font-size: 12px;
        display: none;
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translateY(-50%);
        margin-left: 10px;
        padding: 5px;
        border-radius: 3px;
        background: rgba(0,0,0,1);
        color: white;
        white-space: nowrap;
    }
    &:hover {
        &:after {
            display: block;
            z-index: 88;
        }
    }
}

/*
Informational Tooltips
====================================================== */

.tooltip-label {
  display: inline-block;
}
.more-info {
  color: #fff;
  background: #467dba;
  display: inline-block;
  font-weight: bold;
  padding: 0px 7px;
  -moz-border-radius: 50%;
  border-radius: 50%;
  font-size: 11px;
  margin-bottom: -3px;
}
$tolerance: -2px;
$margin-tooltip-arrow: 6px;
$padding-top-bottom-tooltip: 16px;
$padding-right-left-tooltip: 16px;
$tooltip-background-color: rgba(0, 0, 0, .85);
$tooltip-color: #fff;
$tooltip-border-radius: 3px;
$tooltip-fast-transition: .15s;
$tooltip-slow-transition: .65s;
$tooltip-medium-transition: .35s;

@mixin opacity-transition($speed) {
  animation: animate-tooltip $speed;
}
@mixin border-radius($radius) {
  border-radius: $radius;
}
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}
._exradicated-tooltip {
  position: absolute;
  display: block;
  opacity: 1;
  z-index: 999;
}
tooltip {
  @include keyframes(animate-tooltip) {
    0% {
      opacity: 0;
    }
    50% {
      opacity: .5;
    }
    60% {
      opacity: .8;
    }
    70% {
      opacity: .9;
    }
    90% {
      opacity: 1;
    }
  }
  display: inline-block;
  position: relative;
  &._multiline {
    display: block;
  }
  &._slow {
    &._ready {
      tip {
        @include opacity-transition($tooltip-slow-transition);
      }
    }
  }
  &._fast {
    &._ready {

      tip {
        @include opacity-transition($tooltip-fast-transition);
      }
    }
  }
  &._steady {
    &._ready {

      tip {
        @include opacity-transition($tooltip-medium-transition);
      }
    }
  }
  tip {
    @include border-radius($tooltip-border-radius);
    background: $tooltip-background-color;
    color: $tooltip-color;
    display: none;
    line-height: normal;
    height: auto;
    opacity: 0;
    word-wrap: break-word;
    padding: $padding-top-bottom-tooltip $padding-right-left-tooltip;
    position: absolute;
    will-change: top, left, bottom, right;
    .divider {
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: rgba(255, 255, 255, 0.6) solid 1px;
    }
    h4 {
      color: #fff;
      text-align: center;
      font-size: 18px;
      margin-top: 10px;
    }
    a {
      display: block;
      margin-top: 10px;
    }
    p {
      font-size: 14px;
      margin-bottom: 8px;
    }
    ul {
      margin-left: 20px;
      li {
        list-style: disc;
        font-size: 14px;
      }
    }
    &._hidden {
      display: block;
      visibility: hidden;
    }
  }
  &.active {
    tip {
      display: block;
      opacity: 1;
      z-index: 999;
    }
  }
  tip-tip {
    font-size: .95em;
    &._large {
      font-size: 1.1em;
    }
    &._small {
      font-size: .8em;
    }
    #close-button {
      cursor: pointer;
      float: right;
      left: 0;
      font-size: 26px;
      margin-top: -12px;
      position: relative;
    }
  }
  &._top {
    tip {
      left: 50%;
      top: -($margin-tooltip-arrow + $tolerance);
      transform: translateX(-50%) translateY(-100%);
      tip-arrow {
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid $tooltip-background-color;
        content: '';
        height: 0;
        left: 50%;
        margin-left: -$margin-tooltip-arrow;
        position: absolute;
        top: 100%;
        width: 0;
      }
    }
  }
  &._bottom {
    tip {
      right: 50%;
      top: 100%;
      transform: translateY($margin-tooltip-arrow + $tolerance) translateX(50%);
      tip-arrow {
        border-bottom: 6px solid $tooltip-background-color;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        bottom: 100%;
        content: '';
        height: 0;
        left: 50%;
        margin-left: -$margin-tooltip-arrow;
        position: absolute;
        width: 0;
      }
    }
  }
  &._right {
    tip {
      left: 100%;
      top: 50%;
      transform: translateX($margin-tooltip-arrow + $tolerance) translateY(-50%);
      tip-arrow {
        border-bottom: 6px solid transparent;
        border-right: 6px solid $tooltip-background-color;
        border-top: 6px solid transparent;
        content: '';
        height: 0;
        margin-top: -$margin-tooltip-arrow;
        position: absolute;
        right: 100%;
        top: 50%;
        width: 0;
      }
    }
  }
  &._left {
    tip {
      left: -($margin-tooltip-arrow + $tolerance);
      top: 50%;
      transform: translateX(-100%) translateY(-50%);
      tip-arrow {
        border-bottom: 6px solid transparent;
        border-left: 6px solid $tooltip-background-color;
        border-top: 6px solid transparent;
        content: '';
        height: 0;
        left: 100%;
        margin-top: -$margin-tooltip-arrow;
        position: absolute;
        top: 50%;
        width: 0;
      }
    }
  }
}

.show-tooltip,
.more-info {
    pointer-events: all !important;
}

.show-tooltip,
.more-info {
	position: relative;
	cursor: default;
	&:hover > .tooltip_ {
		display: block;
	}
	> .tooltip_ {
		@include box-shadow(0);
		display: none;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		width: 280px;
		font-weight: normal;
		font-size: 125%;
		text-align: left;
		z-index: 10000;
		&.mini {
			width: auto;
			min-width: 33px;
			font-size: smaller;
		}
		&.smaller {
			width: 180px;
		}
		&.small {
			width: 230px;
		}
		&.large {
			width: 500px;
			padding-top: 15px;
		}
		&.nowrap {
			white-space: nowrap;
		}
		h5 {
			color: #eee;
			margin-bottom: 1em;
		}
		p {
			margin-bottom: .5em;
			color: #a0a0a0;
			line-height: 1.2em;
			font-size: 12px;
			&.white {
				color: #fff;
			}
			&.template-description {
				margin-top: .5em;
				font-size: 12px;
			}
			&:last-child:not(.template-description) {
				margin-bottom: 0;
			}
		}
		h3 {
			color: #fff;
			text-transform: none;
			font-size: 18px;
			font-weight: normal;
			margin-bottom: .1em;
		}
		h4 {
			color: #000;
			text-transform: none;
			font-weight: normal;
			font-size: 16px;
			margin: .1em 0em;
			text-align: center;
			&.white {
				color: #fff;
			}
		}
		em {
			color: #fff;
			width: auto;
			font-weight: normal;
			font-size: 12px;
			line-height: 1.2em;
			height: auto;
		}
		ul {
			color: #666;
			list-style: disc outside;
			margin-left: 1em;
		}
		li {
			color: #ccc;
			line-height: 1.2em;
			font-size: 12px;
			&.white {
				color: #fff;
			}
		}
		& > .cover {
			background-color: #222831;
			border-radius: 3px;
			overflow: hidden;
			position: relative;
			z-index: 20;
			padding: 20px;
			padding-right: 10px;
			overflow: hidden;
			white-space: normal;
			&.white-background {
				background-color: #fff;
				border: solid 1px #d3d3d3;
				padding-right: 20px;
				margin-bottom: 15px;
				> h4, p {
					color: #000;
				}
			}
			&.white-font {
				h4, p, li {
					color: #fff;
				}
			}
			&.nowrap {
				white-space: nowrap;
			}
			ul.errors {
				list-style-type: none;
				margin: 0;
			}
		}
		&.idcard > .cover {
			border-radius: 5px;
			box-shadow: 1em 1em 3em -1.5em rgba(0,0,0,.5);
		}
		&:before {
			box-shadow: inherit;
			content: '';
			display: block;
			position: absolute;
			left: calc(50% - 10px);
			height: 20px;
			width: 20px;
			background: #222831;
			transform: rotate(45deg);
			z-index: -10;
		}
		&:after {
			content: '';
			display: block;
			position: absolute;
			left: calc(50% - 10px);
			height: 20px;
			width: 20px;
			background: inherit;
			transform: rotate(45deg);
			z-index: 10;
		}
	}
	&.full-right:not(.top) > .tooltip_ {
		left: calc(100% + 9px);
		top: 50%;
		transform: translateY(-50%);
		&:after,
		&:before {
			top: calc(50% - 10px);
			left: -9px;
		}
	}
	&.full-left:not(.top) > .tooltip_ {
		left: calc(150% + 21px);
		top: 50%;
		transform: translateY(-50%);
		&:after,
		&:before {
			top: calc(50% - 10px);
			left: calc(150% - 10px);
		}
	}
	&.left > .tooltip_ {
		left: 40%;
		transform: translateX(-25%);

		&:after,
		&:before {
			left: calc(25% - 8px);
		}
	}
	&.right > .tooltip_ {
		right: 50%;
		transform: translateX(25%);
		left: unset;

		&:after,
		&:before {
			left: calc(75% - 10px);
		}
	}
	&.full-right-corner > .tooltip_ {
		right: 0%;
		transform: translateX(0%);
		left: unset;

		&:after,
		&:before {
			left: calc(100% - 30px);
		}
	}
	&:not(.top) > .tooltip_ {
		top: calc(100% + 15px);

		&:after,
		&:before {
			top: -9px;
		}
	}
	&:not(.top) > .tooltip_.large {
		top: calc(100% + 7px);

		&:after,
		&:before {
			top: 4px;
			background: #222831;
		}
	}
	&.top > .tooltip_ {
		bottom: calc(1.1em + 11px);

		&:after,
		&:before {
			bottom: -9px;
		}
	}
	&.top > .tooltip_.large {
		bottom: calc(1.5em - 7px);

		&:after,
		&:before {
			bottom: 6px;
			background: #222831;
		}
	}
	&.top > .tooltip_.small {
		bottom: calc(1.5em - 5px);

		&:after,
		&:before {
			bottom: -2px;
		}
	}
	&.top > .tooltip_.idcard {
		bottom: calc(1.5em - 2px);

		&:after,
		&:before {
			bottom: -6px;
		}
	}
	&.gray-tip > .tooltip_.large {
		&:after,
		&:before {
			background: #d3d3d3;
		}
	}
	&.slight-delay,
	&.small-delay,
	&.delay {
		div.tooltip_ {
			-webkit-transition: opacity 0ms ease 0ms !important;
			transition: opacity 0ms ease 0ms !important;
			opacity: 0;
			pointer-events: none;
			display: block;
			font-family: sans-serif;
		}
		&:hover div.tooltip_ {
			opacity: 1;
			z-index: 20;
			-webkit-transition: opacity 300ms ease 666ms !important;
			transition: opacity 300ms ease 666ms !important;
		}
	}
	&.small-delay {
		&:hover div.tooltip_ {
			-webkit-transition: opacity 225ms ease 500ms !important;
			transition: opacity 225ms ease 500ms !important;
		}
	}
	&.slight-delay {
		div.tooltip_ {
			pointer-events: none;
		}
		&:hover div.tooltip_ {
			pointer-events: unset;
			-webkit-transition: opacity 150ms ease 333ms !important;
			transition: opacity 150ms ease 333ms !important;
		}
	}
	&.title-tooltip {
		.tooltip_ {
			width: auto;
			bottom: calc(1.5em + 7px);

			span {
				background-color: #222831;
				letter-spacing: 1px;
				border-radius: 3px;
				overflow: hidden;
				z-index: 5;
				padding: .5em .75em .4em;
				white-space: nowrap;
				color: #fff;
				font-size: 13px;

				i.fa-link {
					margin-right: .7em;
					font-size: 11px;
					color: #457EBA;
				}
			}

			&:after,
			&:before {
				bottom: -2px;
			}
		}
	}
}