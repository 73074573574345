@import 'common/node_modules/@dnb-uux-design-system/design-tokens/build/styles/_variables.scss';

nav.breadcrumbs {
	margin: 24px 0 0 32px;
	line-height: 16px;
	ol {
		display: flex;
		align-items: center;
		flex-flow: nowrap;
		color: $color-gray-secondary;
	}
	span:global(.MuiTypography-caption) {
		font-weight: 400;
	}
}

.separator {
	margin: 0 8px;
	font-size: 12px;
}
