.container {
	padding: 0 32px;
	margin-top: 24px;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 24px;
}
