div.advanced-query-builder {
	ul.query-input {
		display: inline;
		// vertical-align: text-top;

		li {
			display: inline;

			span {
				display: inline;
				cursor: default;
			}

			span.query-input-number {
				border-bottom: 2px solid $datacloud-color-ignored;
				border-radius: 0px;
				color: #000;
				// font-weight: bold;
				margin: 0px 0px;
				display: inline-block;
				min-width: 1.1em;
				text-align: center;
				line-height: 1.2em;
				cursor: default;
				transition: 0.15s ease-in-out;
				transition-property: color, border-bottom;
				&>a {
					color: white;
				}

				&.ignored {
					color: $datacloud-color-ignored;
				}
			}

			span.query-input-number-redesign {
				display: inline-block;
				text-align: center;
				cursor: default;
				width: 24px;
				height: 24px;
				border-radius: 50%;
				padding: 2px 0;
				background: #5f86da;
				font-size: 12px;
				background: #5f86da;
				margin: 0 8px;
				&>a {
					color: white;
				}

				&.ignored {
					color: $datacloud-color-ignored;
				}
			}

			span.query-input-operator {
				text-transform: uppercase;
				font-weight: bold;
				display: inline-block;

				div {
					font-size: 0.8em;
					font-weight: 600;
				}
			}

			span.query-input-operator.isNewRedesign {
				div {
					font-size: 14px;
					font-weight: 550;
				}
			}

			select.query-section-operator-dropdown {
				background: transparent;
				border: none;
				font-weight: 400;
				cursor: pointer;
			}

			select.query-section-operator-dropdown.isNewRedesign {
				font-family: inherit;
				font-size: 14px;
				font-weight: 550;
				line-height: 20px;
				letter-spacing: 0.0062rem;
				text-align: left;
			}

			span.query-input-parenthesis {
				display: inline-block;
				width: 0.3em;
				height: 1em;
				font-weight: 600;
			}

			span.query-input-parenthesis.isNewRedesign {
				display: inline-block;
				font-weight: 550;
				line-height: 20px;
			}

			span.query-input-parenthesis-label {
				font-size: 1.1em;
				line-height: 1em;
				margin-top: 2px;
				display: inline;
				position: absolute;
				margin-left: 2px;
			}

			span.query-input-section-end .query-input-parenthesis-label {
				margin-left: -2px;
			}
		}

		li:last-child {
			> span.query-input-operator {
				display: none;

				.query-input-operator-alternate {
					display: block;
					margin-right: 0;
					float: left;
				}
			}
			> select.query-input-operator {
				display: none;
			}
		}

		&.query-input-root {
			.query-input {
				// border-top: 1px dashed #999;
				// margin-top: 2px;
				padding: 0 1px;
			}

			&.query-input,
			&.query-input > li > ul {
				border-top: none;
				margin-top: 0;
				padding: 0;
			}
		}

		&.query-input-root {
			> li {
				> .query-input-parenthesis,
				> span.query-input-operator-alternate {
					display: none;
				}
			}
		}
	}

	.query-input-type-and {
		& > span > div.item-operator-and {
			display: inline;
		}

		& > span > div.item-operator-or {
			display: none;
		}
	}

	.query-input-type-or {
		& > span > div.item-operator-and {
			display: none;
		}

		& > span > div.item-operator-or {
			display: inline;
		}
	}
}
