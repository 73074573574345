@import 'common/node_modules/@dnb-uux-design-system/design-tokens/build/styles/_variables.scss';

.editing-container {
	min-width: 560px;
	min-height: 25px;
	.error-message {
		color: #b50a20;
		vertical-align: baseline;
		background-color: white;
	}
	.error-date {
		padding-top: 0.2em;
	}
	@media only screen and (max-width: 1000px) {
		min-height: 80px;
	}
	.edit-controller {
		display: flex;
		justify-content: flex-end;
		height: 100%;
		align-items: center;

		.set,
		.unset {
			margin-top: -17px;
		}
	}
	.edit-controllers {
		// border: 1px solid red;
		min-width: 75px;
		height: 100%;
		width: 100%;
		margin-right: 20px;
		display: flex;
		justify-content: space-around;
		align-items: center;
		margin: 0;

		@media only screen and (max-width: 1050px) {
			flex-direction: column;
			// width: 100%;
			flex-wrap: wrap;
			width: 100%;
			border: 1px solid green;
			.set {
				margin-top: -17px;
				margin-bottom: 10px;
			}
			.unset {
			}
		}
		.set,
		.unset {
			margin-top: -17px;
			// min-width: 70px;
		}
	}
}
div.advanced-query-builder {
	&,
	& * {
		-webkit-transition: none;
		-moz-transition: none;
		-ms-transition: none;
		transition: none;
	}

	.query-helper-text-one {
		margin-top: 1em;
		margin-bottom: 0.5em;
		margin-left: 0.15em;
	}

	.query-helper-text-one-redesign {
		margin: 24px 0;
	}

	.query-section-divider {
		display: flex;
		vertical-align: text-middle;
		margin: 2em 0em 1em 0em;

		span {
			display: inline-block;
			font-weight: bold;
			width: 5em;
			font-size: 0.8em;
			padding-top: 0.2em;
			text-align: center;

			i {
				position: relative;
			}
		}

		div:not(.AddIcon){
			height: 0.8em;
			border-bottom: 1px dashed #888;
			width: 100%;
		}

		div {
			height: 0.8em;
			border-bottom: 1px solid #cbcdce;
			width: 100%;
		}
	}

	.query-section-type-dotline.isNewRedesign {
		position: absolute;
		margin-top: 37px;
		left: -1.8em;
		width: 1.5em;
		border-bottom: 1px solid #cbcdce;
		display: none;

		&.operation {
			margin-top: 19px;
			left: 0em;
		}
	}

	.query-section-type-dotline-bottom.isNewRedesign {
		position: absolute;
		width: 1.5em;
		border-bottom: 1px solid #cbcdce;
		display: none;

		&.operation {
			margin-top: 13px;
			left: -1.5em;
		}
	}

	.query-section-type-dotline {
		position: absolute;
		margin-top: 26px;
		left: -1.8em;
		width: 1.5em;
		border-bottom: 1px dashed #cbcdce;
		display: none;

		&.operation {
			margin-top: 15px;
			left: 0em;
		}
	}

	.query-section-type-dotline-bottom {
		position: absolute;
		margin-top: 25px;
		width: 1.5em;
		border-bottom: 1px dashed #cbcdce;
		display: none;

		&.operation {
			margin-top: 15px;
			left: -1.5em;
		}
	}

	.query-section {
		li {
			position: relative;
			margin-left: 1.15em;
		}

		&.query-tree-root {
			span.query-helper-text {
				display: none;
			}

			div.query-section-type-dotline {
				display: block;
			}

			div.query-section-type-dotline-bottom {
				display: block;
			}

			& > li > query-tree-directive > ul > li {
				//                 margin-left: 1.5em;
			}
		}

		&.query-tree-root > li > query-tree-directive:first-child {
			& > div > div.query-section-label > span.query-helper-text {
				display: inline;
				margin-left: 5px;
			}
			> div > .query-section-type-dotline {
				display: none;
			}
		}

		&.query-tree-root > li > query-tree-directive:first-child > .query-section {
			.query-section-operator {
				display: none;
			}

			& > .query-section-label.isNewRedesign {
				padding-top: 0;
			}

			& > .query-section-label > .query-section-label-expand.fa {
				display: none;
			}

			.query-section-controls {
				display: none;
			}

			.query-section-controls-redesign {
				display: none;
			}
		}

		.query-section-operator {
			position: absolute;
			// background: #fafafa;
			font-weight: bold;
			text-transform: uppercase;
			font-size: 0.8em;
			width: 2em;
			text-align: center;
			z-index: 2;
			display: none !important;
		}

		.query-section-operator.label-operator {
			left: -1.1em;
			top: -1.3em;
			cursor: default;
		}

		.query-section-operator.item-operator {
			display: none;
			top: -0.8em;
			left: -3em;
			cursor: default;
		}

		> li {
			margin-left: 0em;
		}

		.query-section-item {
			position: relative;
			background: white;
			//             border: 1px solid #ccc;
			border-left: 5px solid #5f86da;
			border-right: 1px solid #ddd;
			display: flex;
			cursor: default;
			// z-index: 1;
			border-top-left-radius: 2px;
			border-bottom-left-radius: 2px;

			&[disabled] {
				pointer-events: none;
				opacity: 0.2;
			}

			& *:not(a) {
				// cursor: default;
			}

			&.query-section-item-end {
				// background: #fafafa;
				border: none;
				cursor: default;

				& {
					* {
						cursor: default;
					}

					a, button {
						cursor: pointer;
						* {
							cursor: pointer;
						}
						color: #0078a7;
						font-weight: 550;
						line-height: 24px;
						padding: 0px 5px;
						margin-top: -2px;
						&:hover {
							background-color: rgba(0, 120, 167, 0.1);
							border-radius: 6px;
						}
					}
					a, button:is(.AddIcon) {
						padding-left: 1.5px;
					}
					a {
						align-items: center;
						margin-left: 5px;
						span {
							margin-right: 5px;
						}
					}
				}

				> span {
					display: inline-block;
					margin: 1em 0em 0 0.25em;
					vertical-align: baseline;
					font-size: 0.9em;
					i {
						margin: 0.2em 0.5em;
						font-size: 1.2em;
					}
				}

				> div {
					display: inline-block;
					padding: 0.5em 0em 0 0.25em;
					vertical-align: baseline;
				}

				span,
				span * {
					display: inline-flex;
					text-decoration: none;
				}
				&[disabled] {
					pointer-events: none;
					opacity: 0.2;
				}
			}

			.query-section-item-label {
				//                background-color: #33bdb7;
				//                border-right: 1px solid #ccc;
				min-width: 3em;
				text-align: center;
				border: 1px solid #ddd;
				border-left: none;
				border-right: none;

				strong {
					cursor: move;
					display: inline-block;
					color: #222;
				}

				& {
					cursor: move;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0.5em 0em;
				}
			}

			.query-section-item-content {
				position: relative;
				width: 100%;
				line-height: 1.3em;
				padding: 0.5em 1em 0.5em 0;
				border: 1px solid #ddd;
				border-left: none;
				border-right: none;

				span.item-edit-controls {
					//                     position: absolute;
					//                     top: 0;
					//                     right: 0;
					//                     bottom: 0;
					float: right;

					button {
						border-radius: 0;
						position: relative;
						top: 0;
						bottom: 0;
						margin: 0 -1px 0 -3px;
						height: 100%;
						border-top: none;
						border-bottom: none;

						&.blue-button {
							border: none;
						}

						&.gray-button {
							border: none;
						}
					}
				}

				&.cursor-pointer {
					cursor: pointer;
				}

				span.query-section-item-category,
				span.query-section-item-displayname {
					// float: left;
				}

				.query-section-item-category {
					//                     margin-top: .2em;
					font-size: 11px;
					display: inline-flex;
					gap: 5px;

					strong {
						max-width: 15em;
						display: inline-block;
						overflow: hidden;
						vertical-align: middle;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
				}

				.query-section-item-displayname {
					display: inline-block;
					position: relative;
					width: 100%;
					clear: both;
					font-size: 12px;

					.item-bucket-data {
						//                         display: inline-block;
						max-width: 70%;
						//                         overflow: hidden;
						//                         text-overflow: ellipsis;
						//                         white-space: nowrap;
					}

					.query-section-item-name {
						//                         display: inline-block;
						//                         max-width: 35%;
						margin-right: 0.25em;
						//                         overflow: hidden;
						//                         text-overflow: ellipsis;
						//                         white-space: nowrap;
					}

					.query-section-item-operation {
						position: relative;
						display: inline-flex;
						margin: 0px 0.5em 0 0;

						numerical-range-directive {
							.numerical-container.range-container {
								display: inline-block;
								padding: 0;
							}
							span {
								padding-top: 0.3em;
							}
						}

						.button-group {
							flex-shrink: 0;
							overflow: hidden;
							margin-top: 0px;
							border: 1px solid #d0d0d0;
							border-radius: 3px;
							height: 2.2em;

							button {
								font-size: 11px;
								line-height: 1;
								padding: 2px;
								height: 2em;
								margin: 1px;
								border: none;
								border-radius: 3px;
							}
						}

						chips-directive {
							.textContainer.dropDown {
								input {
									width: 100%;
									border: none;
								}
								legend span {
									margin: 0;
								}
							}
						}

						chips {
							float: right;

							.chips-query-container .chips-container {
								margin-bottom: 0;

								.chip {
									margin: 0 3px 5px 0;
								}
							}
							.ng-search {
								height: 2.2em;
							}

							.text-container.dropdown {
								.icon-button {
									padding: 0em 0.5em;
									line-height: 2.1em;
								}

								input {
									padding: 0;
									margin-bottom: 0;
									width: 100%;
									height: 100%;
									border: none;
								}
							}
						}

						span {
							margin: 0px 0.25em 0;
						}

						span.error-message {
							margin-left: 10px;
							margin-right: 0;
						}

						*:not(a) {
							// cursor: default;
						}

						select {
							margin-right: 1em;
							max-width: 45em;
							font-size: 12px;
						}

						input,
						select {
							height: 2.2em;
							padding: 4px;
							border-radius: 3px;
							border-color: #d0d0d0;
							border-width: 1px;
							border-style: solid;
						}

						span.operation-and {
							// margin: .25em;
							font-weight: bold;
						}

						input {
							width: 10em;
							cursor: text;
							font-size: 12px;
						}
						.range-container {
							input {
								border: unset;
							}
							legend {
								border-bottom: unset;
								span {
									margin: 0;
								}
							}
						}
					}

					button.unset-button,
					button.set-button {
						float: right;
						margin: -0.25em 0 0 1em;
						padding: 0.3em 0.8em 0.2em;
						border-radius: 3px;
						font-size: 0.8em;
					}
				}
			}

			.query-section-item-clone {
				position: relative;
				width: 5em;
				display: flex;
				justify-content: center;
				align-items: center;
				border: 1px solid #ddd;
				border-left: none;
			}
			
			.query-section-item-edit {
				display: flex;
				align-items: center;
				border-top: 1px solid #ddd;
				border-bottom: 1px solid #ddd;
				border-left: 1px solid #ddd;
				padding: 0 1em;
				width: 4.5em;
				& > * {
					cursor: pointer;
				}
			}

			.query-section-item-records {
				position: relative;
				//                 background: #ececec;
				//                 border-left: #ddd solid 1px;
				width: 10em;
				padding: 0.5em 1em;
				line-height: 1.4em;
				display: flex;
				justify-content: space-between;
				align-items: center;
				border: 1px solid #ddd;
				border-left: none;
				border-right: none;

				span {
					float: left;

					&.query-section-item-records-label {
						//                         font-weight: bold;
						color: #888;
						font-size: 0.9em;
					}

					&.query-section-item-records-number {
						font-size: 1em;
						display: flex;
						flex-direction: column;
					}
				}

				i.fa-close {
					//                     position: absolute;
					//                     top: 0em;
					//                     right: 0em;
					//                     float: right;
					//                     padding: 1.2em .8em;
					font-size: 13px;
					cursor: pointer;
				}
			}

			&__section-ignored {
				border: 1px solid #ddd;
				border-right: none;
				display: flex;
				align-items: center;
				padding-left: 1em;

				&__unused-indicator {
					font-weight: bold;
					color: red;
				}
			}

			&__section-ignored.toggle {
				border: 1px solid $color-gray-incidental;
				border-left: none;
				padding-left: 0;
				padding-right: 1em;
				width: 5em;
				border-top-right-radius: 8px;
				border-bottom-right-radius: 8px;
			}

			&__section-ignored.member.toggle {
				padding-left: 1em;
			}

			.query-section-item-controls {
				display: flex;
				align-items: center;
				border-top: 1px solid #ddd;
				border-bottom: 1px solid #ddd;
				padding: 0 1em;
				width: 4.5em;
				& > * {
					cursor: pointer;
				}
			}
		}

		.query-section-item-box {
			position: relative;
			border-left: 1px dashed #666;
			padding: 0 0 0.25em 1.5em;
			margin-bottom: 0em;

			&.item-ignored {
				border-bottom: none;
				color: $datacloud-color-ignored;

				.query-section-item {
					border-left-color: #aaa;
					.editOutlinedIcon, .contentCopyIcon, .deleteIcon {
						color: $datacloud-color-ignored;
					}
				}

				.item-operator-and,
				.item-operator-or {
					display: none;
				}

				// .query-section-item-label strong {
				//     visibility: hidden;
				// }

				.query-section-item-content {
					border-right: transparent;
				}
			}

			&.query-section-item-end:first-child div.query-section-item-end {
				// top: -0.75em;

				span > a[disabled] {
					opacity: 0.2;
					pointer-events: none;
				}
			}
			&.query-section-item-end.isNewRedesign {
				a {
					margin-left: 0px;
				}
			}
		}

		.query-section-item-box-subtree {
			border-bottom: none;
			padding-top: 3px;
		}

		.query-section-item-box-activity {
			a.add-attribute, button.add-attribute {
				display: none;
			}
			.add-operator {
				.operator-type {
					display: none;
				}
				advanced-query-icon-directive {
					padding-right: 5px;
					button.AddIcon.notActivity {
						display: none;
					}
					button.AddIcon.Activity {
						display: inline-flex;
					}
				}
			}
		}

		li.query-section-item-box-subtree {			
			.add-event, add-attributes-directive ~ button.add-event {
				display: none;
			}
		}

		li.query-section-item-box-activity {
			.add-event, add-attributes-directive ~ button.add-event {
				display: inline-flex;
			}
		}

		.query-section-item-box-activity {
			a.add-event, add-attributes-directive ~ button.add-event {
				display: inline-flex;
			}
		}

		.query-section-all,
		.query-section-any {
			margin: 0.1em 0 0;
		}

		.query-section-item-end {
			border-color: #bbb;
			border-right: none;
			padding-bottom: 0;
		}

		.query-section-item-activity {
			border-right: none;
			margin-left: 1.15em;
			padding-bottom: 0;
			display: flex;

			select {
				margin: 0 1em;
				max-width: 45em;
				font-size: 12px;
			}

			input,
			select {
				height: 2.2em;
				padding: 4px;
				border-radius: 3px;
				border-color: #d0d0d0;
				border-width: 1px;
				border-style: solid;
			}

			input {
				width: 10em;
				cursor: text;
				font-size: 12px;
			}

			numerical-range-directive {
				.numerical-container.range-container {
					display: inline-block;
					padding: 0;
				}
				span {
					padding-top: 0.3em;
					margin: 0px 0.25em 0;
				}
			}

			.query-section-item-records {
				position: relative;
				width: 10em;
				padding: 0.4em 1em;
				line-height: 1.4em;
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-left: none;
				border-right: none;

				span {
					float: left;

					&.query-section-item-records-label {
						color: #888;
						font-size: 0.9em;
						margin-right: 5px;
					}

					&.query-section-item-records-number {
						font-size: 1em;
						// display: flex;
						flex-direction: column;
					}
				}

				i.fa-close {
					font-size: 13px;
					cursor: pointer;
				}
			}
		}

		.query-section-label {
			display: block;
    		padding-top: 3px;
			&,
			& * {
				-webkit-transition: none;
				-moz-transition: none;
				-ms-transition: none;
				transition: none;
			}

			button {
				border-radius: 3px;
			}

			.query-section-controls {
				border-radius: 100%;
				border: 1px dashed #666;
				padding: 0;
				margin: 0;
				margin-left: 3px;
				margin-right: 0.3em;
				float: left;

				&:hover {
					border-style: solid;
					border-color: #457eba;
					background: #fff;
					-webkit-transition: none;
					-moz-transition: none;
					-ms-transition: none;
					transition: none;

					.fa:before {
						color: #457eba;
						-webkit-transition: none;
						-moz-transition: none;
						-ms-transition: none;
						transition: none;
					}
				}
			}

			.query-section-controls-redesign {
				.deleteIcon {
					padding: 6px;
					border-radius: 100%;
					color: #6b6d6f;
				}

				&:hover {
					border-style: solid;
					border-color: #457eba;
					background: #fff;
					-webkit-transition: none;
					-moz-transition: none;
					-ms-transition: none;
					transition: none;

					.fa:before {
						color: #457eba;
						-webkit-transition: none;
						-moz-transition: none;
						-ms-transition: none;
						transition: none;
					}
				}
			}
			.query-section-label-expand.fa {
				position: absolute;
				left: -0.6em;
				top: 13px;
				font-size: 10pt;
				border: 2px solid #f9f9f9;
				border-bottom: transparent;
				background: #f9f9f9;
			}

			.query-section-label-expand.icon {
				cursor: pointer;
				background: white;
				.dnbButtonIcon {
					text-align: center;
					padding: 6px;
					border-radius: 50%;
					color: #6b6d6f;
				}
			}

			select.query-section-subtype-dropdown {
				background: transparent;
				border: none;
				font-weight: 600;
			}

			select.query-section-operator-dropdown {
				background: transparent;
				border: none;
				font-weight: 600;
				cursor: pointer;
				margin: 0.55em 0em;
			}

			select.query-section-operator-dropdown.isNewRedesign {
				margin: 0;
				font-family: inherit;
				font-size: 14px;
				font-weight: 550;
				letter-spacing: 0.0062rem;
				padding-left: 8px;
			}

			.query-section-label-text {
				padding: 0.333em 0em 0.333em 0.25em;
				display: inline-block;
			}

			.query-section-controls i.fa {
				display: inline-block;
				padding: 7px 8px;
				cursor: pointer;
				font-size: 13px;
			}
			.le-grid .le-row {
				min-height: 25px;
			}
		}

		.query-section-item.isNewRedesign {
			border-radius: 8px;
			&:hover:not(.query-section-item-end) {
				box-shadow: 0px 2px 4px 0px rgba(22, 25, 25, 0.2);
			}
			&.active-link {
				box-shadow: 0px 2px 4px 0px rgba(22, 25, 25, 0.2);
			}
			&.query-section-item-end {
				> div {
					display: inline-flex;
					gap: 8px;
				}
			}

			.query-section-item-label {
				border: 1px solid $color-gray-incidental;
				border-left: none;
				border-right: none;
				padding: 8px;
				display: flex;
				flex-direction: column;
				gap: 8px;
				strong {
					cursor: move;
					display: inline-block;
					color: white;
					width: 1.6em;
					height: 1.6em;
					border-radius: 50%;
					margin: 0 0.25em;
					background: #5f86da;
				}
			}
			.query-section-item-value {
				strong {
					font-weight: 850 !important;
				}
			}
			.query-section-item-content.isNewRedesign {
				line-height: 32px;
				display: flex;
				flex-direction: column;
				border: 1px solid $color-gray-incidental;
				border-left: none;
				border-right: none;
				.query-section-item-category {
					font-size: 14px;
					font-weight: 550;
					letter-spacing: 0.00062rem;
					line-height: 20px;
				}
				.query-section-item-displayname {
					font-size: inherit;
					.query-section-item-name {
						font-weight: 850;
						margin-right: 0.25em;
						white-space: nowrap;
						height: 36px;
						padding-top: 8px;
					}
					.item-bucket-data {
						width: 100%;
						max-width: 100%;
					}
					.query-section-item-operation {
						align-items: baseline;
						&.Member {
							padding-top: 6px;
						}
						span {
							font-weight: 550;
						}
						.button-group {
							height: 3.6rem;
							align-items: center;
							display: flex;
							border-radius: 7px;
							border: 1px solid #6B6D6F;
							box-shadow: 0px 1px 3px 0px #16191933 inset;
							background: #FFFFFF;
						}
	
						input,
						select {
							height: 3.6rem;
						}
					}
					.item-edit-bucket-data {
						.query-section-item-operation:not(.Member) {
							align-items: unset;
						}
						input:not(.MuiInputBase-input), select {
							height: 3.6rem;
							border-radius: 7px;
							border: 1px solid #6B6D6F;
							box-shadow: 0px 1px 3px 0px #16191933 inset;
							background: #FFFFFF;
						}
						.query-section-item-records {
							border: unset;
							width: auto;
						}
						.flex-container-controller {
							align-items: flex-start;
							.queryBuilderListHr {
								margin: 8px 0 0;
								flex-shrink: 0;
								border-style: solid;
								border-color: rgba(0, 0, 0, 0.12);
								background-color: rgba(203, 205, 206, 0.4);
								border-width: 1px;
								border-radius: 4px;
								height: 40px;
							}
							button {
								margin-top: 10px;
								width: 60px;
								max-height: 3.6rem;
								padding: 1px;
								border-radius: 6px;
								background-color: #0078a7;
								font-size: 14px;
								font-weight: 550;
								&.disabled {
									opacity: 0.4;
									color: white;
								}
								&.gray-button {
									border-color: #0078a7;
									color: #0078a7;
									background-color: transparent;
								}
								&.model {
									margin-left: 4px;
									margin-right: 4px;
								}
							}							
							.query-section-item-records {
								&.model {
									padding-left: 4px;
									padding-right: 4px;
								}
							}
						}
						.query-section-item-category {
							flex-direction: column;
						}
					}
					.query-section-item-value {
						enum-attribute {
							span {
								margin: 0;
								font-weight: 850;
							}
						}
						.action {
							text-decoration: underline;
							color: #0078a7;
						}
					}
				}
				&.editing {
					flex-direction: unset;
				}
			}

			.query-section-item-clone {
				border: 1px solid $color-gray-incidental;
				border-left: none;
				.contentCopyIcon {
					padding: 6px;
					border-radius: 100%;
					color: #6b6d6f;
				}
			}
			.query-section-item-clone.contentCopyIcon {
				border-right: none;
			}
			
			.query-section-item-edit {
				border-top: 1px solid $color-gray-incidental;
				border-bottom: 1px solid $color-gray-incidental;
				border-left: 1px solid $color-gray-incidental;
				.editOutlinedIcon {
					padding: 6px;
					border-radius: 100%;
					color: #6b6d6f;
				}
			}

			.query-section-item-records {
				border: 1px solid $color-gray-incidental;
				border-right: none;
				width: auto;
			}

			.query-section-item-records.member {
				border-left: none;
				border-right: 1px solid $color-gray-incidental;
				width: auto;
			}

			.query-section-item-controls {
				border-top: 1px solid $color-gray-incidental;
				border-bottom: 1px solid $color-gray-incidental;
				.deleteIcon {
					padding: 6px;
					border-radius: 100%;
					color: #6b6d6f;
				}
			}
			span {
				&.query-section-item-records-label {
					font-size: 16px;
					font-weight: 550;
					line-height: 24px;
					letter-spacing: 0.00062rem;
					color: #6b6d6f;
				}
			}
			.expanding-bars-init {
				font-size: 14px;
				font-weight: 550;
				line-height: 20px;
				letter-spacing: 0.00062rem;
			}
		}

		.query-section-item-box.isNewRedesign {
			border-left: 1px solid #cbcdce;
			padding: 0 0 0.5em 1.5em;

			&.item-ignored {
				border-bottom: none;
				color: $datacloud-color-ignored;

				.query-section-item {
					border-left-color: $datacloud-color-ignored;
				}
			}

		}

		.query-section-label.isNewRedesign {			
			display: flex;
			align-items: center;
			padding-left: 1px;
			line-height: 3.6rem;
			.num-edit-row {
				font-size: 14px;
				font-weight: 550;
				align-items: center;
				select, input {
					border: 1px solid #6B6D6F;
					box-shadow: 0px 1px 3px 0px #16191933 inset;
					border-radius: 7px;
					line-height: 2rem;
					height: 3.6rem;
					letter-spacing: 0.0062rem;
				}
				.range-container {
					flex-wrap: nowrap;
				}
			}
			.flex-container-controller {
				button {
					width: 60px;
					max-height: 3.6rem;
					padding: 1px;
					border-radius: 6px;
					background-color: #0078a7;
					font-size: 14px;
					font-weight: 550;
					color: #fff;
				}
				button.disabled {
					opacity: 0.4;
				}
			}
			.flexContainerController {
				button {
					width: 60px;
					max-height: 3.6rem;
					padding: 1px;
					border-radius: 6px;
					background-color: #0078a7;
					font-size: 14px;
					font-weight: 550;
					color: #fff;
				}
				button.disabled {
					opacity: 0.4;
				}
			}
			.query-helper-text {
				font-weight: 550;
				margin-right: 8px;
			}
			.query-section-item-operation {
				font-weight: 550;
				white-space: nowrap;
			}
		}

		.query-section-any {
		}

		.query-section-all {
		}
	}

	.query-tree-root .query-section-type-and {
		& > div > div.item-operator-and {
			display: block;
		}

		& > div > div.item-operator-or {
			display: none;
		}
	}

	.query-tree-root .query-section-type-or {
		& > div > div.item-operator-and {
			display: none;
		}

		& > div > div.item-operator-or {
			display: block;
		}
	}

	.query-section-item-box:first-child {
		// padding-top: 0.75em;
   		margin-top: 2px;
		& > query-tree-directive {
			& > div > .query-section-operator {
				display: none;
			}

			& > .query-section-item {
				//                 top: .75em;
			}

			& > div > .query-section-label > .query-section-label-expand {
				// top: 1.6em;
			}
		}
	}
	.query-section-item-box.isNewRedesign:first-child {
		padding-top: 5px;
   		margin-top: 0;
	}

	hr:not(.queryBuilderListHr) {
		margin-top: -0.9em;
		margin-left: 2.5em;
		border: none;
		border-top: 1px dashed #aaa;
	}

	.dragging,
	.dropped {
		-webkit-transition: none;
		-moz-transition: none;
		-ms-transition: none;
		-o-transition: none;
		transition: none;
	}

	.dragging {
		pointer-events: none;
		position: absolute;
		top: 0px;
		left: 0px;
		perspective: 5px;
		-webkit-transition: none;
		-moz-transition: none;
		-ms-transition: none;
		-o-transition: none;
		transition: none;
		-webkit-transform-origin: bottom left;
		-moz-transform-origin: bottom left;
		-ms-transform-origin: bottom left;
		-o-transform-origin: bottom left;
		transform-origin: bottom left;
		opacity: 0.666;
		z-index: 999;
	}

	.dashed {
		background: white;
		border: 1px dashed #999;
		height: 52px;
		display: block;
		opacity: 0.5;

		.query-section-item-label {
			border: none;
		}
	}
}

div.wizard-content div.advanced-query-builder {
	.query-section {
		.query-section-item.query-section-item-end {
			background: white;
		}

		.item-ignored .query-section-item-content {
			border-right-color: white;
		}
	}
}
.dragdrop-hover-bg {
	li {
		background: #fafafa;
	}
	& > li {
		background: #eee;
	}
}

div.advanced-query-builder {
	.query-section {
		.query-section-item:not(.can-edit) {
			.query-section-item-label {
				strong {
					cursor: default;
				}
				& {
					cursor: default;
				}
			}
		}
		.query-section-label:not(.can-edit) {
			.query-section-controls {
				pointer-events: none;
				i.fa {
					opacity: 0.2;
					cursor: default;
				}
			}
		}
	}
}
