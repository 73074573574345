@import 'common/node_modules/@dnb-uux-design-system/design-tokens/build/styles/_variables.scss';

.chipsQueryContainer {
	display: flex;
	flex-direction: column;
	min-width: 200px;
	min-height: 30px;
	&.bottom {
		flex-direction: column-reverse;
	}
	.chipsContainer {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 2px;
		width: 100%;
		font-size: 14px;

		&.scrollable {
			overflow-y: scroll;
			max-height: 125px;
		}

		&.expanded {
			max-height: 480px;
		}

		&.bottom {
			margin-top: 2px;
		}

		&Pagination {
			margin-top: 8px;
		}

		.chipContainer {
			padding: 5px;
			border-radius: 4px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			span {
				white-space: nowrap;
				text-overflow: ellipsis;
				max-width: 12em;
				overflow: hidden;
				min-height: 1.1em;
			}
		}
		.chip {
			width: auto;
			padding: 0px;
			margin: 4px;
			line-height: 1;
			border: #cbcbcb solid 1px;
			background-color: rgba(0, 0, 0, 0.05);
		}
		.chipRemove {
			cursor: pointer;
			margin-left: 4px;
			color: #4e4e4e;
		}
		.chipInvalid {
			color: orange;
		}
	}
	.ChipsDialogLarge {
		width: auto;
		font-size: 14px;
		font-style: normal;
		margin: 0;
	}

	.chip {
		width: auto;
		height: 24px;
		padding: 0px;
		margin: 0 5px 5px 0;
		line-height: 1;
		background-color: $color-gray-secondary-alpha-1;
		border-radius: 7px;
		.chipContainer {
			padding: 0 3px;
			border-radius: 4px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			.text {
				white-space: nowrap;
				text-overflow: ellipsis;
				max-width: 12em;
				overflow: hidden;
				min-height: 1.1em;
				color: $color-gray-secondary;
				font-size: 12px;
			}
		}
	}
	.controlsContainer {
		display: flex;
		align-items: center;
		padding: 0 0 5px 0;
		.bulkEntryButton {
			flex-shrink: 0;

			button {
				color: #457eba;
				border: 1px solid #457eba;
				padding: 4px;
				border-radius: 4px;
				margin-right: 4px;
			}
		}
		.chipsCount {
			white-space: nowrap;
			padding-left: 5px
		}
		.dropDown {
			fieldset {
				box-shadow: 0px 1px 3px 0px $color-gray-primary-alpha-2 inset;
			}
		}
		.dropdownHeader{
			display: flex;
			justify-content: space-between;
		}
		.dropdownContent {
			display: none;
			position: absolute;
			background-color: #f9f9f9;
			min-width: 300px;
			margin-top: -1px;
			overflow: unset;
			max-height: 200px;
			border: #cbcbcb solid 1px;
			box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
			z-index: 999;
			width: auto;
			.queryResultRow {
				display: flex;
				border-bottom: 1px solid #ddd;
				padding: 8px 8px 8px 12px;
				span {
					flex: 1;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				.action {
					border: 1px solid;
				}
			}
			.queryResultRowSelected {
				background: #467dba;
				color: #fff;
				font-weight: bold;
			}
		}
		.dropdownContent:not(.queryResultHeader) {
			.queryResultRow:hover {
				background: #fbfbfb;
				color: #467dba;
				font-weight: bold;
				cursor: pointer;
			}
		}
		.textContainer.showHeader {
			.dropdownContent:not(.queryResultHeader) {
				margin-top: 2em;
			}
			.showDropdown.queryResultHeader {
				display: block;
			}
		}
		.queryResultHeader {
			margin-top: -1px;
			display: none;
			.queryResultRow {
				padding: 1px 8px 0px 12px;
				background: #efefef;
				border-bottom: none;
				text-transform: uppercase;
				font-size: 6pt;
				font-weight: bold;
				i {
					margin-left: 0.5em;
				}
			}
			.queryResultRow span span:hover {
				text-decoration: underline;
				color: #457eba;
			}
			.queryResultRow span span:active {
				color: #666;
			}
		}
		.showDropdown {
			display: block;
		}
	}
}

.ChipsContent {
	display: flex;
	height: 660px;
	.ChipsLeftSection {
		height: 643px;
		width: 272px;
		padding-right: 12px;
		.delimiter {
			display: flex;
			flex-direction: column;
			gap: 4px;
		}
		.pasteTitle {
			display: flex;
			height: 20px;
		}
		.delimiterLable {
			&:after {
				content: ' *';
			}
		}
		fieldset {
			box-shadow: 0px 1px 3px 0px $color-gray-primary-alpha-2 inset;
		}
	}
	.ChipsLeftSection.noDataSource {
		width: 822px;
	}
	.ChipsCenterSection {
		width: 600px;
		display: flex;
		flex-direction: column;
		.searchSection {
			display: flex;
			flex-direction: column;
			margin-left: 10px;
			gap: 4px;			
			fieldset {
				box-shadow: 0px 1px 3px 0px $color-gray-primary-alpha-2 inset;
			}
		}
	}
	.ChipsRightSection {
		width: 270px;
		border-radius: 7px;
		overflow-y: auto;
		overflow-x: clip;
		.section {
			.sectionHeader {
				display: flex;
				align-items: center;
				height: 36px;
				padding: 0 8px 0 12px;
				justify-content: space-between;
				white-space: nowrap;
			}
			.sectionBody {
				padding-left: 10px;
				padding-top: 2px;
				.content {
					padding: 2px 8px;
					border-radius: 7px;
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					width: fit-content;
					.text {
						white-space: nowrap;
						text-overflow: ellipsis;
						max-width: 12em;
						overflow: hidden;
					}

				}
			}
		}
	}
}
