/* 
Logo
==================================================== */
.logo {
  display: inline-block;
  width: 100px;
  margin: 20px 0;
  opacity: 0.7;
  filter: alpha(opacity=70);
  img {
    width: 100%;
  }
}
.login .logo {
  filter: none;
  opacity: 1;
  margin: 0;
  padding-top: 6px;
}
.no-nav-logo {
  width: 110px;
  margin-top: 12px;
}

/*
Main Content & Page Header
=================================================== */
.page-header {
	text-align: center;
}
.main-content {
	/*padding-bottom: 8em;*/
}

/* 
Toggle UI Components
====================================================== */
.toggle {
  & > ul, & > div {
    display: none;
  }
  &.open {
    & > ul, & > div {
      display: block;
    }
  }
}

/* 
Content Panels
================================================= */
.content-panel {
  border: #d4d4d4 solid 1px;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0px 0px 14px #f6f8f9;
  .panel-header, .panel-body, .panel-footer {
    padding: 20px;
  }
  .panel-header {
    background: #fafafa;
    border-bottom: #f2f2f2 solid 1px;
  }
  .panel-footer {
    margin-top: 20px;
    padding-top: 20px;
    border-top: #ddd solid 1px;
    &:after {
      display: table;
      content: "";
      clear: both;
    }
  }
}

section.le-itemlist {
  width: 100%;
  padding: 0 1.5em;
}

.help-sidebar {
  h3 {
    font-size: 16px;
    line-height: 20px;
    color: #3c8bc4;
    text-transform: none;
    margin-top: 20px;
    &:first-of-type {
      margin-top: 0;
    }
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
     -khtml-user-select: none; /* Konqueror */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  not supported by any browser */
}


.noselect-children,
.noselect-children * {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: text; /* Chrome/Safari/Opera */
     -khtml-user-select: none; /* Konqueror */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  not supported by any browser */
}

.expanding-bars-init,
.expanding-bars-init * {
    display: block;
    float: left;
    transition: none;
    line-height: 1;

    &.expanding-bars-spinner {
        & > * {
            background-color: #888;
            color: transparent;
            display:inline-block;
            border-radius: 0;
            margin-right: 0em;
            -webkit-animation: expanding-bars-animation .8s infinite;
            animation: expanding-bars-animation .8s infinite;
            transform: scaleY(.1) scaleX(1.25);

            &:nth-child(1) {
                -webkit-animation-delay: 0.3s;
                animation-delay: 0.3s;
            }
            &:nth-child(2) {
                -webkit-animation-delay: 0.5s;
                animation-delay: 0.5s;
            }
            &:nth-child(3) {
                -webkit-animation-delay: 0.8s;
                animation-delay: 0.4s;
            }
            &:nth-child(4) {
                -webkit-animation-delay: 0s;
                animation-delay: 0s;
            }
            &:nth-child(5) {
                -webkit-animation-delay: 0.6s;
                animation-delay: 0.6s;
            }
            &:nth-child(6) {
                -webkit-animation-delay: 0.2s;
                animation-delay: 0.2s;
            }
            &:nth-child(7) {
                -webkit-animation-delay: 0.7s;
                animation-delay: 0.7s;
            }
        }
    }
}

@keyframes expanding-bars-animation {
    15% {
        transform: scaleY(.1) scaleX(1.25);
        border-radius: 0;
        background-color: #888;
    }
    33% {
        transform: scaleY(1) scaleX(.5);
        border-radius: 3px;
        background-color: #CCC;
    }
    85% {
        transform: scaleY(.1) scaleX(1.25);
        border-radius: 0;
        background-color: #888;
    }
}