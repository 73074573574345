.import-wizard-progress {
	position: relative;
	color: #aaa;
	margin-top: 25px;

	&:before {
		content: '';
		position: absolute;
		display: block;
		position: absolute;
		top: 10px;
		left: 10px;
		bottom: 10px;
		border-left: 1px solid #d0d0d0;
	}

	li {
		position: relative;
		text-decoration: none;
		margin-bottom: 40px;
		padding-left: 30px;
		transition: none;

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			background-color: #f2f1f1;
			width: 23px;
			height: 23px;
			border-radius: 50%;
			border: 1px solid #d0d0d0;
			transition: none;
		}

		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			transition: none;
		}

		span.import-wizard-progress-validation {
			color: red;
			font-size: 9pt;
			float: left;
			display: block;
			width: 100%;
			font-weight: normal;
			position: absolute;
			margin-top: -0.3em;
			opacity: 0;
			&.invalid {
				opacity: 1;
			}
		}
	}
	li.previous {
		cursor: default;

		&:before {
			background-color: #fff;
		}

		&:not(.current):not(:last-child):after {
			@include font-awesome-inline('\f00c');
			color: #0f9e4d;
			left: 4px;
		}
	}

	li.current {
		color: #4298b5;

		&:before {
			background-color: #fff;
		}
	}
}
