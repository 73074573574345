@keyframes CascadeListFadeIn { 
    0% {
        opacity: 0;
        transform: scale(.85);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.fade-element-in.ng-enter {
  transition: 0.8s linear all;
  opacity: 0;
}

.fade-element-in-init .fade-element-in.ng-enter {
  opacity: 1;
}

.fade-element-in.ng-enter.ng-enter-active {
  opacity: 1;
}

.fade-element-in.ng-leave {
  transition: 0.3s linear all;
  opacity: 1;
}
.fade-element-in.ng-leave.ng-leave-active {
  opacity: 0;
}

.cascade li {
    animation: CascadeListFadeIn .1s linear;
    animation-fill-mode: both;
    &:nth-child(1) {
        animation-delay: 0;
    }
    &:nth-child(2) {
        animation-delay: .05s
    }
    &:nth-child(3) {
        animation-delay: .1s
    }
    &:nth-child(4) {
        animation-delay: .15s
    }
    &:nth-child(5) {
        animation-delay: .2s
    }
    &:nth-child(6) {
        animation-delay: .25s
    }
    &:nth-child(7) {
        animation-delay: .3s
    }
}
