@import '../../colors.scss';
@import '../../../../common/assets/sass/include-media';

.le-dropdown {
	position: relative;
	display: inline-block;
	font-size: 14px;
	font-weight: 400;
	line-height: 28px;
	font-weight: normal;
	outline: none;

	.button {
		position: relative;
		display: inline-block;
		padding: 0 18px;
		margin-bottom: 0;
		font-size: 14px;
		font-weight: 400;
		line-height: 28px;
		text-align: center;
		white-space: nowrap;
		vertical-align: middle;
		-ms-touch-action: manipulation;
		touch-action: manipulation;
		cursor: pointer;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		background-image: none;
		border: 1px solid transparent;
		color: $pearl-white;
		border-radius: 3px;
		transition: all 133ms ease;
		outline: none;

		span.le-button-label + i {
			padding-left: 8px;
		}

		&:hover {
			-webkit-transition: none;
			-moz-transition: none;
			-ms-transition: none;
			transition: none;
		}

		&.blue-text-dropdown {
			background-color: #ffffff;
			border: 1px solid #ffffff;
			color: #457eba;

			&:hover {
				color: #629acc;
			}

			&-open {
				background-color: #e6f1f6;
				border: 1px solid #e6f1f6;
				color: #457eba;

				&:hover {
					color: #629acc;
				}
			}
		}

		&.blue-filled-text-dropdown,
		&.blue-filled-text-dropdown-open {
			background-color: #457eba;
			border: 1px solid #457eba;
			color: #ffffff;

			&:hover {
				background-color: #629acc;
			}
		}

		i + span.le-button-label {
			padding-left: 8px;
		}
	}

	.le-dropdown-list {
		background-color: #ffffff;
		border-radius: 6px;
		border: 1px solid #f5f5f6;
		box-shadow: 0 6px 12px 0 #0000002e;
		list-style: none;
		padding-left: 0;
		display: block;
		position: absolute;
		top: 100%;
		right: 0;
		margin-top: 4px;
		padding: 8px;
		z-index: 2;
		min-width: 190px;

		&-item {
			line-height: 36px;
			background-color: #ffffff;
			color: #222;
			border-radius: 6px;
			padding-left: 8px;
			padding-right: 8px;

			&:hover {
				background-color: #f0f0f1;
			}

			span.disabled {
				color: #d8d8d8;
			}
		}
	}

	.adminNavItem {
		font-weight: bold;
		margin: 0 16px;
		padding: 18px 0;
		transition: none;
		white-space: nowrap;
		background-color: white;
		border-color: white;
		border: 0;
		line-height: 1.6;
		color: #222831;

		&-open {
			@extend .adminNavItem;
			background-color: white;
			border: 0;
			color: #222831;
		}

		~ .le-dropdown-list {
			background-color: white;
			border-radius: 6px;
			border: 1px solid #f5f5f6;
			box-shadow: 0 6px 12px 0 #0000002e;
			list-style: none;
			padding-left: 0;
			display: block;
			position: absolute;
			top: 80%;
			left: 0;
			margin-top: 4px;
			padding: 8px;
			z-index: 2;
			min-width: 190px;

			&-item {
				line-height: 36px;
				background-color: white;
				color: #222;
				border-radius: 6px;
				padding-left: 8px;
				padding-right: 8px;

				&:hover {
					background-color: #f0f0f1;
				}

				span.disabled {
					color: #d8d8d8;
				}
			}
		}
	}
}
