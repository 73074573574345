@import 'widgets/colors.scss';
@import 'widgets/layout/layout.scss';

$columns-gap: 8px;
$header-height: 50px;
$row-height: 50px;
$max-span: 12;
$cell-padding: 8px;
$font-size: 12px;
@mixin display-grid {
	/*Defines the dyplay grid for the other browsers*/
	display: grid;
}

@mixin columns-grid {
	/*Defines the columns for the other browsers*/
	grid-template-columns: repeat($max-span, minmax(10px, 1fr));
}

.le-table {
	*,
	*:after,
	*:before {
		transition: none;
	}

	ul {
		list-style-type: none;
	}

	.indicator-row {
		border-top: 1px solid $silver;
		border-bottom: 1px solid $silver;
	}

	@include display-grid;
	@include columns-grid;
	font-size: $font-size;
	height: 100%;
	padding: 0.5em;

	.le-table-row {
		@include display-grid;
		@include columns-grid;
		position: relative;
		grid-column: span 12;
		border-bottom: 1px solid $silver;
		background-color: $pearl-white;

		&.disabled {
			background-color: $palladium;
			color: $gun-metal;
			pointer-events: none;
		}

		&.selected {
			background-color: #f0f7fc;
		}

		&.selected:hover {
			background-color: #f0f7fc;
		}
	}

	&.selectable {
		.le-table-row {
			&:hover {
				cursor: pointer;
			}
		}
	}

	.le-table-body {
		background: none;
		min-height: 0;
	}

	.le-table-header {
		@extend .le-table-row;
		border-bottom: 1px solid #222;
		text-transform: uppercase;
		font-size: 10px;
		background-color: transparent;
		font-weight: bold;
	}

	.le-table-row:hover {
		background-color: #f0f7fc;
	}

	.le-table-header:hover,
	.le-table-footer:hover {
		background-color: transparent;

		& > .le-table-cell {
			color: $black;

			* {
				color: $black;
			}
		}
	}

	.le-table-body:hover {
		background: none;
	}

	.le-table-col-span-1 {
		-ms-grid-column-span: 1;
		grid-column: span 1;
	}

	.le-table-col-span-2 {
		-ms-grid-column-span: 2;
		grid-column: span 2;
	}

	.le-table-col-span-3 {
		-ms-grid-column-span: 3;
		grid-column: span 3;
	}

	.le-table-col-span-4 {
		-ms-grid-column-span: 4;
		grid-column: span 4;
	}

	.le-table-col-span-5 {
		-ms-grid-column-span: 5;
		grid-column: span 5;
	}

	.le-table-col-span-6 {
		-ms-grid-column-span: 6;
		grid-column: span 6;
	}

	.le-table-col-span-7 {
		-ms-grid-column-span: 7;
		grid-column: span 7;
	}

	.le-table-col-span-8 {
		-ms-grid-column-span: 8;
		grid-column: span 8;
	}

	.le-table-col-span-9 {
		-ms-grid-column-span: 9;
		grid-column: span 9;
	}

	.le-table-col-span-10 {
		-ms-grid-column-span: 10;
		grid-column: span 10;
	}

	.le-table-col-span-11 {
		-ms-grid-column-span: 11;
		grid-column: span 11;
	}

	.le-table-col-span-12 {
		-ms-grid-column-span: 12;
		grid-column: span 12;
	}

	.le-table-col-span-13 {
		-ms-grid-column-span: 13;
		grid-column: span 13;
	}

	.le-table-col-span-14 {
		-ms-grid-column-span: 14;
		grid-column: span 14;
	}

	.le-table-col-span-15 {
		-ms-grid-column-span: 15;
		grid-column: span 15;
	}

	.le-table-col-span-16 {
		-ms-grid-column-span: 16;
		grid-column: span 16;
	}

	.le-table-col-span-17 {
		-ms-grid-column-span: 17;
		grid-column: span 17;
	}

	.le-table-col-span-18 {
		-ms-grid-column-span: 18;
		grid-column: span 18;
	}

	.le-table-col-span-19 {
		-ms-grid-column-span: 19;
		grid-column: span 19;
	}

	.le-table-col-span-20 {
		-ms-grid-column-span: 20;
		grid-column: span 20;
	}

	.le-table-col-span-21 {
		-ms-grid-column-span: 21;
		grid-column: span 21;
	}

	.le-table-col-span-22 {
		-ms-grid-column-span: 22;
		grid-column: span 22;
	}

	.le-table-col-span-23 {
		-ms-grid-column-span: 23;
		grid-column: span 23;
	}

	.le-table-col-span-24 {
		-ms-grid-column-span: 24;
		grid-column: span 24;
	}

	.le-table-col-span-25 {
		-ms-grid-column-span: 25;
		grid-column: span 25;
	}

	.le-table-col-span-26 {
		-ms-grid-column-span: 26;
		grid-column: span 26;
	}

	.le-table-col-span-27 {
		-ms-grid-column-span: 27;
		grid-column: span 27;
	}

	.le-table-col-span-28 {
		-ms-grid-column-span: 28;
		grid-column: span 28;
	}

	.le-table-col-span-29 {
		-ms-grid-column-span: 29;
		grid-column: span 29;
	}

	.le-table-col-span-30 {
		-ms-grid-column-span: 30;
		grid-column: span 30;
	}

	.le-table-col-span-31 {
		-ms-grid-column-span: 31;
		grid-column: span 31;
	}

	.le-table-col-span-32 {
		-ms-grid-column-span: 32;
		grid-column: span 32;
	}

	.le-table-col-span-33 {
		-ms-grid-column-span: 33;
		grid-column: span 33;
	}

	.le-table-col-span-34 {
		-ms-grid-column-span: 34;
		grid-column: span 34;
	}

	.le-table-col-span-35 {
		-ms-grid-column-span: 35;
		grid-column: span 35;
	}

	.le-table-col-span-36 {
		-ms-grid-column-span: 36;
		grid-column: span 36;
	}

	.le-table-col-span-37 {
		-ms-grid-column-span: 37;
		grid-column: span 37;
	}

	.le-table-col-span-38 {
		-ms-grid-column-span: 38;
		grid-column: span 38;
	}

	.le-table-col-span-39 {
		-ms-grid-column-span: 39;
		grid-column: span 39;
	}

	.le-table-cell {
		text-overflow: ellipsis;
		overflow: hidden;
		line-height: 2;
		margin: 0;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		padding: 8px 0 8px 8px;
		justify-content: space-between;

		& > li {
			box-sizing: border-box;
			align-items: center;
		}

		&.disabled {
			background-color: $palladium;
			color: $gun-metal;
			pointer-events: none;
		}

		.le-table-cell-content {
			display: flex;
			flex: 3;
			align-items: center;
			flex-wrap: nowrap;
			min-width: 0;

			& > span {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				margin-right: 1em;
			}

			&:last-of-type {
				& > span {
					margin-right: 0;
				}
			}
		}

		.le-table-cell-icon {
			margin-left: 1em;
		}

		.le-table-cell-icon-actions {
			@extend .le-table-cell-icon;
			cursor: pointer;
		}

		.le-cell-tools {
			display: flex;
			flex: 0;
			width: 100%;
			flex-wrap: nowrap;
			padding-right: 0.5em;
		}

		.le-cell-template {
			display: flex;
			flex: 1;
			width: 100%;
			flex-wrap: nowrap;
			margin-right: 1em;
		}

		.flex-1 {
			@extend .le-cell-template;
			flex: 1;
		}
	}

	.le-table-cell-centered {
		justify-content: space-around;
	}

	.le-table-cell-right-align {
		justify-content: flex-end;
	}

	.le-table-footer {
		@include display-grid;
		@include columns-grid;
		grid-column: span 12;
		border-top: 0;

		.table-pagination {
			justify-content: flex-end;
		}
	}
}
