@import '../../../../../../../common/node_modules/@dnb-uux-design-system/design-tokens/build/styles/_variables.scss';

.CommonNumberInput {
	height: 36px;
	background: $color-background-white;
	border: 1px solid $color-gray-secondary;
	border-radius: 8px;
	text-align: center;

	&[type='number']::-webkit-inner-spin-button,
	&[type='number']::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}
