.filter-range-slider {
	&,
	& ul {
		display: block;
		width: 100%;
	}

	.range-slider-label {
		display: inline-block;
		font-weight: bold;
		margin-bottom: 0.5em;
	}

	.range-slider-container {
		display: flex;
		align-items: center;

		.range-slider-input {
			&,
			& input {
				width: 4em;
				color: #666;
				border: none;

				&.no-dial {
					&[type='number']::-webkit-inner-spin-button,
					&[type='time']::-webkit-inner-spin-button {
						-webkit-appearance: none;
					}
				}
			}

			input {
				border: 1px solid #ccc;
				padding: 1px;
			}

			&.value-low input {
				text-align: right;
			}
		}

		.range-slider-slider {
			text-align: center;
			padding: 0 1em;
			width: 100%;

			.range-slider-track {
				display: flex;
				align-items: center;
				height: 10px;
				border-left: 1px solid #666666;
				border-right: 1px solid #666666;
				width: 100%;
				position: relative;
				cursor: pointer;

				.range-slider-handle {
					background: #ef6a30;
					position: absolute;
					height: 3px;
					left: 0;
					width: 100%;
					transition: none;

					.handle-low,
					.handle-high {
						background: #ef6a30;
						position: absolute;
						border-radius: 50%;
						height: 0.7em;
						width: 0.7em;
						top: 1px;
						transform: translate(-50%, -50%);
						transition: all 166ms ease;
						pointer-events: none;

						&.active {
							background: #cd4810;
							height: 0.85em;
							width: 0.85em;
						}
					}

					.handle-low {
						left: 0;
					}

					.handle-high {
						left: 100%;
					}
				}
			}
		}
	}
}
