.wrapper {
	overflow-x: auto;
	overflow-y: auto;
	height: 100%;
	min-height: 150px;
}

.table {
	margin: 0;
}

.thead {
	position: sticky;
	top: 0;
	z-index: 1;
	border-bottom: 1px solid darkgrey;
}

.thead button {
	color: #514f4d;
}

.row {
	white-space: nowrap;
	/* !important used to overwrite default table styles */
	background-color: white !important;
}

.cell {
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 0;
	background-color: white;
	transition: background-color ease-in-out 0.2s;
}

// 'not only child' selects only when resize handle is present
th.cell > *:first-child:not(:only-child) {
	// required so that header content does not overlap the resize handle
	max-width: calc(100% - 1.5em);
}

.row[role='button'] {
	cursor: pointer;
}

.row[role='button']:focus {
	outline: 2px solid #457eba;
	z-index: 1;
}

.row[role='button']:hover {
	background-color: #f0f7fc !important;
	cursor: pointer;

	.cell {
		background-color: #f0f7fc;
	}
}

.blockLayout .row {
	display: flex;
}

.blockLayout .cell {
	display: flex;
	justify-content: space-between;
}

.stickyLeftCell {
	position: -webkit-sticky;
	position: sticky;
	z-index: 9999 !important;
}

.rightBorder {
	border-right: 1px solid lightgrey;
	margin-right: 10px;
}

.stickyRightCell {
	position: -webkit-sticky;
	position: sticky;
}

.pushStickyCellRight {
	margin-left: auto;
}

.leftBorder {
	border-left: 1px solid lightgrey;
	margin-left: 10px;
}

.resizeHandle {
	height: 100%;
	width: 0.2em;
	min-width: 0.2em; // required in case the column header shrinks too much
	cursor: ew-resize;
	background: #e8e8e8;
	transition: background 0.1s ease-in-out;
}

.thead:hover .resizeHandle {
	background: #999;
}

.resizing.resizeHandle {
	background: #09f;
}

.clickableRow:hover {
	background-color: #f0f7fc !important;
	cursor: pointer;
	td {
		background-color: #f0f7fc;
	}
}
