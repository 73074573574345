.ScheduleWeeklyComponent {
	display: flex;
	align-items: center;
	margin-left: 16px;
}
.ScheduleMonthlyComponent {
	display: flex;
	flex-direction: column;
	margin-left: 12px;

	> .OptionContainer {
		display: flex;
		align-items: center;

		&:first-child {
			margin-bottom: 8px;
		}
	}

	.mr12 {
		margin-right: 16px;
	}
}
