div.value-picker {
	padding: 0;
	.filters {
		.sort {
			border-radius: 3px;
		}
	}
	div.pagination:first-child {
		margin-top: 1em;
	}
	div.pd-pagination {
		text-align: right;
		float: right;
	}

	div.picker-buckets {
		display: block;
		columns: 3;
		margin: 0em 0 0.5em;

		& > div {
			margin-bottom: 0.5em;

			label {
				position: relative;
				padding-left: 2.333em;
				text-align: left;
				padding-right: 24px;

				&.checked {
					font-weight: bold;
				}

				input {
					position: absolute;
					top: 5px;
					left: 0.5em;
				}

				span {
					position: relative;
				}

				i {
					position: absolute;
					right: 1em;
				}
			}
		}

		&.picker-bucket-header {
			margin: 1em 0 0em;

			& > div {
				margin: 0;

				label {
					margin: 0;
					font-size: 12px;
					color: #999;
					text-transform: uppercase;
				}
			}
		}
	}

	div.advanced-query-builder .query-section {
		.query-section-item {
			pointer-events: none;
			position: relative;
			left: 0;
			.query-section-item-controls {
				display: none;
			}
		}

		.query-section-item-box {
			border-left: none;
			border-bottom: none;
			padding: 0;
			margin: 2em 8em 2em;
		}

		.query-section-item-records {
			.fa-close {
				display: none;
			}
		}
	}
}
