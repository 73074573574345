.messaging {
    display: flex;
    justify-items: space-between;
    position: relative;
    background: white;
    border: 1px solid #888;
    margin: 1.1em 0 0.5em;
    padding: 5px 16px;
    border-radius: 8px;
    font-size: 16px;

    &.error-messaging {
        background-color: rgb(250,230,235);
        border-color: rgba(201, 0, 50, 1);
        .messaging-icon {
            color: rgba(201, 0, 50, 1);
        }
        &.old-error {
            min-height: 2em;
            border-top: none;
            border-bottom: none;
            border-right: none;
            border-left-width: 3px;
            padding-left: 10px;
            background: none;
            display: flex;
            align-items: center;
            position: relative;
            ico {
                margin-right: 5px;
            }
            button {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                background: none;
                border: none;
                font-size: 20px;
            }
        }
    }

    &.warning-messaging {
        background-color: rgb(255,249,240);
        border-color: rgba(250, 193, 102, 1);
        .messaging-icon {
            color: rgba(250, 193, 102, 1);
        }
    }

    &.success-messaging {
        background-color: rgb(242,249,238);
        border-color: rgba(117, 191, 81, 1);
        .messaging-icon {
            color: rgba(117, 191, 81, 1);
        }
    }

    &.info-messaging {
        background-color: rgb(240,246,250);
        border-color: rgba(104, 156, 202, 1);
        .messaging-icon {
            color: rgba(104, 156, 202, 1);
        }
    }

    i.badge {
        position: absolute;
        top: -.5em;
        left: -1.2em;
    }

    .messaging-icon {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding-top: 2px;
        margin-right: 8px;
        color: transparent;

        ico {
            font-size: 20px;
        }
    }
    .messaging-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        color: #222;

        .messaging-title {
            font-weight: bold;
            font-size: .9em;
        }
        strong {
            font-weight: bold;
        }
    }

    .messaging-controls {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        width: 2em;
        margin-top: 4px;

        &:hover .close {
            color: #457EBA;
        }

        .close, .link-button.close-button {
            border: none;
            background: transparent;
            font-size: 11pt;
            display: flex;
            color: #222;

            &, & *, & *:before {
                -webkir-transition: none;
                transition: none;
            }
    	}
    }
}
.modal-content {
    .messaging.error-messaging.old-error {
        margin: 0 30px;
    }
}

.status-alert-box {
    @include flex-container;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    background: white;
    border-radius: 4px;
    border: 1px solid rgb(253,188,84);
    padding: 0;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    &.no_results {
        position: absolute;
        top: 75px;
    }
    .icon {
        @include flex-item(0 0 auto);
        color: white;
        background: rgb(253,188,84);
        position: relative;
        width: 70px;
        &:after {
            @include font-awesome-inline("\f071");
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            text-align: center;
            transform: translateY(-50%);
            font-size: 33px;
        }
    }
    .message {
        @include flex-item(1 1 auto);
        padding: 15px;
        padding-right: 35px;
        strong {
            font-weight: bold;
        }
        i {
            vertical-align: middle;
        }
        p {
            line-height: normal;
            margin-bottom: 10px;
        }
    }
    &.alert {
        position: fixed;
        border-color: rgb(253,188,84);
    }
    &.saved {
        border-color: rgb(111,190,74);
        .icon {
            background: rgb(111,190,74);
            &:after {
                @include font-awesome-inline("\f00c");
            }
        }
    }
    &.error {
        border-color: rgb(210, 32, 39);
        .icon {
            background: rgb(210, 32, 39);
            &:after {
                @include font-awesome-inline("\f071");
            }
        }
    }
    & > .close {
        position: absolute;
        top: 18px;
        right: 10px;
    }
}

.page-error {
	text-align: center;
	background: url('/assets/images/ico-error.png') center top no-repeat;
	background-size: 60px;
    padding-top: 6em;
    margin-top: 2.5em;
    h2 {
    	margin-bottom: 10px;
    }
	h3 {
		color: #a1a0a1;
	    font-size: 1em;
	    margin-bottom: 40px;
	    text-transform: initial;
	    font-weight: normal;
	}
}
