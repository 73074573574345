.numerical-container {
	.error-message {
		padding-top: 0;
		margin-top: 0;
	}
	input[type='number'] {
		outline: none;
		border: 1px solid rgba(204, 204, 204, 0.747);
		max-width: 80px;
		border-color: rgba(204, 204, 204, 0.747);
		height: 1.65em;
		font-size: 12px;
	}
	input[type='number'].invalid-field {
		border-left: 3px solid #b50a20;
		padding-left: 4px;
	}
	input[type='number'].field-disabled {
		background-color: rgba(238, 238, 238, 1);
	}
}
