.lp .secondary-nav button {
    padding: 17px;
}

.lp .tile-list > div {
	width: 100%;
}

.leads-table .data-table {
  border: none;
}

.leads-table th.lead-company {
  width: 35%;
}

.leads-table th.lead-contact {
  width: 25%;
}

.leads-table .lead-converted {
  text-align: center;
}

.leads-table .lead-score {
  text-align: center;
}

.leads-table tbody tr:last-of-type {
  border-bottom: #ccc solid 1px;
}
.model-details .data-table h2 {
	text-align: center;
	font-size: 22px;
	margin-top: 2em;
	font-weight: bold;
}
#performanceTab h2{margin:50px 0;padding:0;text-align:center;width:100%;font-size:1.25em;font-family:'ProximaNova-Bold','Open Sans',sans-serif}#performanceTab h2.strike-through{position:relative;z-index:1}#performanceTab h2.strike-through:before{border-top:1px solid #cccccc;content:"";margin:0 auto;position:absolute;top:50%;left:0;right:0;bottom:0;width:936px;z-index:-1}#performanceTab h2.strike-through span{background:white;padding:0 15px}#performanceTab h2.line-through{display:table;white-space:nowrap}#performanceTab h2.line-through:before,#performanceTab h2.line-through:after{border-top:1px solid #cccccc;content:'';display:table-cell;position:relative;top:0.5em;width:45%}#performanceTab h2.line-through:before{right:1.5%}#performanceTab h2.line-through:after{left:1.5%}#performanceTab .lift-data .data-table{margin-top:20px;margin-bottom:35px}


.lp .tile-list .list-item {
    -webkit-order: 0;
    order: 0;
    -webkit-flex-grow: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 1;
    flex-shrink: 1;
    -webkit-flex-basis: auto;
    flex-basis: auto;
    -webkit-align-self: auto;
    align-self: auto;
    margin-right: 4px;
	border: #d0d0d0 solid 1px;
	padding: 0.75em;
	margin-bottom: 4px;
	width: 100%;
	background: #e7e7e7;
	transition: background 150ms ease;
	height: 240px;
	@include media(">medium") {
		width: calc(50% - 4px);
	}
	@include media(">large") {
		width: calc(33.333% - 4px);
	}
	&:hover {
		cursor: pointer;
	}
}



.lp .tile-list .model {
	.form-inline .form-control {
		width: 100%;
		margin-bottom: 10px;
	}
	
}



.model-details {
	.back-button {
	    padding: 20px 50px 20px 0;
	    margin-left: 20px;
	    background: url('../images/bg_back_button.png') no-repeat left center;
	    color: #999;
	    &:hover {
	    	background: url('../images/bg_back_button_hover.png') no-repeat left center;
	    }
	}
    h1 {
    	float: left;
        padding: 20px 50px 20px 0;
        line-height: 1;
        font-size: 28px;
        font-family:'ProximaNova-Bold','Open Sans',sans-serif;
    }
}




/*
Forms for System SetUp Page
================================================================== */
.form-systemSetup {
    width: 860px;
    margin: 6em auto 0; 
}
    .formSF img, .formMT img {
        margin-bottom: 30px;
        padding-left:30%;
    }
    .formSF, .form-title, .formMT {
        float: left;
    }
        .form-systemSetup .form-title {
            width: 240px;
            font-size: 32px;
            color: #333;
            text-align: right;
            padding-right: 30px;
            padding-bottom: 425px;
            border-right: #ccc solid 1px;
            font-weight: normal;
        }
            .form-systemSetup .form-title h1 {
                font-family:'ProximaNova-Regular','Open Sans',sans-serif;
                font-size: 32px;
                line-height: 1em;
                margin-bottom: 0;
            }
            .form-title span {
                font-size: 10pt;
                color: #666;
                margin-top: 0;
            }

        .formSF, .formMT {
            width: 310px;
        }
        .formSF {
            border-right: #ccc solid 1px;
            padding: 0 30px;
        }
        .formMT {
            padding-left: 30px;
        }

            .formSF .tabpanel .nav-tabs {
                margin-bottom: 20px;
                border:0px;
                text-align: center;
            }
                .formSF .tabpanel .nav > li {
                    padding: 10px 0;
                    border-bottom: #ccc solid 1px;
                }
                .formSF .tabpanel .nav > li.active {
                    border-bottom: #333 solid 1px;
                }
                .formSF .tabpanel .nav > li > a {
                    font-family:'ProximaNova-Regular','Open Sans',sans-serif;
                    font-size:14px;
                    line-height: 12px;
                    font-weight: bold;
                    color:#477cba;
                    text-transform: uppercase;
                    background: none;
                    border: 0;
                    border-radius: 0;
                    padding: 0 15px;
                }
                    .formSF .tabpanel .nav > li:first-of-type > a {
                        border-right: #999 solid 1px;
                    }
                    .formSF .tabpanel .nav > li.active > a {
                        color: #333;
                    }
                    .formSF .tabpanel .nav-tabs a:focus{
                        color:333;
                        background: none;
                        border: 0;
                    }
                    .formSF .tabpanel .nav-tabs a:hover{
                        color:#0177fe;
                        background: none;
                        border:0px;
                    }

            .form-systemSetup label, .form-systemSetup label {
                font-size: 12px;
                color: #999;
                margin-bottom: 2px;
            }
                .form-systemSetup label span, .form-title span span {
                    color: #c70009;   
                }
    .form-systemSetup .form-control {
        color: #333;
        margin-bottom: 14px;
    }
    .form-systemSetup .btn-primary {
        font-size: 10pt;
        padding: 10px 16px;
        margin-bottom:10px;
    }
    .secondary-actions a {
        font-size: 12px;
        color: #2579ad;
        display: block;
    }



/*
Model Detail Attributes
brought over from leadpprioritization/app/assets/style.scss
================================================================== */
.js-top-predictor-donut {
	text-align: center;
}
#attributes h3 {
    text-align: center;
    font-family:'ProximaNova-Regular','Open Sans',sans-serif;
    font-weight: normal;
    font-size: 22px;
    margin-bottom: 30px;
}
#chart span {
    color: #477cba;
    cursor: pointer;                
}
.data .attributes h4 {
    margin-bottom: 0;
    margin-top: 0;
    padding-bottom: 0.5em;
    border-bottom: #ddd solid 1px;
    text-align: left;
    font-family:'ProximaNova-Bold','Open Sans',sans-serif;
    font-weight: normal;
    font-size: 14px;
}

.lattice-attributes {
    padding-right: 7px;
}
.marketo-attributes {
    padding-left: 7px;
}

.attributes.lattice-attributes h4 {
    text-align: right;
}
    .lattice-attributes a, .marketo-attributes a {
        text-align: right;
        color: #666;
        display: block;
        border-top: transparent solid 1px;
        border-bottom: #fff solid 1px;
        font-family:'ProximaNova-Regular','Open Sans',sans-serif;
        font-size: 14px;
    }
    .lattice-attributes a {
        padding: 7px 0 7px 7px;
    }
    .marketo-attributes a {
        padding: 7px 7px 7px 0;
        text-align: left;
    }
        .attributes a:hover {
            color: #747474;
            background: #e1eefd;
            border-top: #ddd solid 1px;
        }
    .attributes span {
        display: inline-block;
        color: #fff;
        width: 33px;
        height: 26px;
        vertical-align: middle;
        text-align: center;
        line-height: 2em;
        -webkit-border-radius: 4px; 
        -moz-border-radius: 4px; 
        border-radius: 4px;
        font-family:'ProximaNova-Bold','Open Sans',sans-serif;
    }
        .lattice-attributes span {
            margin-left: 1em;
        }
            .firmographics span {
                background: #85e7d7;
            }
            .growth-trends span {
                background: #0fb6e0;
            }
        .marketo-attributes span {
            margin-right: 1em;
        }
            .marketing-response span {
                background: #4095e1;
            }
            .other span {
                background: #fe864b;
            }
#topPredictorAttributeHover {
    position: fixed;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

a#back-to-summary-link:hover {
  cursor: pointer;
}

.donut-chart-back-button {
    cursor: pointer;
}

.attribute-hover {
    position: relative;
}

.attribute-hover > div {
    background: white;
    width: 350px;
    text-align: left;
    box-shadow:
    0 0 0 1px #CDCDCD,
    0 0 0 4px hsl(0, 0%, 90%);
    border-radius: 1px;
}

.attribute-hover-header {
    padding: 10px 10px 0 10px;
}

.attribute-hover p {
    font-size: 12px;
    line-height: 14px;
    overflow: hidden;
}

.attribute-hover p.truncate_2lines {
    height: 2.4em;
    position: relative;
    word-wrap: break-word;
}

.attribute-hover p.truncate_2lines:after {
    content: '...';
    position: absolute;
    display: block;
    bottom: 0em;
    right: .5em;
    width: 1.5em;
    text-align: left;
    background: #fff;
}

.attribute-hover h4 {
    height: 1.2em;
    padding-left: 6px;
    margin: 0;
    display: inline-block;
    font-family: 'ProximaNova-Bold';
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    font-size: 16px;
    line-height: 21px;
}

.attribute-hover-left-arrow > div:after,
.attribute-hover-left-arrow > div:before {
    left: 100%;
    top: 50%;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.attribute-hover-left-arrow > div:after {
    border: rgba(255, 255, 255, 0) solid 12px;
    border-left-color: white;
    margin-top: -12px;
}

.attribute-hover-left-arrow > div:before {
    border: rgba(255, 225, 255, 0) solid 13px;
    border-left-color: #BBB;
    margin-top: -13px;
}

.attribute-hover-right-arrow > div:after,
.attribute-hover-right-arrow > div:before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.attribute-hover-right-arrow > div:after {
    border: rgba(255, 255, 255, 0) solid 12px;
    border-right-color: white;
    margin-top: -12px;
}

.attribute-hover-right-arrow > div:before {
    border: rgba(255, 225, 255, 0) solid 13px;
    border-right-color: #BBB;
    margin-top: -13px;
}

.total-attr-values {
    font-family:'ProximaNova-Bold','Open Sans',sans-serif;
    color: #333;
    font-size: 16px;
    margin: 1em 0 2em;
    display: inline-block;
}
a.export-link {
	color: #e86339;
	font-weight: bold;
	text-transform: uppercase;
}
