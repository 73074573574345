.lookup-summary {
	position: relative;
	margin: 0 auto;

	pre {
		padding: 0 0 0 1em;
		white-space: pre-wrap;
	}
	.string {
		color: #629acc;
	}
	.number {
		color: #ff8c36;
	}
	.boolean {
		color: #cc5200;
	}
	.null {
		color: #8d66c6;
	}
	.key {
		color: #f75959;
	}
	div.lookup-back {
		margin: 0em 0 1.5em;
		font-weight: bold;
	}
	ul {
		display: block;
		border-bottom: 1px solid #d0d0d0;
		margin: 1.5em 0 0.5em;
		font-weight: bold;

		li {
			display: inline-block;
			background: #f1f1f1;
			padding: 0.6em 1.5em;
			margin-right: 0.5em;
			margin-bottom: -1px;
			border: 1px solid #d0d0d0;
			border-bottom: none;
			cursor: pointer;

			&.active {
				background: #fafafa;
				border-bottom-color: #fafafa;
				cursor: default;
				color: #ef6a30;
			}

			&:hover {
				text-decoration: underline;
				&.active {
					text-decoration: inherit;
				}
			}
		}
	}
	div.lookup-summary-info {
		position: relative;
		right: 0;
		bottom: 5em;
		text-align: right;
		float: right;
		height: 0;

		span {
			h4 {
			}
		}
	}
	div.lookup-response-area {
		margin: 2em 0 1.5em;
		padding: 2em 2.5em;
		background: #fff;
		border: 1px solid #d0d0d0;
		border-radius: 3px;

		label {
			display: block;
			word-wrap: break-word;
			span {
				font-weight: bold;
				margin-right: 0.333em;
			}
		}
	}
	div.lookup-response-elapsed {
		font-weight: bold;
	}
	div.lookup-back {
		cursor: pointer;
		&:hover {
			text-decoration: underline;
		}
		ico {
			margin-right: 0.33em;
		}
	}
}

.form-group input.form-control.lookup-required {
	border-left-width: 5px;
	border-left-color: #d22027;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
