.SourceListCustomizeColumn {
	width: 240px;
	> div {
		&:last-child {
			margin-bottom: 0;
			padding: 16px 0;
			border-bottom: none;
		}
	}
}
