.le-switch {
	$width: 3em;
	$height: 17px;
	$padding: .3em;
	$borderColor: #ccc;
	$borderTransitionColor: #aaa;
	$toggleSize: 12px;
	$toggleOnColor: #129e4e;
	$toggleOffColor: #ccc;

	position: relative;
	display: inline-block;
	vertical-align: middle;
	box-sizing: content-box;
	width: $width;
	height: $height;
	border: 0.1em solid $borderColor;
	border-radius: 1em;
	cursor: pointer;
	transition: 0.15s ease-in-out;
	transition-property: border-color, box-shadow;

	&:focus-within,
	&:hover {
		box-shadow: 0 0.1em 0.3em -0.1em #3339;
	}

	&:focus-within {
		border-color: $borderTransitionColor;
	}

	&__checkbox {
		appearance: none;
		-webkit-appearance: none;
		font-size: inherit;
		margin: 0;
		cursor: inherit;
		position: absolute;
		top: 50%;
		display: block;
		z-index: 1;
		width: $toggleSize;
		height: $toggleSize;
		background-color: $toggleOffColor;
		border-radius: 50%;
		box-shadow: 0 0.05em 0.2em -0.05em #4447;
		transform: translate($padding, -50%);
		transition: 0.2s ease-in-out;

		&:checked {
			background-color: $toggleOnColor;
			transform: translate(calc(#{$width - $padding} - 100%), -50%);
		}
	}

	&__indicator {
		font-size: 10px;
		display: inline-block;
		position: absolute;
		top: 50%;
		right: $padding;
		transform: translateY(-50%);
		color: #666;
		line-height: 1em;
		user-select: none;
	}

	&__checkbox:checked ~ &__indicator {
		right: unset;
		left: $padding;
	}

	&[disabled] {
		pointer-events: none;
		opacity: 0.5;
	}
}
