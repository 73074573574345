.wrapper {
	position: relative;
	height: 100%;
	overflow: auto;
}

.overlay {
	z-index: 1;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	animation: fadeIn 0.2s ease-out 1;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
