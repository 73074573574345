@import 'common/node_modules/@dnb-uux-design-system/design-tokens/build/styles/_variables.scss';

.editing-container {
	min-width: 560px;
	min-height: 25px;
	.errorMessage {
		color: #b50a20;
		vertical-align: baseline;
		background-color: white;
	}
	.error-date {
		padding-top: 0.2em;
	}
	@media only screen and (max-width: 1000px) {
		min-height: 80px;
	}
	.edit-controller {
		display: flex;
		justify-content: flex-end;
		height: 100%;
		align-items: center;

		.set,
		.unset {
			margin-top: -17px;
		}
	}
	.edit-controllers {
		min-width: 75px;
		height: 100%;
		width: 100%;
		margin-right: 20px;
		display: flex;
		justify-content: space-around;
		align-items: center;
		margin: 0;

		@media only screen and (max-width: 1050px) {
			flex-direction: column;
			// width: 100%;
			flex-wrap: wrap;
			width: 100%;
			border: 1px solid green;
			.set {
				margin-top: -17px;
				margin-bottom: 10px;
			}
			.unset {
			}
		}
		.set,
		.unset {
			margin-top: -17px;
		}
	}
}
div.advancedQueryBuilder {
	&,
	& * {
		-webkit-transition: none;
		-moz-transition: none;
		-ms-transition: none;
		transition: none;
	}

	.queryHelperTextOne {
		margin-top: 1em;
		margin-bottom: 0.5em;
		margin-left: 0.15em;
	}

	.queryHelperTextOneRedesign {
		margin: 24px 0;
	}

	.querySection-divider {
		display: flex;
		vertical-align: text-middle;
		margin: 2em 0em 1em 0em;

		span {
			display: inline-block;
			width: 5em;
			padding-top: 0.2em;
			text-align: center;

			i {
				position: relative;
			}
		}

		div:not(.AddIcon){
			height: 0.8em;
			border-bottom: 1px dashed #888;
			width: 100%;
		}

		div {
			height: 0.8em;
			border-bottom: 1px solid #cbcdce;
			width: 100%;
		}
	}

	.querySectionTypeDotlineBottom {
		position: absolute;
		width: 1.5em;
		border-bottom: 1px solid #cbcdce;
		display: none;

		&.operation {
			margin-top: 13px;
			left: -1.5em;
		}
	}

	.querySectionTypeDotline {
		position: absolute;
		margin-top: 37px;
		left: 0;
		width: 1.5em;
		border-bottom: 1px solid #cbcdce;
		display: none;

		&.operation {
			margin-top: 19px;
			left: 0em;
		}
		&.buttonGroup {
			margin-top: 14px;
		}
		&.intersection {
			margin-top: 52px;
		}
	}

	.querySectionTypeDotlineBottom {
		position: absolute;
		margin-top: 25px;
		width: 1.5em;
		border-bottom: 1px dashed #cbcdce;
		display: none;

		&.operation {
			margin-top: 15px;
			left: -1.5em;
		}
	}

	.querySection {
		li {
			position: relative;
			margin-left: 1.15em;
		}

		&.queryTreeRoot {
			span.queryHelperText {
				display: none;
			}

			div.querySectionTypeDotline {
				display: block;
			}

			div.querySectionTypeDotlineBottom {
				display: block;
			}
		}

		&.queryTreeRoot > li > div:first-child{
			& > div > div.querySectionLabel {
				&> span.queryHelperText {
					display: inline;
					margin-left: 5px;
				}
				.querySectionControls {
					display: none;
				}
				.querySectionOperatorDropdown {
					&.Intersection, &.Union {
						display: none;
					}
				}
			}
		}

		&.queryTreeRoot > li > .querySection {
			.querySectionOperator {
				display: none;
			}

			& > .querySectionLabel {
				padding-top: 0;
			}

			.querySectionControls {
				display: none;
			}
		}

		.querySection-operator {
			position: absolute;
			text-transform: uppercase;
			width: 2em;
			text-align: center;
			z-index: 2;
			display: none !important;
		}

		.querySection-operator.label-operator {
			left: -1.1em;
			top: -1.3em;
			cursor: default;
		}

		.querySection-operator.item-operator {
			display: none;
			top: -0.8em;
			left: -3em;
			cursor: default;
		}

		> li {
			margin-left: 0em;
		}
		.querySectionItem {
			position: relative;
			background: white;
			border-left: 5px solid #5f86da;
			display: flex;
			cursor: pointer;
			border-radius: 8px;
			align-items: center;

			&[disabled] {
				pointer-events: none;
				opacity: 0.2;
			}
			&:hover:not(.querySectionItemEnd) {
				box-shadow: 0px 2px 4px 0px rgba(22, 25, 25, 0.2);
			}
			&.active-link {
				box-shadow: 0px 2px 4px 0px rgba(22, 25, 25, 0.2);
			}
			&.querySectionItemEnd {
				> div {
					display: inline-flex;
					gap: 8px;
				}
				border: none;
				cursor: default;

				& {
					* {
						cursor: default;
					}

					a, button {
						cursor: pointer;
						* {
							cursor: pointer;
						}
						color: #0078a7;
						line-height: 24px;
						padding: 0px 5px;
						margin-top: -2px;
						&:hover {
							background-color: rgba(0, 120, 167, 0.1);
							border-radius: 6px;
						}
					}
					a, button:is(.AddIcon) {
						padding-left: 1.5px;
					}
					a {
						align-items: center;
						margin-left: 5px;
						span {
							margin-right: 5px;
						}
					}
				}

				> span {
					display: inline-block;
					margin: 1em 0em 0 0.25em;
					vertical-align: baseline;
					i {
						margin: 0.2em 0.5em;
					}
				}

				span,
				span * {
					text-decoration: none;
				}
				&[disabled] {
					pointer-events: none;
					opacity: 0.2;
				}
			}

			.querySectionItemID {
				margin: 8px;
			}

			.querySectionItemLabel {
				border-left: none;
				border-right: none;
				padding: 8px;
				display: flex;
				flex-direction: column;
				gap: 8px;
				min-width: 3em;
				text-align: center;
				justify-content: center;
				strong {
					cursor: move;
					display: inline-block;
					color: white;
					width: 1.6em;
					height: 1.6em;
					border-radius: 50%;
					margin: 0 0.25em;
					background: #5f86da;
				}
			}
			.querySectionItemContent {
				width: 100%;
				padding: 0.5em 1em 0.5em 0;
				line-height: 32px;
				display: flex;
				flex-direction: column;
				.querySectionItemCategory {
					font-size: 14px;
					letter-spacing: 0.00062rem;
					line-height: 20px;
					strong {
						max-width: 15em;
						display: inline-block;
						overflow: hidden;
						vertical-align: middle;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
				}
				&.editing {
					flex-direction: unset;
				}

				span.item-edit-controls {
					float: right;

					button {
						border-radius: 0;
						position: relative;
						top: 0;
						bottom: 0;
						margin: 0 -1px 0 -3px;
						height: 100%;
						border-top: none;
						border-bottom: none;

						&.blue-button {
							border: none;
						}

						&.gray-button {
							border: none;
						}
					}
				}

				&.cursorPointer {
					cursor: pointer;
				}

				.querySectionItemDisplayname {
					display: flex;
					align-items: baseline;
					width: 100%;
					.querySectionItemName {
						margin-right: 0.25em;
						white-space: nowrap;
						padding-top: 8px;
					}
					.itemBucketData:not(.member) {
						flex: auto;
					}
					.itemBucketData {
						.querySectionItemOperation {
							width: 100%;
						}
					}
					.itemEditBucketData {
						.querySectionItemOperation {
							width: auto;
						}
					}
					.querySectionItemOperation {
						display: inline-flex;
						margin: 0px 0.5em 0 0;
						align-items: baseline;
						
						.itemBucketData {
							&.member {
								.querySectionItemOperation {
									gap: 8px;
								}
							}
						}
						
						.textContainer.dropDown {
							input {
								width: 100%;
								border: none;
							}
							legend span {
								margin: 0;
							}
							button {
								margin-right: 0;
							}
						}

						.operationLabel span {
							margin: 0px 0.25em 0;
						}

						span.errorMessage {
							margin-left: 10px;
							margin-right: 0;
						}

						button {
							margin-right: 1em;
							width: auto;
							max-width: 45em;
						}

						input {
							width: 10em;
							cursor: text;
						}
						&.Member {
							padding-top: 6px;
						}
						.booleanItem {
							display: inline-flex;
							align-items: center;
						}
					}
					.operationLabel {
						width: max-content;
						margin: 0 8px;
						span {
							white-space: nowrap;
						}
					}
					.querySectionItemValue {
						flex: auto;
						margin-right: 8px;
						enum-attribute {
							span {
								margin: 0;
							}
						}
						.action {
							text-decoration: underline;
							color: #0078a7;
						}
						.transactionLable {
							margin-right: 8px;
						}
					}
					.itemEditBucketData {
						.querySectionItemValue {
							flex: unset;
						}
						width: 100%;
						.querySectionItemOperation:not(.Member) {
							align-items: unset;
						}
						input:not(.MuiInputBase-input), select {
							height: 3.6rem;
							border-radius: 7px;
							border: 1px solid #6B6D6F;
							box-shadow: 0px 1px 3px 0px #16191933 inset;
							background: #FFFFFF;
						}
						.numerical-container.range-container {
							input {
								width: 10em;
								cursor: text;
							}
						}
						.querySectionItemRecords {
							border: unset;
							width: auto;
						}
						.flexContainerController {
							align-items: flex-start;
							.queryBuilderListHr {
								margin: 8px 0 0;
								flex-shrink: 0;
								border-style: solid;
								border-color: rgba(0, 0, 0, 0.12);
								background-color: rgba(203, 205, 206, 0.4);
								border-width: 1px;
								border-radius: 4px;
								height: 40px;
							}
							button {
								margin-top: 10px;
								width: 60px;
								max-height: 3.6rem;
								padding: 1px;
								border-radius: 6px;
								background-color: #0078a7;
								&.disabled {
									opacity: 0.4;
									color: white;
								}
								&.gray-button {
									border-color: #0078a7;
									color: #0078a7;
									background-color: transparent;
								}
								&.model {
									margin-left: 4px;
									margin-right: 4px;
								}
							}							
							.querySectionItemRecords {
								&.model {
									padding-left: 4px;
									padding-right: 4px;
								}
							}
						}
						.querySectionItemCategory {
							flex-direction: column;
						}
					}

					button.unset-button,
					button.set-button {
						float: right;
						margin: -0.25em 0 0 1em;
						padding: 0.3em 0.8em 0.2em;
						border-radius: 3px;
					}
				}
			}

			.querySectionItemClone {
				position: relative;
				width: 5em;
				display: flex;
				justify-content: center;
				align-items: center;
				.contentCopyIcon {
					padding: 6px;
					border-radius: 100%;
					color: #6b6d6f;
				}
			}
			
			.querySectionItemEdit {
				display: flex;
				align-items: center;
				padding: 0 1em;
				width: 4.5em;
				& > * {
					cursor: pointer;
				}
				.editOutlinedIcon {
					padding: 6px;
					border-radius: 100%;
					color: #6b6d6f;
				}
			}

			.querySectionItemRecords {
				position: relative;
				padding: 0.5em 1em;
				line-height: 1.4em;
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: auto;

				span {
					float: left;

					&.querySectionItemRecordsLabel {
						color: #888;
					}

					&.querySectionItemRecordsMumber {
						display: flex;
						flex-direction: column;
					}
				}

				i.fa-close {
					cursor: pointer;
				}
			}

			.querySectionItemRecords.member {
				width: auto;
			}

			&__section-ignored {
				display: flex;
				align-items: center;
				padding-left: 1em;

				&__unused-indicator {
					color: red;
				}
			}

			&__section-ignored.toggle {
				padding-left: 0;
				padding-right: 1em;
				width: 5em;
				border-top-right-radius: 8px;
				border-bottom-right-radius: 8px;
			}

			.querySectionItemControls {
				display: flex;
				align-items: center;
				padding: 0 1em;
				width: 4.5em;
				& > * {
					cursor: pointer;
				}
				.deleteIcon {
					padding: 6px;
					border-radius: 100%;
					color: #6b6d6f;
				}
			}

			span {
				&.querySectionItemRecordsLabel {
					line-height: 24px;
					letter-spacing: 0.00062rem;
					color: #6b6d6f;
				}
			}
			.expanding-bars-init {
				line-height: 20px;
				letter-spacing: 0.00062rem;
			}
		}
		.querySectionItemBox {
			position: relative;
			border-left: 1px solid #cbcdce;
			padding: 0 0 0.5em 1.5em;
			margin-bottom: 0em;

			&.itemIgnored {
				border-bottom: none;
				color: $datacloud-color-ignored;

				.querySectionItem {
					border-left-color: $datacloud-color-ignored;
					.editOutlinedIcon, .contentCopyIcon, .deleteIcon {
						color: $datacloud-color-ignored;
					}
				}

				.item-operator-and,
				.item-operator-or {
					display: none;
				}

				.querySectionItemContent {
					border-right: transparent;
				}
			}

			&.querySectionItemEnd:first-child div.querySectionItemEnd {
				span > a[disabled] {
					opacity: 0.2;
					pointer-events: none;
				}
			}
			&.querySectionItemEnd {
				&.intersection {
					div.querySectionItemEnd>div {
						flex-direction: column;
					}
				}
				a {
					margin-left: 0px;
				}
			}
		}

		.querySectionItemBoxSubtree {
			border-bottom: none;
			padding-top: 3px;
		}

		.querySectionItemBoxActivity {
			a.addAttribute, button.addAttribute {
				display: none;
			}
			.addOperator {
				.operatorType {
					display: none;
				}
				span {
					button.AddIcon.notActivity {
						display: none;
					}
					button.AddIcon.Activity {
						display: inline-flex;
					}
				}
			}
		}

		li.querySectionItemBoxSubtree {			
			.addEvent, addAttributes-directive ~ button.addEvent {
				display: none;
			}
		}

		li.querySectionItemBoxActivity {
			.addEvent, addAttributes-directive ~ button.addEvent {
				display: inline-flex;
			}
		}

		.querySectionItemBoxActivity {
			a.addEvent, addAttributes-directive ~ button.addEvent {
				display: inline-flex;
			}
		}

		.querySectionAll,
		.querySectionAny {
			margin: 0.1em 0 0;
		}

		.querySectionItemEnd {
			border-color: #bbb;
			border-right: none;
			padding-bottom: 0;
		}

		.querySectionItemActivity {
			border-right: none;
			margin-left: 1.15em;
			padding-bottom: 0;
			display: flex;

			button {
				margin: 0 1em;
				max-width: 45em;
			}

			input,
			button {
				height: 2.2em;
				padding: 4px;
				border-radius: 3px;
				border-color: #d0d0d0;
				border-width: 1px;
				border-style: solid;
			}

			input {
				width: 10em;
				cursor: text;
			}

			numerical-range-directive {
				.numerical-container.range-container {
					display: inline-block;
					padding: 0;
				}
				span {
					padding-top: 0.3em;
					margin: 0px 0.25em 0;
				}
			}

			.querySectionItemRecords {
				position: relative;
				width: 10em;
				padding: 0.4em 1em;
				line-height: 1.4em;
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-left: none;
				border-right: none;

				span {
					float: left;

					&.querySectionItemRecordsLabel {
						color: #888;
						margin-right: 5px;
					}

					&.querySectionItem-RecordsNumber {
						flex-direction: column;
					}
				}

				i.fa-close {
					cursor: pointer;
				}
			}
		}
		.querySectionLabel {
			display: flex;
			align-items: center;
			padding-left: 1px;
			line-height: 3.6rem;
			.num-edit-row {
				align-items: center;
				button, input {
					line-height: 2rem;
					height: 3.6rem;
				}
				input {
					border: 1px solid #6B6D6F;
					box-shadow: 0px 1px 3px 0px #16191933 inset;
					border-radius: 7px;
				}
				button {
					border: none;
					box-shadow: none;
				}
				.range-container {
					flex-wrap: nowrap;
				}
			}
			.flexContainerController {
				button {
					width: 60px;
					max-height: 3.6rem;
					padding: 1px;
					border-radius: 6px;
					background-color: #0078a7;
					color: #fff;
				}
				button.disabled {
					opacity: 0.4;
				}
			}
			.queryHelperText {
				margin-right: 8px;
			}
			.querySectionItemOperation {
				white-space: nowrap;
				cursor: pointer;
			}
			&,
			& * {
				-webkit-transition: none;
				-moz-transition: none;
				-ms-transition: none;
				transition: none;
			}

			.querySectionControls {
				.deleteIcon {
					padding: 6px;
					border-radius: 100%;
					color: #6b6d6f;
				}

				&:hover {
					border-style: solid;
					border-color: #457eba;
					background: #fff;
					-webkit-transition: none;
					-moz-transition: none;
					-ms-transition: none;
					transition: none;

					.fa:before {
						color: #457eba;
						-webkit-transition: none;
						-moz-transition: none;
						-ms-transition: none;
						transition: none;
					}
				}
			}

			.querySectionLabelExpand.icon {
				cursor: pointer;
				background: white;
				.dnbButtonIcon {
					text-align: center;
					padding: 6px;
					border-radius: 50%;
					color: #6b6d6f;
				}
			}

			.querySectionOperatorDropdown {
				button {
					width: 245px;
					box-shadow: none;
					border: unset;
				}
			}

			.le-grid .le-row {
				min-height: 25px;
			}
		}			
	}

	.queryTreeRoot .querySectionTypeAnd {
		& > div > div.item-operator-and {
			display: block;
		}

		& > div > div.item-operator-or {
			display: none;
		}
	}

	.queryTreeRoot .querySectionTypeOr {
		& > div > div.item-operator-and {
			display: none;
		}

		& > div > div.item-operator-or {
			display: block;
		}
	}

	.querySectionItemBox:first-child {
		padding-top: 5px;
   		margin-top: 0;
		& > .querySectionBlock {
			& > div > .querySection-operator {
				display: none;
			}
		}
	}

	.dragging,
	.dropped {
		-webkit-transition: none;
		-moz-transition: none;
		-ms-transition: none;
		-o-transition: none;
		transition: none;
	}

	.dragging {
		pointer-events: none;
		position: absolute;
		top: 0px;
		left: 0px;
		perspective: 5px;
		-webkit-transition: none;
		-moz-transition: none;
		-ms-transition: none;
		-o-transition: none;
		transition: none;
		-webkit-transform-origin: bottom left;
		-moz-transform-origin: bottom left;
		-ms-transform-origin: bottom left;
		-o-transform-origin: bottom left;
		transform-origin: bottom left;
		opacity: 0.666;
		z-index: 999;
	}

	.dashed {
		background: white;
		border: 1px dashed #999;
		height: 52px;
		display: block;
		opacity: 0.5;
		margin-top: 0.5em;
		margin-bottom: 0.5em;

		.querySectionItemLabel {
			border: none;
		}
	}
}

div.wizard-content div.advancedQueryBuilder {
	.querySection {
		.querySectionItem.querySectionItemEnd {
			background: white;
		}

		.itemIgnored .querySectionItemContent {
			border-right-color: white;
		}
	}
}
.dragdrop-hover-bg {
	li {
		background: #fafafa;
	}
	& > li {
		background: #eee;
	}
}

div.advancedQueryBuilder {
	.querySection {
		.querySectionItem:not(.canEdit) {
			.querySectionItemLabel {
				strong {
					cursor: default;
				}
				& {
					cursor: default;
				}
			}
		}
		.querySectionLabel:not(.canEdit) {
			.querySectionControls {
				pointer-events: none;
			}
		}
	}
}
