.entity-count {
	font-size: 12px;
	float: right !important;

	&.contacts {
		padding-left: 15px;
	}

	&.accounts {
		padding-right: 15px;
		border-right: 1px solid #dcdcdc;
	}
}

.counts {
	color: #457eba;
}
