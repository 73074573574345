$very-light-gray: #f1f1f1;
$light-gray: #d1d1d1;

.marketplaceSegmentFilters {
	.mpsfHeader {
		font-size: 12px;
		line-height: 24px;
		padding: 14px;
		height: 58px;

		.mpsfTitle {
			margin: 5px;
			display: inline-flex;
			align-items: center;
			justify-content: space-between;
			width: 70px;
		}

		.mpsfClearButton {
			color: #0078a7;
			border: 1px solid $light-gray;
			background-color: white;
			float: right;
			height: 30px;
			line-height: 14px;
			padding: 8px 14px;
			border-radius: 3px;
			font-size: 12px;

			&:hover {
				background-color: $very-light-gray;
			}
		}
	}

	.mpsfBody {
		.mpsfFilter,
		.mpsfSubFilter {
			font-size: 12px;
			padding: 10px 16px;

			cursor: pointer;
			display: flex;

			&:hover {
				background-color: $very-light-gray;
			}

			.mpsfExpand {
				height: 18px;
				width: 18px;
				text-align: center;
				border-radius: 4px;

				&:hover {
					background-color: $light-gray;
				}
			}
		}

		.mpsfFilter {
			border-top: 1px solid $very-light-gray;
			text-transform: uppercase;
			font-weight: 600;
		}

		> *:last-child {
			border-bottom: 1px solid $very-light-gray;
		}

		.mpsfSubFilter {
			padding-left: 48px;
		}

		.mpsfCounter {
			color: #0078a7;
			padding-left: 4px;
			flex: 1;
			line-height: 18px;
		}
	}
}
