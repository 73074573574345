.query-section-item-operation {
	min-width: 100px;
}

div.transaction-container {
	flex: 1;
	width: 100%;
	margin-top: -6px;
	.range-container {
		padding: 0;
	}
	.time-frame {
		margin-left: -4px;
		margin-right: 0;
		& > .time-frame-select {
			margin-left: 4px;
		}
	}
	.transaction-row {
		display: flex;
		justify-content: flex-start;
		width: 100%;
		align-items: center;
		gap: 8px;
		.transaction-element-title {
			min-width: 90px;
			margin-top: 0.2em;
			font-weight: bold;
		}
		.transaction-element {
			padding: 3px 0 3px 5px;
			select {
				min-width: 120px;
			}
		}
	}
	.date-picker-container {
		width: 160px;
		min-width: 160px;
		max-width: 160px;
	}
	.date-picker-container-with-label {
		width: 165px;
		min-width: 165px;
		max-width: 165px;
	}
}
