@mixin flex-container($args...) {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
/**
 * @param flex grow [bool]
 * @param flex shrink [bool]
 * @param initial width [width]
 * etc ...
 */
@mixin flex-item($args...) {
    -webkit-flex: $args;
    -ms-flex: $args;
    flex: $args;
}

/**
 * @param content value
 * looky here for content values: https://astronautweb.co/snippet/font-awesome/
 * 
 */
@mixin font-awesome-inline($args...) {
    content: $args;
    display: block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}
 
@mixin text-ellipsis() {
    text-transform: none;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

@mixin vertical-center-anything() {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

@mixin user-select($args...) {
    -webkit-touch-callout: $args;
    -webkit-user-select: $args;
    -khtml-user-select: $args;
    -moz-user-select: $args;
    -ms-user-select: $args;
    user-select: $args;
}

@mixin box-shadow($fmt) {
    -webkit-box-shadow: $fmt;
    box-shadow: $fmt;
}