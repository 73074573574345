@import 'common/node_modules/@dnb-uux-design-system/design-tokens/build/styles/_variables.scss';

.attr-groups {
	flex-grow: 1;
}

.attr-groups-flex-container {
	display: flex;
	padding: 0 $spacing-2;
}

.attr-sidebar-container {
	display: flex;
	padding: 24px 0;
}

.attr-group {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	margin-bottom: 16px;

	&-display-name {
		font-size: 18px;
		font-weight: bold;

		input {
			width: 200px;
		}
	}

	&-description {
		margin: 12px 0;
		max-width: 400px;
		overflow-wrap: break-word;

		textarea {
			width: 100%;
		}
	}

	&-icon {
		cursor: pointer;

		&:hover {
			color: #0c8bc7;
		}
	}
}

.attr-groups-filters {
	ul.filters {
		display: flex;
		height: 30px;
		align-items: center;
		margin-bottom: 1em;
	}
}

.attr-loading-state {
	font-size: 18px;
	font-weight: bold;
}

.attr-groups-controls {
	display: flex;
	flex-direction: row-reverse;
	flex-wrap: nowrap;

	li {
		margin: 0 4px;
	}
}

.attr-groups-header {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
}

.category-dropdown {
	font-size: 14px;
	height: 30px;
	width: 200px;
	margin-right: 12px;
}
