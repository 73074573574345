/* Global Box Sizing */
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

.pull-left {
  float: left !important;
}
.pull-right {
  float: right !important;
}

/* Whitespace utils
=================================================== */

.no-margin {
	margin: 0 !important;
}

.ml-0 {
	margin-left: 0 !important;
}
.ml-half {
	margin-left: 7px !important;
}
.ml-1 {
	margin-left: 14px !important;
}
.ml-2 {
	margin-left: 28px !important;
}
.ml-3 {
	margin-left: 42px !important;
}

.mr-0 {
	margin-right: 0 !important;
}
.mr-half {
	margin-right: 7px !important;
}
.mr-1 {
	margin-right: 14px !important;
}
.mr-2 {
	margin-right: 28px !important;
}
.mr-3 {
	margin-right: 42px !important;
}

.mt-0 {
	margin-top: 0 !important;
}
.mt-half {
	margin-top: 7px !important;
}
.mt-1 {
	margin-top: 14px !important;
}
.mt-2 {
	margin-top: 28px !important;
}
.mt-3 {
	margin-top: 42px !important;
}

.mb-0 {
	margin-bottom: 0 !important;
}
.mb-half {
	margin-bottom: 7px !important;
}
.mb-1 {
	margin-bottom: 14px !important;
}
.mb-2 {
	margin-bottom: 28px !important;
}
.mb-3 {
	margin-bottom: 42px !important;
}

.pl-0 {
	padding-left: 0 !important;
}
.pl-half {
	padding-left: 7px !important;
}
.pl-1 {
	padding-left: 14px !important;
}
.pl-2 {
	padding-left: 28px !important;
}
.pl-3 {
	padding-left: 42px !important;
}

.pr-0 {
	padding-right: 0 !important;
}
.pr-half {
	padding-right: 7px !important;
}
.pr-1 {
	padding-right: 14px !important;
}
.pr-2 {
	padding-right: 28px !important;
}
.pr-3 {
	padding-right: 42px !important;
}

.pt-0 {
	padding-top: 0 !important;
}
.pt-half {
	padding-top: 7px !important;
}
.pt-1 {
	padding-top: 14px !important;
}
.pt-2 {
	padding-top: 28px !important;
}
.pt-3 {
	padding-top: 42px !important;
}

.pb-0 {
	padding-bottom: 0 !important;
}
.pb-half {
	padding-bottom: 7px !important;
}
.pb-1 {
	padding-bottom: 14px !important;
}
.pb-2 {
	padding-bottom: 28px !important;
}
.pb-3 {
	padding-bottom: 42px !important;
}

.center-content-vertically {
	display: flex;
	align-items: center;
}

/* Background Colors
=================================================== */
.saphire-bkg {
	background: #467dba;
}
.saphire-two-bkg {
	background: #629acc;
}
.saphire-three-bkg {
	background: #65b7e5;
}
.saphire-four-bkg {
	background: #26639b;
}
.aqua-marine-bkg {
	background: #6ce3ff;
}
.amber-bkg {
	background: #f16a22;
}
.amber-two-bkg {
	background: #ff8c36;
}
.amber-three-bkg {
	background: #ffbd48;
}
.amber-four-bkg {
	background: #cc5200;
}
.gold-bkg {
	background: #ffbd48;
}
.gold-two-bkg {
	background: #ffda7d;
}
.gold-three-bkg {
	background: #ffe67b;
}
.gold-four-bkg {
	background: #ef9d18;
}
.jade-bkg {
	background: #6fbe4a;
}
.jade-two-bkg {
	background: #8ed162;
}
.jade-three-bkg {
	background: #a2e264;
}
.jade-four-bkg {
	background: #129e4e;
}


.amethyst-bkg {
	background: #7b4bb2;
}
.amethyst-two-bkg {
	background: #8d66c6;
}
.amethyst-three-bkg {
	background: #9f6fe2;
}
.amethyst-four-bkg {
	background: #663399;
}


.ruby-bkg {
	background: #d22027;
}
.ruby-two-bkg {
	background: #e83a3a;
}
.ruby-three-bkg {
	background: #f75959;
}
.ruby-four-bkg {
	background: #b50b20;
}
