.filter-list {
	&,
	& ul {
		display: block;
		width: 100%;
	}

	.filter-list-label {
		display: flex;
		font-weight: bold;
		margin-bottom: 0.5em;

		span {
			min-width: 2em;
		}

		span:not(:last-child) {
			padding-left: 0.5em;
			padding-right: 1em;
		}

		span:first-child {
			flex-grow: 3;
		}

		span:last-child {
			padding-left: 0.5em;
			padding-right: 0.5em;
		}
	}

	.filter-list-toggles {
		text-align: right;
		margin-top: -0.65em;
		padding: 0 0 0.75em;
		font-size: 8pt;

		span {
			color: #457eba;
			cursor: pointer;
			text-transform: uppercase;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.filter-list-container {
		max-height: 15.4em;
		overflow: auto;
		overflow-x: hidden;
		width: 100%;
		transition: none;

		li {
			transition: none;

			&:hover {
				background-color: #f0f7fc;
			}

			label {
				display: flex;
				position: relative;
				height: 1.9em;
				justify-content: flex-start;

				input {
					position: relative;
				}

				span {
					min-width: 2em;
				}

				span:not(:last-child) {
					overflow: hidden;
					white-space: nowrap;
					display: inline-block;
					padding-left: 0.5em;
					padding-right: 1em;

					i {
						color: darkgray;
						margin-left: 0.75em;
					}
				}

				span.list-item-icon {
					max-width: 1em;
					min-width: 1em;
					padding: 0;
					margin-left: 0.75em;

					i {
						margin: 0;
					}
				}

				span.list-item-label {
					text-overflow: ellipsis;
					flex-grow: 3;
				}

				span:last-child {
					padding-left: 0.5em;
					padding-right: 0.5em;
				}
			}
		}
	}
}
