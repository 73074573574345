.attr-list-results.attr-edit-list {
	li {
		min-height: 47px;
		height: unset;

		div {
			position: relative;
			height: 100%;
			width: 100%;
			line-height: 1em;
			vertical-align: middle;
			padding: 0.33em 1em;

			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
			}

			label {
			}

			input,
			textarea {
				&.ng-pending {
					border-color: #457eba;
					border-left-width: 6px;
				}

				&.ng-valid-saved {
					border-color: #6fbe4a;
					border-left-width: 6px;
					transition: all 0.25s ease;
				}

				&.ng-invalid-saved {
					border-color: #d0011c;
					border-left-width: 6px;
					transition: all 0.25s ease;
				}
			}

			textarea {
				min-height: 3.5em;
				width: 100%;
			}

			&.edit-attribute-name {
				max-width: 25%;

				label {
					padding-left: 0;
					width: 100%;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}

			&.edit-attribute-displayname {
				max-width: 25%;
				em {
					right: 0;
					top: 50%;
					transform: translateY(-50%);
					opacity: 0.5;
				}

				p {
					float: right;
					font-size: 0.75em;
					color: #d0011c;
					display: initial;
				}
			}

			&.edit-attribute-description {
				max-width: 50%;
				position: relative;
				em {
					right: 0;
					top: 50%;
					transform: translateY(-50%);
					opacity: 0.5;
				}
			}
		}
	}
}
