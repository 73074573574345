@import '../colors.scss';

.reactBannerContainer {
	display: flex;
	justify-content: center;
	margin: 0 16px;

	.leBannersContainer {
		width: 100%;
		max-width: 1200px;
	}

	.leBanner {
		display: flex;
		width: 100%;
		max-width: 1200px;
		margin-top: 30px;
		padding: 0;
		border-radius: 8px;

		.leBannerIconContainer {
			width: 3em;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			color: $pearl-white;
			padding-top: 7px;

			i {
				font-size: 17px;
			}
		}

		.leBannerContent {
			flex: 1;
			display: flex;
			padding: 10px 0;
			flex-direction: column;

			.leBannerTitle {
				font-weight: bold;
			}
		}

		.leBannerCloseContainer {
			width: 2em;
			padding: 0;
			padding-top: 10px;
			display: flex;
			align-items: flex-start;
			justify-content: center;

			& > i:after {
				display: block;
				color: black;
				content: '\2716';
				width: 100%;
				height: 100%;
			}

			& > i:hover {
				cursor: pointer;
				opacity: 0.5;
			}
		}

		&.error {
			@extend .leBanner;
			background-color: rgba(201, 0, 50, 0.1);
			border: 1px solid rgba(201, 0, 50, 1);

			.leBannerIcon::after {
				color: rgba(201, 0, 50, 1);
				content: '\f071';
				font-family: FontAwesome;
			}
		}

		&.info {
			@extend .leBanner;
			background-color: rgba(104, 156, 202, 0.1);
			border: 1px solid rgba(104, 156, 202, 1);

			.leBannerIcon::after {
				color: rgba(104, 156, 202, 1);
				content: '\f05a';
				font-family: FontAwesome;
			}
		}

		&.success {
			@extend .leBanner;
			background-color: #f1f9ee;
			border: 1px solid rgba(117, 191, 81, 1);

			.leBannerIcon::after {
				color: rgba(117, 191, 81, 1);
				content: '\f058';
				font-family: FontAwesome;
			}
		}

		&.warning {
			@extend .leBanner;
			background-color: rgba(250, 193, 102, 0.1);
			border: 1px solid rgba(250, 193, 102, 1);

			.leBannerIcon::after {
				color: rgba(250, 193, 102, 1);
				content: '\f06a';
				font-family: FontAwesome;
			}
		}

		.leCloseIcon::after {
			color: rgba(34, 40, 49, 1);
			content: '\f00d';
			font-family: FontAwesome;
		}

		.blueBadge {
			cursor: pointer;
		}

		.longWord {
			word-wrap: break-word;
		}
	}
}

.timeout {
	animation: horizontalSquash 333ms ease 5s;
}

@keyframes horizontalSquash {
	from {
		transform: rotateY(0deg);
	}
	to {
		transform: rotateY(90deg);
	}
}
