.tiles {
    margin: 0 0 3px;
    padding: 0;
    & > li {
        min-height: 300px;
        padding: 13px 0 60px;
        position: relative;
        margin-bottom: 26px;
        vertical-align: top;
        background-color: #fff;
        border-style: solid;
        border-color: #d0d0d0;
        border-width: 1px;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
        &:hover {
            transition: all 0ms ease 0s;
            -webkit-box-shadow: 0px 3px 8px 0px rgba(128, 211, 239, 0.35);
            -moz-box-shadow: 0px 3px 8px 0px rgba(128, 211, 239, 0.35);
            box-shadow: 0px 3px 8px 0px rgba(128, 211, 239, 0.35);
            cursor: pointer;
            &, & * {
                border-color: #6A9ACE;
                transition: none;
            }
        }
        &:active {
            border-color: #72b8e8;
        }
        &.loading-tile {
            background-color: rgba(255, 255, 255, 0.3);
            .tile-header h2, .tile-metadata, .tile-body {
                span {
                    display: inline-block;
                    background: #ececec;
                    height: 10px;
                    width: 35%;
                }
            }
            .tile-body {
                div {
                    text-align: center;
                    margin-top: 20px;
                }
                i {
                    font-size: 26px;
                    color: #ddd;
                }
            }
            .tile-footer {
                color: #8a8a8a;
            }
        }
        &.no-style {
            background: none;
            border: none;
            padding: 0;
        }
        &.create-tile {
            background: transparent;
            border: #7b7b7b dashed 1px;
            &:hover {
                border-color: #457eba;
                border-style: solid;
                & * {
                    border-color: transparent;
                    transition: none;
                }
            }
            .form-group {
                margin-bottom: 10px;
            }
            .actions {
                position: absolute;
                bottom: 13px;
                width: 90%;
            }
        }
        &.editing {
            &:hover {
                border-color:#d0d0d0;
            }
            .tile-footer {
                border-top-color: #e7e7e7;
                span {
                    color: #bbb;
                }
                .button {
                    cursor: default;
                    border: #b7b7b7 solid 1px;
                    background-color: #e4e4e4;
                    color: #656565;
                    box-shadow: none;
                }
            }
        }
        
        form {
          padding: 0 13px 13px;
        }
    }
}

.tile-header, .tile-metadata, .tile-body, .tile-footer {
    padding: 0 13px;
}

.tile-header {
    word-wrap: break-word;
    margin-bottom: 14px;
    margin-top: 6px;
    h2 {
        background-size: 15px;
        background-repeat: no-repeat;
        background-position: left;
        padding-left: 22px;
        margin: 0;
        color: #333333;
        font-family:'ProximaNova-Semibold','Open Sans',sans-serif;
        padding-right: 1.25em;
        font-size: 14px;
        text-align: left;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:after {
            color: #bfbfbf;
            font-style: normal;
            font-weight: normal;
            top: 1em;
            right: 1em;
            position: absolute;
        }
    }
}
.tile-controls,
.tile-header {
    .edit {
        top: 9px;
        right: 4px; 
        position: absolute;
        button {
            padding: 0.5em 0.75em 0.35em 0.8em;
            margin-right: 0;
            font-size: 14px;
            transition: all 0s linear;
            background-color: transparent;
            border: 0;
            border-bottom-width: 1px;
            &:hover {
                * {
                    color: #f16a22;
                }
            }
            &.open {
                background: #222;
                color: #fff;
                border-radius: 4px;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;

                &:hover * {
                  color: #fff;
                }
            }
            span, span:after, span:before {
                transition: background 0s linear, color 0s linear;
            }
        }
    }
}
.tile-metadata {
    padding: 12px;
    border-color: #ddd;
    border-style: solid;
    border-width: 1px 0;
    font-size: 12px;
    line-height: 1.25;
}
.tile-body {
    padding-top: 16px;
    padding-bottom: 16px;
    strong {
        font-weight: bold;
    }
    h4 {
        text-align: center;
        font-size: 16px;
    }
    .bucket-loading {
        color: #bfbfbf;
        font-size: 24px;
        text-align: center;
        display: block;
        margin-top: 15px;
    }
    .attributesDisplay {
        display: block;
        font-size: 12px;
        word-break: break-word;
        &.centered {
            text-align: center;
        }
        &.italicized {
            font-style: italic;
        }
        em {
            color: #467dba,
        }
    }
}
.tile-footer {
    position: absolute;
    bottom: 0;
    border-top: #ddd solid 1px;
    padding: 13px;
    width: 100%;
    &:after {
        display: table;
        content: "";
        clear: both;
    }
    .button {
        font-size: 12px;
        padding: 0px 10px;
        text-transform: none;
    }
    .launch-success i {
        color: #70bf4a;
        display: inline-block;
        margin-left: 4px;
        font-size: 17px;
    }
    &.segments {
        padding: 0px 13px;
    }
    .account-count {
        border-right: 1px solid #ddd; 
        text-align: left; 
        padding: 8px
    }
    .contact-count {
        text-align: left; 
        padding:8px 0px 8px 13px; 
        margin-left:0px;
    }
    .entity-title {
        font-size: 11px;
    }    
    .inactive {
        color: #a0a0a0;
    }
}


@media (min-width: 640px) and (max-width: 799px) {
    .tiles > li {
        display: inline-block;
        width: 49%;
        margin: 0 1% 6px;
		&:nth-child(2n+1) {
			margin-left: 0;
		}
		&:nth-child(2n) {
			margin-right: 0;
		}
    }
}
// @media (min-width: 800px) and (max-width: 1023px) {
@media (min-width: 800px) {
    .tiles > li {
      display: inline-block;
      width: 32%;
      margin: 0 1% 16px;
      &:nth-child(3n+1) {
        margin-left: 0;
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
      .meta-data {
          position: absolute;
      }
    }
}
// @media (min-width: 1024px) {
//     .tiles > li {
//       display: inline-block;
//       width: 23.2%;
//       margin: 0 1% 26px;
//       &:nth-child(4n+1) {
//         margin-left: 0;
//       }
//       &:nth-child(4n) {
//         margin-right: 0;
//       }
//     }
//     .tiles.play-tiles > li {
//       display: inline-block;
//       width: 31%;
//       margin: 0 1% 16px;
//       &:nth-child(3n+1) {
//         margin-left: 0;
//       }
//       &:nth-child(3n) {
//         margin-right: 0;
//       }
//       .meta-data {
//           position: absolute;
//       }
//     }
// }
