@import 'common/node_modules/@dnb-uux-design-system/design-tokens/build/styles/_variables.scss';

.CommonMultipleSelectGroup {
	padding: 8px 0;

	.commonCheckboxGroup {
		margin: 4px 0;
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: center;
	}

	.isDraggable {
		justify-content: flex-start;

		&:hover {
			background: darken($color-background-white, 5%);
			cursor: move;

			.draggableIcon,
			.draggableIcon svg {
				cursor: move;
			}
		}
	}

	.draggableIcon {
		display: flex;
		align-items: center;
		margin: 0 8px;
	}
}
