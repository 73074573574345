.edit-form {
	& > div {
		padding: 0 0 13px;
	}
	.form-control:required:not(.ng-dirty) {
		border-left-color: #aaa;
		border-left-width: 1px;
	}
	.form-control.ng-invalid-required:required {
		border-left-color: #d22027;
		border-left-width: 5px;
	}
}
