@import 'common/widgets/colors.scss';

.leCopyControlls {
	width: inherit;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: flex;
	font-size: 12px;
	& > span {
		width: inherit;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: inline-block;
		font-weight: normal;
	}
	.leCopyControl {
		min-width: 26px;
		padding: 0.3em;
		color: #3488c6;
		&.right {
			margin-left: 4px;
		}
		&.left {
			margin-right: 0;
		}
		&:hover {
			cursor: pointer;
			color: #629acc;
		}
	}
}

.pathValue:hover {
	padding-right: 25px;
	.pathCopy {
		display: initial;
	}
}

.pathValue {
	position: relative;
	> .pathCopy {
		display: none;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
	}
}
