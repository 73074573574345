@import 'common/node_modules/@dnb-uux-design-system/design-tokens/build/styles/_variables.scss';

.CustomizeColumnContextMenu {
	> div {
		&:first-child {
			margin-bottom: 12px;
		}
		&:last-child {
			margin-bottom: 0;
			padding: 17.5px 0;
			border-bottom: none;
			label {
				margin-left: 10px;
				color: $color-gray-primary;
			}
		}
	}
}

.Header {
	font-size: 16px;
}
