@import 'widgets/colors.scss';

.EIFCommonTable {
	height: 41px;
	&:global(.le-table-row) {
		&.IsEntityRelationConfirmed {
			background-color: #fff9f0;
		}
	}
	&:global(.le-table-header) {
		text-transform: none !important;
		border-top: 1px solid $silver;
		border-bottom: 1px solid $silver;

		:global(.le-table-cell) {
			font-size: 14px;
			font-weight: 600;
			color: #222831;
		}

		:global(.le-table-cell-content) > div,
		:global(.le-table-cell-content) > span {
			padding-top: 8px;
		}

		:global(.notSortingBy) {
			color: #aaa;
		}

		:global(.sortIcon) {
			margin-left: 10px;
			width: 10px;
			height: 12px;
		}
	}
	&:global(.le-table-body) {
		border-bottom: none !important;
	}
}

.NameTableHeaderComponent {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
}

.Text {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
