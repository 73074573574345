#JourneyStageEditDialog {
	textarea {
		min-height: unset;
	}
}

#JourneyStageEditDialogDescription .DNBInputBase {
	height: 9.3rem;
	textarea {
		min-height: unset;
		height: 8rem !important;
		overflow: auto !important;
	}
}
