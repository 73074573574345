.progress {
	h2 {
		margin: 0 0 10px;
	}
}


/* 
Spinner Balls 
============================================================ */
.loading {
	width: 18px;
	height: 18px;
	display: inline-block;
	background: url("../images/spin.svg") no-repeat center;
	background-size: 18px 18px;
}
.loading-small {
	width: 18px;
	height: 18px;
	display: inline-block;
	background: url("../images/ajax-loader.gif") no-repeat center;
	background-size: 18px 18px;
}

.loading-spinner {
	position: absolute;
	z-index: 100;
	opacity: 0;
	transition: opacity 500ms ease-out 250ms;

	&.show-spinner {
		opacity: 1;
	}

	div.meter {
		overflow: hidden;
	}
}

/**
 * lattice spinner
 * ShowSpinner(<text>, 'lattice'); // default is also lattice
 */
.loading-spinner.lattice:not(.bar) {
	position: absolute;
	top: 5em;
	left: 50%;
	transform: translateX(-50%);
	-webkit-font-smoothing: antialiased;
	.meter {
		background: url(/assets/images/spinners/spinner-lattice-dots.gif) center center no-repeat;
		background-size: contain;
		height: 100px;
		width: 100px;
	    margin: 0.5em auto;
	}
	&.small, &.size-20x20 {
		 .meter {
			background-image: url(/assets/images/spinners/spinner-lattice-dots-20x20.gif);
			height: 20px;
			width: 20px;
		}
	}
	&.small, &.size-40x40 {
		 .meter {
			background-image: url(/assets/images/spinners/spinner-lattice-dots-40x40.gif);
			height: 40px;
			width: 40px;
		}
	}
	&.small, &.size-50x50 {
		 .meter {
			background-image: url(/assets/images/spinners/spinner-lattice-dots-50x50.gif);
			height: 50px;
			width: 50px;
		}
	}
	&.small, &.size-100x100 {
		 .meter {
			background-image: url(/assets/images/spinners/spinner-lattice-dots-100x100.gif);
			height: 100px;
			width: 100px;
		}
	}
	&.small, &.size-200x200 {
		 .meter {
			background-image: url(/assets/images/spinners/spinner-lattice-dots-200x200.gif);
			height: 200px;
			width: 200px;
		}
	}
	&.small, &.size-300x300 {
		 .meter {
			background-image: url(/assets/images/spinners/spinner-lattice-dots-300x300.gif);
			height: 300px;
			width: 300px;
		}
	}
	&.small, &.size-400x400 {
		 .meter {
			background-image: url(/assets/images/spinners/spinner-lattice-dots-400x400.gif);
			height: 400px;
			width: 400px;
		}
	}
	h2 {
		font-size: 16px;
	}
}

/* 
Progress Bar 
ShowSpinner(<text>, 'bar');
============================================================ */
// .bar {
// 	background: white;
// 	border: none;
// 	border-radius: 6px;
// 	box-shadow: 0 0px 75px 50px rgba(255,255,255,.9);
// 	&.loading-spinner {
// 		top: 10em;
// 		left: 30%;
// 		right: 30%;
// 		margin: -2.5em 0 0;
// 		padding: 1.25em;
// 	}
//     h2 {
//       display: inline-block;
//       position: absolute;
//       top: 1.6em;
//       left: 0;
//       right: 0;
//       margin: 0 auto;
//       font-size: 125%;
//       text-align: center;
//       z-index: 1;
//       text-shadow: 1px 1px 0px #aaa, -1px -1px 0px #aaa, -1px 1px 0px #aaa, 1px -1px 0px #aaa;
//       text-transform: uppercase;
//       letter-spacing: 3px;
//       color: #fff;
//       font-weight: bold;
//     }
// }
.meter.bar {
	height: 36px; 
	position: relative;
	background: #fefefe;
	border: #ccc solid 1px;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	-webkit-box-shadow: inset 0 -1px 1px rgba(255,255,255,0.3);
	-moz-box-shadow   : inset 0 -1px 1px rgba(255,255,255,0.3);
	box-shadow        : inset 0 -1px 1px rgba(255,255,255,0.3);

	& > span {
		display: block;
		-webkit-transition: width 4s;
		transition: width 4s;
		height: 100%;
		background-color: #a2e264;
		position: relative;
		overflow: hidden;
		&.indeterminate {
			width: 0%;
		}
	}
	& > span:after, .animate > span > span {
		content: "";
		position: absolute;
		top: 0; left: 0; bottom: 0; right: 0;
		background-image:
		   -webkit-gradient(linear, 0 0, 100% 100%,
			  color-stop(.25, rgba(255, 255, 255, .2)),
			  color-stop(.25, transparent), color-stop(.5, transparent),
			  color-stop(.5, rgba(255, 255, 255, .2)),
			  color-stop(.75, rgba(255, 255, 255, .2)),
			  color-stop(.75, transparent), to(transparent)
		   );
		background-image:
			-moz-linear-gradient(
			  -45deg,
			  rgba(255, 255, 255, .1) 25%,
			  transparent 25%,
			  transparent 50%,
			  rgba(255, 255, 255, .1) 50%,
			  rgba(255, 255, 255, .1) 75%,
			  transparent 75%,
			  transparent
		   );
		z-index: 1;
		-webkit-background-size: 50px 50px;
		-moz-background-size: 50px 50px;
		background-size: 50px 50px;
		-webkit-animation: move 2.5s linear infinite;
		-moz-animation: move 2.5s linear infinite;
		animation: move 2.5s linear infinite;
		-webkit-border-radius: 8px;
		   -moz-border-radius: 8px;
				border-radius: 8px;
		overflow: hidden;
	}
	&.animate > span:after {
		display: none;
	}
}
@-webkit-keyframes move {
	0% {
	   background-position: 0 0;
	}
	100% {
	   background-position: 50px 50px;
	}
}

@-moz-keyframes move {
	0% {
	   background-position: 0 0;
	}
	100% {
	   background-position: 50px 50px;
	}
}
.orange > span {
	background-color: #f1a165;
	background-image: -moz-linear-gradient(top, #f1a165, #f36d0a);
	background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0, #f1a165),color-stop(1, #f36d0a));
	background-image: -webkit-linear-gradient(#f1a165, #f36d0a);
}
.red > span {
	background-color: #f0a3a3;
	background-image: -moz-linear-gradient(top, #f0a3a3, #f42323);
	background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0, #f0a3a3),color-stop(1, #f42323));
	background-image: -webkit-linear-gradient(#f0a3a3, #f42323);
}
.nostripes > span > span, .nostripes > span:after {
	-webkit-animation: none;
	-moz-animation: none;
	animation: none;
	background-image: none;
}

/* 
Wizard Steps 
============================================================ */
.wizard {
	list-style-type: none;
	list-style-position: inside;
	margin: 0 0 20px;
	padding: 0;
	background: #fff;
	border: #959595 solid 1px;
	li {
		display: block;
		margin-bottom: 12px;
		padding: 20px 20px 10px;
		border-bottom: dotted #959595 1px;
		width: 100%;
		&:last-child {
			margin-bottom: 0;
			border-bottom: 0;
		}
		.load-data, .match-cloud, .building-model, .scoring-training-set {
		    padding-left: 40px;
		    background-repeat: no-repeat;
		    background-position: left;
		}
		.load-data {
		    background-image: url("/assets/images/ico-status-process-file-inactive.png");
		}
		.match-cloud {
		    background-image: url("/assets/images/ico-status-data-cloud-inactive.png");
		}
		.building-model {
		    background-image: url("/assets/images/ico-status-building-model-inactive.png");
		}
		.scoring-training-set {
		    background-image: url("/assets/images/ico-status-scoring-inactive.png");
		}
		&.success {
			.load-data {
		        background-image: url("/assets/images/ico-status-process-file-success.png");
		    }
		    .match-cloud {
		        background-image: url("/assets/images/ico-status-data-cloud-success.png");
		    }
		    .building-model {
		        background-image: url("/assets/images/ico-status-building-model-success.png");
		    }
			.scoring-training-set {
			    background-image: url("/assets/images/ico-status-scoring-success.png");
			}
		}
		&.error {
			.load-data {
		        background-image: url("/assets/images/ico-status-process-file-failed.png");
		    }
		    .match-cloud {
		        background-image: url("/assets/images/ico-status-data-cloud-failed.png");
		    }
		    .building-model {
		        background-image: url("/assets/images/ico-status-building-model-failed.png");
		    }
			.scoring-training-set {
			    background-image: url("/assets/images/ico-status-scoring-failed.png");
			}
		}
		p {
			font-size: 12px;
		}
		div {
			&:after {
				display: table;
				content: "";
				clear: both;
			}
			& > span {
				padding-top: 25px;
				padding-bottom: 8px;
				min-height: 70px;
				&:first-child {
					float: left;
				}
				&:last-child {
					float: right;
					padding-top: 25px;
					padding-right: 0;
				}
				.fa-exclamation-triangle {
					color: #ca2b2b;
				}
			}
		}
	}
	@include media(">600px") {
		background: transparent;
		border: none;
		li {
			display: inline-block;
			position: relative;  
			border-bottom: 0;
			padding: 0 14px;	
		    margin-right: 1.5%;
		    width: 22%;
			text-align: left;
			&:after, &:before, &:last-child {
				background: #fff;
				border-color: #959595;
				border-style: solid;
				border-width: 1px;
			}
			&:after, &:before {
			  display: block;
			  content: '';
			  position: absolute;
			}
			&:after {
			  top: -1px;
			  left: -15px;
			  right: 0;
			  height: calc(50% - -1px);
			  transform: skewX(45deg);
			  border-bottom: 0;
			}
			&:before {
			  left: -15px;
			  right: 0;
			  bottom: -1px;
			  height: calc(50% - -1px);
			  transform: skewX(-45deg);
			  border-top: 0;
			}
			&:first-child {
				position: relative;
				background: #fff;
				border: #959595 solid 1px;
				border-right: 0;
				top: 0px;
				&:after, &:before {
					left: 100%;
					transform: none;
					top: 50%;
					border: solid transparent;
					content: " ";
					height: 0;
					width: 0;
					position: absolute;
					pointer-events: none;
				}
				&:after {
					border-color: rgba(255, 255, 255, 0);
					border-left-color: #fff;
					border-width: 35px;
					margin-top: -35px;
					background: none;
				}
				&:before {
					border-color: rgba(149, 149, 149, 0);
					border-left-color: #959595;
					border-width: 36px;
					margin-top: -36px;
					background: transparent;
				}

			}
			&:nth-child(2) {
				margin-left: 17px;
			}
			&:last-child {
				margin-bottom: 12px;
				border-left: 0;
				margin-right: 0;
				&:after, &:before {
					border-right: 0;
					right: 20px;   
				}
			}
			.load-data, .match-cloud, .building-model, .scoring-training-set,
			&.success .load-data, &.success .match-cloud, &.success .building-model, &.success .scoring-training-set,
			&.error .load-data, &.error .match-cloud, &.error .building-model, &.error .scoring-training-set{
				background-image: none;
				padding-left: 0;
			}
			&.success {
				div > span {
					padding-top: 16px;
				}
			}
			&.success {
				&:after, &:before, &:last-child {
			    	border-color: #82c460;
			    }
				&:first-child {
					border: #82c460 solid 1px;
					border-right: 0px;
					&:before {
						border-color: rgba(130, 196, 96, 0);
						border-left-color: #82c460;
					}
					&:after {
						border-color: rgba(255, 255, 255, 0);
						border-left-color: #fff;
					}
				}
			}
			&.error {
				&:after, &:before, &:last-child {
			    	border-color: #ca2b2b;
			    }
				&:first-child {
					border: #ca2b2b solid 1px;
					border-right: 0px;
					&:before {
						border-color: rgba(202, 43, 43, 0);
						border-left-color: #ca2b2b;
					}
					&:after {
						border-color: rgba(255, 255, 255, 0);
						border-left-color: #fff;
					}
				}
			}
			&.current {
				&:after, &:before, &:last-child {
			    	border-color: #235cff;
			    }
				&:first-child {
					border: #235cff solid 1px;
					&:before {
						border-color: rgba(35, 92, 255, 0);
						border-left-color: #235cff;
					}
					&:after {
						border-color: rgba(255, 255, 255, 0);
						border-left-color: #fff;
					}
				}
			}
			// span:last-child {
			// 	display: none;
			// }
			p {
				margin-bottom: -5px;
				font-size: 11px;
			}
			div {
				position: relative;
				z-index: 1;
			}
		}
	}
	@include media(">800px") {
		li {
			padding: 0 13px;
			width: 22.6%;
			margin-right: 1.95%;
			.load-data, .match-cloud, .building-model, .scoring-training-set, 
			&.success .load-data, &.success .match-cloud, &.success .building-model, &.success .scoring-training-set,
			&.error .load-data, &.error .match-cloud, &.error .building-model, &.error .scoring-training-set {
				padding-left: 25px;
				padding-top: 21px;
				background-size: 18px;
			}
			.load-data {
			    background-image: url("/assets/images/ico-status-process-file-inactive.png");
			}
			.match-cloud {
			    background-image: url("/assets/images/ico-status-data-cloud-inactive.png");
			}
			.building-model {
			    background-image: url("/assets/images/ico-status-building-model-inactive.png");
			}
			.scoring-training-set {
			    background-image: url("/assets/images/ico-status-scoring-inactive.png");
			}
			&.success {
				.load-data {
			        background-image: url("/assets/images/ico-status-process-file-success.png");
			    }
			    .match-cloud {
			        background-image: url("/assets/images/ico-status-data-cloud-success.png");
			    }
			    .building-model {
			        background-image: url("/assets/images/ico-status-building-model-success.png");
			    }
				.scoring-training-set {
				    background-image: url("/assets/images/ico-status-scoring-success.png");
				}
			}
			&.error {
				.load-data {
			        background-image: url("/assets/images/ico-status-process-file-failed.png");
			    }
			    .match-cloud {
			        background-image: url("/assets/images/ico-status-data-cloud-failed.png");
			    }
			    .building-model {
			        background-image: url("/assets/images/ico-status-building-model-failed.png");
			    }
				.scoring-training-set {
				    background-image: url("/assets/images/ico-status-scoring-failed.png");
				}
			}
			.fa-check {
				padding-top: 10px;
			}
		}
	}
	@include media(">900px") {
		li {
			margin-right: 1.98%;
		}
	}
	@include media(">1040px") {
		li {
			margin-right: 2.1%;
		}
	}
	@include media(">1260px") {
		li {
			width: 22.8%;
			margin-right: 2%;
			.load-data, .match-cloud, .building-model, .scoring-training-set, 
			&.success .load-data, &.success .match-cloud, &.success .building-model, &.success .scoring-training-set,
			&.error .load-data, &.error .match-cloud, &.error .building-model, &.error .scoring-training-set {
				background-size: 18px;
			}
		}
	}
	@include media(">1590px") {
		li {
			width: 22.7%;
			margin-right: 2%;
		}
	}
	@include media(">1620px") {
		li {
			width: 22.8%;
			margin-right: 2%;
		}
	}
}
.open-nav {
	.wizard {
		@include media(">400px") {
			li {
				.load-data, .match-cloud, .building-model, .scoring-training-set, 
				&.success .load-data, &.success .match-cloud, &.success .building-model, &.success .scoring-training-set,
				&.error .load-data, &.error .match-cloud, &.error .building-model, &.error .scoring-training-set {
					background-image: none;
					padding-left: 0;
				}
			}
		}
		@include media(">1040px") {
			li {
				width: 22.5%;
				margin-right: 1.98%;
				&:last-child {
					margin-right: 0;
				}
				.load-data, .match-cloud, .building-model, .scoring-training-set, 
				&.success .load-data, &.success .match-cloud, &.success .building-model, &.success .scoring-training-set,
				&.error .load-data, &.error .match-cloud, &.error .building-model, &.error .scoring-training-set {
					padding-left: 24px;
				}
				.load-data {
				    background-image: url("/assets/images/ico-status-process-file-inactive.png");
				}
				.match-cloud {
				    background-image: url("/assets/images/ico-status-data-cloud-inactive.png");
				}
				.building-model {
				    background-image: url("/assets/images/ico-status-building-model-inactive.png");
				}
				.scoring-training-set {
				    background-image: url("/assets/images/ico-status-scoring-inactive.png");
				}
				&.success {
					.load-data {
				        background-image: url("/assets/images/ico-status-process-file-success.png");
				    }
				    .match-cloud {
				        background-image: url("/assets/images/ico-status-data-cloud-success.png");
				    }
				    .building-model {
				        background-image: url("/assets/images/ico-status-building-model-success.png");
				    }
					.scoring-training-set {
					    background-image: url("/assets/images/ico-status-scoring-success.png");
					}
				}
				&.error {
					.load-data {
				        background-image: url("/assets/images/ico-status-process-file-failed.png");
				    }
				    .match-cloud {
				        background-image: url("/assets/images/ico-status-data-cloud-failed.png");
				    }
				    .building-model {
				        background-image: url("/assets/images/ico-status-building-model-failed.png");
				    }
					.scoring-training-set {
					    background-image: url("/assets/images/ico-status-scoring-failed.png");
					}
				}
			}
		}
	}
}

