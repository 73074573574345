.title {
	font-size: 24px;
}

.paragraph {
	margin: 16px 0;
	font-size: 14px;
	line-height: 150%;
}

.button {
	background: #457FBA;
	color: white;
	padding: 8px 20px;
	border-radius: 3px;
	border: none;
	outline: none;
}
