/*
Table of Contents
1. Font Aweosome Overrides
2. Custom Icons (SVG with PNG Modernizr fallbacks)
*/


/*
1. Font Awesome Overrides
======================================================== */
.fa {
	&.fa-sort {
	    font-size: 10px;
	}
}



/*
2. Custom (SVG with PNG Modernizr fallbacks)
======================================================== */
.ico {
	display: inline-block;
	width: 12px;
	height: 12px;
	vertical-align: middle;
	background-repeat: no-repeat;
	background-position: center;
	&.ico-small {
		width: 12px;
	}
	&.ico-search {
		background-color: $jet;
		-webkit-mask: url("/assets/images/ico-search.svg");
		mask: url("/assets/images/ico-search.svg");
	}
	&.ico-filter {
		background-color: $jet;
		-webkit-mask: url("/assets/images/ico-filter.svg");
		mask: url("/assets/images/ico-filter.svg");
	}
	&.ico-sort {
		background-color: $jet;
		-webkit-mask: url("/assets/images/ico-sort.svg");
		mask: url("/assets/images/ico-sort.svg");
	}
	&.ico-grid {
		background-color: $jet;
		-webkit-mask: url("/assets/images/ico-grid.svg");
		mask: url("/assets/images/ico-grid.svg");
	}
	&.ico-list {
		background-color: $jet;
		-webkit-mask: url("/assets/images/ico-list.svg");
		mask: url("/assets/images/ico-list.svg");
	}
	&.ico-fingerprint {
		background-color: $pearl-white;
		-webkit-mask: url("/assets/images/ico-fingerprint.svg");
		mask: url("/assets/images/ico-fingerprint.svg");
		height: 28px !important;
    	width: 20px !important;
	}
	&.ico-bullseye {
		background-color: $pearl-white;
		-webkit-mask: url("/assets/images/ico-bullseye.svg");
		mask: url("/assets/images/ico-bullseye.svg");
	}
	&.ico-back {
		background-color: transparent;
		&:after {
			font-size: 21px;
			content: '\f053';
			display: block;
			font-family: FontAwesome;
			font-style: normal;
			font-weight: normal;
			text-decoration: inherit;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateY(-50%) translateX(-50%);
			margin-left: -2px;
		}
	}
	&.ico-model {
		background-color: $pearl-white;
		-webkit-mask-image: url("/assets/images/ico-model.svg");
		mask: url("/assets/images/ico-model.svg");
	}
	&.ico-check {
		background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20px' height='15px' viewBox='0 0 20 15' version='1.1'><g xmlns='http://www.w3.org/2000/svg' fill='%2300B04A' fill-rule='nonzero'><path d='M17.8839585,0.353500929 C17.643229,0.117781929 17.3505543,0 17.0065649,0 C16.6624157,0 16.3697409,0.117781929 16.1290114,0.353500929 L7.66453082,8.64907179 L3.87098841,4.9242165 C3.63010125,4.68849771 3.33758197,4.57071579 2.9935926,4.57071579 C2.64944557,4.57071579 2.35692848,4.68849771 2.11604132,4.9242165 L0.3612519,6.64135393 C0.120364744,6.87707271 0,7.16331407 0,7.50007757 C0,7.83668593 0.120364744,8.12308221 0.3612519,8.35864607 L5.03219011,12.9293619 L6.7871372,14.6464991 C7.02786669,14.8822181 7.32038378,15 7.66453082,15 C8.00852018,15 8.30103728,14.8820631 8.54192443,14.6464991 L10.2968737,12.9293619 L19.6387479,3.7879305 C19.8794774,3.5522115 19.9999998,3.26597036 19.9999998,2.92920686 C20.0001575,2.59259829 19.8794774,2.30635714 19.6387479,2.07063814 L17.8839585,0.353500929 Z' id='Shape'/></g></svg>");
		background-size: 14px;
		background-repeat: no-repeat;
		background-position: center center;
		width: 14px;
    	height: 14px;
	}
	&.ico-dashboard {
		background-color: transparent !important;
		background: url("data:image/svg+xml;utf8,<svg x='0px' y='0px' viewBox='0 0 40 39' version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><g fill='%23ffffff'><path d='M1.96989318,28.7153 L30.8647793,28.7153 L30.8647793,2.44745 L1.96989318,2.44745 L1.96989318,28.7153 Z M32.0142736,0 L0.820398903,0 C0.329095378,0 0,0.3255 0,0.8153 L0,30.1847 C0,30.67295 0.329095378,31 0.820398903,31 L32.1796011,31 C32.6709046,31 33,30.67295 33,30.1847 L33,0.8153 C32.8362322,0.3255 32.5071368,0 32.0142736,0 Z'></path><polygon points='6 25 12 25 12 19 6 19'></polygon><polygon points='13.5 25 19.5 25 19.5 19 13.5 19'></polygon><polygon points='21 25 27 25 27 19 21 19'></polygon><polygon points='6 8 8 8 8 6 6 6'></polygon><polygon points='10 8 27 8 27 6 10 6'></polygon><polygon points='6 12 27 12 27 10 6 10'></polygon><polygon points='6 16 22 16 22 14 6 14'></polygon></g></g></svg>") no-repeat 3px 4px;
		background-size: 22px !important;
	}
	&.ico-ratings {
		background-color: transparent !important;
		background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 23 25' xmlns='http://www.w3.org/2000/svg'><g data-name='Layer 2'><g id='Content'><path fill='%23fff' d='M13,11.05A4.95,4.95,0,1,0,18,16,5,5,0,0,0,13,11.05Zm2.13,8.22L13,17.73l-2.13,1.54.82-2.5L9.56,15.23h2.63l.81-2.5.81,2.5h2.63l-2.12,1.54Z'/><polygon fill='%23fff' points='9 0 9 4 13 4 9 0'/><path fill='%23fff' d='M13,9V5H8V0H0V16H6a7,7,0,0,1,7-7Z'/></g></g></svg>") no-repeat 4px 3px;
		&.ico-black {
			background-color: transparent;
			background-image: url("/assets/images/ico-ratings-header.png");
		}
	}
	&.ico-campaign {
		background-color: transparent !important;
		background-image: url("/assets/images/ico-campaign-white.png");
		background-size: 16px;
		&.ico-black {
			background-color: transparent;
			background-image: url("/assets/images/ico-campaign-dark.png");
		}
	}
	&.ico-segments {
		background-color: transparent !important;
		background-image: url("/assets/images/ico-segments-new.svg");
		background-size: 18px;
	}
	&.ico-connections {
		background-color: transparent !important;
		background-image: url("/assets/images/ico-connections-new.svg");
		background-size: 24px;
		top: 48% !important;
	}
	&.ico-report {
		background-color: transparent !important;
		background-image: url("/assets/images/ico-report.svg");
		background-size: 18px;
	}
	&.ico-ssvi {
	  background-color: transparent !important;
	  background-image: url("/assets/images/ico-ssvi-white.svg");
	  background-size: 22px;
	}
	&.ico-dnb-logo {
		background-color: transparent !important;
		background-image: url("/assets/images/DBlogomain.svg");
		background-size: 187px 42px;
	}
	&.ico-analysis {
		background-color: transparent !important;
		background-image: url("/assets/images/ico-analysis-new.svg");
		background-size: 18px;
	}
	&.ico-notes {
		background-color: transparent !important;
		background-image: url("/assets/images/ico-notes-new.svg");
		background-size: 18px;
	}
	&.ico-playbook {
		background-color: transparent !important;
		background-image: url("/assets/images/ico-playbook.png");
		background-size: 18px;
	}
	&.ico-dnb-revup {
		background-color: transparent !important;
		background-image: url("/assets/images/DNBHeaderLogo.svg");
	}
	&.ico-contact-us {
		background-color: transparent !important;
        position: relative;
        &:before {
            @include font-awesome-inline("\f003");
            font-size: 20px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);

        }
	}
	&.ico-marketo-apikey {
		-webkit-mask-image: url("/assets/images/ico-marketo-apikey.svg");
		mask: url("/assets/images/ico-marketo-apikey.svg");
	}
	&.ico-marketo-models {
		-webkit-mask-image: url("/assets/images/ico-marketo-models.svg");
		mask: url("/assets/images/ico-marketo-models.svg");
	}
	&.ico-cog {
		background-color: $pearl-white;
		-webkit-mask-image: url("/assets/images/ico-cog.svg");
		mask: url("/assets/images/ico-cog.svg");
	}
	&.ico-jobs {
		background-image: url("/assets/images/ico-jobs.png");
		background-size: 20px;
		&.ico-black {
			background-image: url("/assets/images/ico-jobs-black.png");
            background-color: transparent;
		}
	}
	&.ico-step-open {
		background-image: url("/assets/images/ico-step-open.svg");
	}
	&.ico-step-current {
		background-image: url("/assets/images/ico-step-current.svg");
	}
	&.ico-step-checked {
		background-image: url("/assets/images/ico-step-checked.svg");
	}
	&.ico-user {
		background-color: $pearl-white;
		-webkit-mask-image: url("../images/ico-user.svg");
		mask: url("../images/ico-user.svg");
	}
	&.ico-user-card {
		background-color: $pearl-white;
		-webkit-mask-image: url("../images/ico-user-card.svg");
		mask: url("../images/ico-user-card.svg");
	}
	&.ico-industry {
		background-color: $pearl-white;
		-webkit-mask: url("/assets/images/ico-industry.svg");
		mask: url("/assets/images/ico-industry.svg");
	}
	&.ico-locations {
		background-color: $pearl-white;
		-webkit-mask: url("/assets/images/ico-locations.svg");
		mask: url("/assets/images/ico-locations.svg");
	}
	&.ico-tmb-user {
		background-color: $pearl-white;
		-webkit-mask: url("/assets/images/ico-employee-range.svg");
		mask: url("../images/ico-user.svg");
	}
	&.ico-tmb-chart {
		background-color: $pearl-white;
		-webkit-mask: url("/assets/images/ico-revenue-range.svg");
		mask: url("/assets/images/ico-chart.svg");
	}
	&.ico-tech-profile {
		background-color: $pearl-white;
		-webkit-mask: url("/assets/images/ico-web.svg");
		mask: url("/assets/images/ico-web.svg");
	}
	&.ico-web {
		background-color: $pearl-white;
		-webkit-mask: url("/assets/images/ico-web.svg");
		mask: url("/assets/images/ico-web.svg");
	}
	&.ico-chip {
		background-color: $pearl-white;
		-webkit-mask: url("/assets/images/ico-chip.svg");
		mask: url("/assets/images/ico-chip.svg");
	}
	&.ico-history {
		background-color: $pearl-white;
		-webkit-mask-image: url("/assets/images/ico-model-history-10.svg");
		mask: url("/assets/images/ico-model-history-10.svg");
	}
	&.ico-enrichment {
		background-color: $pearl-white;
		-webkit-mask-image: url("/assets/images/ico-enrichment.svg");
		mask: url("/assets/images/ico-enrichment.svg");
	}
	&.ico-datatable {
		background-color: $pearl-white;
		-webkit-mask-image: url("/assets/images/ico-datatable.svg");
		mask: url("/assets/images/ico-datatable.svg");
	}
	&.ico-performance {
		background-color: $pearl-white;
		mask: url("/assets/images/ico-performance.svg");
		-webkit-mask-image: url("/assets/images/ico-performance.svg");
		-webkit-mask-size: 18px 18px;
		-webkit-mask-position: center center;
	}
	&.ico-alerts {
		-webkit-mask-image: url("/assets/images/ico-alerts.svg");
		mask: url("/assets/images/ico-alerts.svg");
	}
	&.ico-warning {
		background-color: transparent !important;
		background: url("data:image/svg+xml;utf8,<svg width='16px' height='14px' viewBox='0 0 16 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='3rd-iteration' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><g id='create-play-copy-9' transform='translate(-1152.000000, -542.000000)' fill='%23F5A623'><path d='M1160.07496,554.185103 C1159.66016,554.185103 1159.32169,553.831981 1159.32169,553.424961 C1159.32169,553.004931 1159.66016,552.652738 1160.07496,552.652738 C1160.48976,552.652738 1160.83834,553.004931 1160.83834,553.424961 C1160.83834,553.831981 1160.48976,554.185103 1160.07496,554.185103 Z M1160.77304,546.723071 L1160.57622,551.825688 L1159.58382,551.825688 L1159.37688,546.723071 L1160.77304,546.723071 Z M1159.99954,542.5395 L1152.5,555.6645 L1167.5,555.6645 L1159.99954,542.5395 Z' id='Fill-16'></path></g></g></svg>") no-repeat 2px 3px;
	}
	&.ico-scoring {
		background-color: $pearl-white;
		-webkit-mask-image: url("/assets/images/ico-insights-new.svg");
		mask: url("/assets/images/ico-insights-new.svg");
		-webkit-mask-size: 20px 20px;
		-webkit-mask-position: center center;
	}
	&.ico-refine {
		background-color: $pearl-white;
		-webkit-mask-image: url("/assets/images/ico-launch-history.svg");
		mask: url("/assets/images/ico-launch-history.svg");
		-webkit-mask-size: 20px 20px;
		-webkit-mask-position: center center;
	}
	&.ico-remodel {
		background-color: $pearl-white;
		-webkit-mask-image: url("/assets/images/ico-remodel.svg");
		mask: url("/assets/images/ico-remodel.svg");
	}
	&.ico-remodel-large {
		-webkit-mask-image: url("/assets/images/ico-remodel-large.svg");
		mask: url("/assets/images/ico-remodel-large.svg");
	}
	&.ico-attributes {
		background-color: $pearl-white;
		-webkit-mask-image: url("/assets/images/ico-attribute-admin.svg");
		-webkit-mask-size: contain;
		mask: url("/assets/images/ico-attribute-admin.svg");
		mask-size: contain;
	}
	&.ico-view-model {
		background-color: $pearl-white;
		-webkit-mask-image: url("/assets/images/ico-view-model.svg");
		mask: url("/assets/images/ico-view-model.svg");
	}
	&.ico-donut {
		background-color: $pearl-white;
		-webkit-mask-image: url("/assets/images/ico-donut.svg");
		mask: url("/assets/images/ico-donut.svg");
	}
	&.ico-leads {
		background-color: $pearl-white;
		-webkit-mask-image: url("/assets/images/ico-leads.svg");
		mask: url("/assets/images/ico-leads.svg");
	}
	&.ico-marketo {
		background-color: $pearl-white;
		-webkit-mask-image: url("/assets/images/ico-marketo.svg");
		mask: url("/assets/images/ico-marketo.svg");
	}
	&.ico-eloqua {
		background-color: $pearl-white;
		-webkit-mask-image: url("/assets/images/ico-app-settings.svg");
		mask: url("/assets/images/ico-app-settings.svg");
	}
	&.ico-salesforce {
		background-color: $pearl-white;
		-webkit-mask-image: url("/assets/images/ico-salesforce.svg");
		mask: url("/assets/images/ico-salesforce.svg");
	}
	&.ico-connectors{
		background-color: $pearl-white;
		-webkit-mask-image: url("/assets/images/ico-connections-new.svg");
		mask: url("/assets/images/ico-connections-new.svg");
		-webkit-mask-size: 24px 24px;
		top: 48% !important;
	}
	&.ico-api-console {
		background-color: $pearl-white;
		-webkit-mask-image: url("/assets/images/ico-api-console.svg");
		mask: url("/assets/images/ico-api-console.svg");
	}
    &.ico-arrow-open {
		-webkit-mask-image: url("/assets/images/ico-arrow-open.svg");
		mask: url("/assets/images/ico-arrow-open.svg");
    }
    &.ico-attr-firmographics {
		-webkit-mask-image: url("/assets/images/enrichments/ico-attr-firmographics.svg");
		mask: url("/assets/images/enrichments/ico-attr-firmographics.svg");
    }
    &.ico-attr-geography {
		-webkit-mask-image: url("/assets/images/enrichments/ico-attr-geography.svg");
		mask: url("/assets/images/enrichments/ico-attr-geography.svg");
    }
    &.ico-attr-growth-trends {
		-webkit-mask-image: url("/assets/images/enrichments/ico-attr-growth-trends.svg");
		mask: url("/assets/images/enrichments/ico-attr-growth-trends.svg");
    }
    &.ico-attr-industry {
		-webkit-mask-image: url("/assets/images/enrichments/ico-attr-industry.svg");
		mask: url("/assets/images/enrichments/ico-attr-industry.svg");
    }
    &.ico-attr-intent {
		-webkit-mask-image: url("/assets/images/enrichments/ico-attr-intent.svg");
		mask: url("/assets/images/enrichments/ico-attr-intent.svg");
    }
    &.ico-attr-lead-information {
		-webkit-mask-image: url("/assets/images/enrichments/ico-attr-lead-information.svg");
		mask: url("/assets/images/enrichments/ico-attr-lead-information.svg");
    }
    &.ico-attr-online-presence {
		-webkit-mask-image: url("/assets/images/enrichments/ico-attr-online-presence.svg");
		mask: url("/assets/images/enrichments/ico-attr-online-presence.svg");
    }
    &.ico-attr-technology-profile {
		-webkit-mask-image: url("/assets/images/enrichments/ico-attr-technology-profile.svg");
		mask: url("/assets/images/enrichments/ico-attr-technology-profile.svg");
    }
    &.ico-attr-website-keywords {
		-webkit-mask-image: url("/assets/images/enrichments/ico-attr-website-keywords.svg");
		mask: url("/assets/images/enrichments/ico-attr-website-keywords.svg");
    }
    &.ico-attr-website-profile {
		-webkit-mask-image: url("/assets/images/enrichments/ico-attr-website-profile.svg");
		mask: url("/assets/images/enrichments/ico-attr-website-profile.svg");
    }
    &.ico-insights {
		background-color: $pearl-white;
		-webkit-mask-image: url("/assets/images/ico-insights.svg");
		mask: url("/assets/images/ico-insights.svg");
    }
    &.ico-launch {
		background-color: $pearl-white;
		-webkit-mask-image: url("/assets/images/ico-launch.svg");
		mask: url("/assets/images/ico-launch.svg");
    }
    &.ico-preview {
		background-color: $pearl-white;
		-webkit-mask-image: url("/assets/images/ico-preview.svg");
		mask: url("/assets/images/ico-preview.svg");
    }
    &.ico-playbook-ratings {
		background-color: $pearl-white;
		-webkit-mask-image: url("/assets/images/ico-ratings.svg");
		mask: url("/assets/images/ico-ratings.svg");
    }
    &.ico-playbook-segments {
		background-color: $pearl-white;
		-webkit-mask-image: url("/assets/images/ico-segments.svg");
		mask: url("/assets/images/ico-segments.svg");
    }
    &.ico-settings {
		background-color: $pearl-white;
		-webkit-mask-image: url("/assets/images/ico-settings.svg");
		mask: url("/assets/images/ico-settings.svg");
    }
    &.ico-targets {
		background-color: $pearl-white;
		-webkit-mask-image: url("/assets/images/ico-targets.svg");
		mask: url("/assets/images/ico-targets.svg");
	}
	&.ico-dnb-icon-color {
		height: 16px;
		width: 16px;
		background: url(/assets/images/dnb-favicon.svg) no-repeat center center;
	}
	&.ico-lattice-dots-color {
		height: 15px;
    	width: 14px;
		background: url(/assets/images/enrichments/subcategories/latticeratings.png) no-repeat center center;
	}
    &.ico-lattice-dots {
    	height: 15px;
    	width: 14px;
    	opacity: .5;
    	background: url(/assets/images/ico-lattice-dots.png) no-repeat center center;
    }
	&.ico-lattice-dots-solid {
	  height: 15px;
	  width: 14px;
	  background: url(/assets/images/ico-lattice-dots.png) no-repeat center center;
	}
	&.ico-light-grey,
	&.ico-light-gray {
		background-color: #b3b5b8;
	}
	&.ico-black {
		background-color: #222;
	}
	&.ico-white {
		background-color: $pearl-white;
	}
	&.ico-jet {
		background-color: $jet;
	}
	&.ico-saphire {
		background-color: $saphire;
	}
	&.ico-ruby {
		background-color: $ruby;
	}
	&.ico-amber {
		background-color: $amber;
	}
	&.ico-gold {
		background-color: $gold;
	}
	&.ico-jade {
		background-color: $jade;
	}
	&.ico-amethyst {
		background-color: $amethyst;
	}
	&.ico-action-refresh {
		background: url(/assets/images/job/action/refresh.svg) no-repeat center center;
	}
	&.ico-action-attributesactivated {
		background: url(/assets/images/job/action/attributes_activated.svg) no-repeat center center;
	}
	&.ico-action-attributesdeactivated {
		background: url(/assets/images/job/action/attributes_deactivated.svg) no-repeat center center;
	}
	&.ico-action-businesscalendarchanged {
		background: url(/assets/images/job/action/business_calendar_changed.svg) no-repeat center center;
	}
	&.ico-action-curatedattributesupdated {
		background: url(/assets/images/job/action/curated_attributes_updated.svg) no-repeat center center;
	}
	&.ico-action-segmentedited {
		background: url(/assets/images/job/action/segment_edited.svg) no-repeat center center;
	}
	&.ico-action-scoring {
		background: url(/assets/images/job/action/scoring.svg) no-repeat center center;
	}
	&.ico-action-delete {
		background: url(/assets/images/job/action/delete.svg) no-repeat center center;
	}
	&.ico-action-import {
		background: url(/assets/images/job/action/import.svg) no-repeat center center;
	}
	&.ico-entity-accounts {
		background: url(/assets/images/job/entity/accounts.svg) no-repeat center center;
	}
	&.ico-entity-contactleads {
		background: url(/assets/images/job/entity/contact_leads.svg) no-repeat center center;
	}
	&.ico-entity-dnbintentdata {
		background: url(/assets/images/job/entity/dnb_intent_data.svg) no-repeat center center;
	}
	&.ico-entity-event {
		background: url(/assets/images/job/entity/event.svg) no-repeat center center;
	}
	&.ico-entity-generaldatatype {
		background: url(/assets/images/job/entity/general_data_type.svg) no-repeat center center;
	}
	&.ico-entity-salesactivity {
		background: url(/assets/images/job/entity/sales_activity.svg) no-repeat center center;
	}
	&.ico-entity-g2intent {
		background: url(/assets/images/job/entity/g2_intent.svg) no-repeat center center;
		background-size: contain;
	}
	&.ico-entity-trustradiusintent {
		background: url(/assets/images/job/entity/trust_radius_intent.svg) no-repeat center center;
		background-size: contain;
	}
}
.no-cssmask {
	.ico, .menu .active .ico {
		background-color: transparent;
	}
	.ico {
		-webkit-mask-image:  none;
		mask: none; // added to make debuging easier, remove if you see issues with svg masks moving forward. - ben2
		&.ico-search {
			background-image: url("/assets/images/ico-search.png");
		}
		&.ico-filter {
			background-image: url("/assets/images/ico-filter.png");
		}
		&.ico-sort {
			background-image: url("/assets/images/ico-sort.png");
		}
		&.ico-grid {
			background-image: url("/assets/images/ico-grid.png");
		}
		&.ico-list {
			background-image: url("/assets/images/ico-list.png");
		}
		&.ico-fingerprint {
			background-image: url("/assets/images/ico-fingerprint.png");
		}
		&.ico-bullseye {
			background-image: url("/assets/images/ico-bullseye.png");
		}
		&.ico-dashboard {
			background-image: url("/assets/images/ico-dashboard.png");
		}
		&.ico-model {
			background-image: url("/assets/images/ico-model.png");
			&.ico-black {
				background-image: url("/assets/images/ico-model-black.png");
			}
		}
		&.ico-campaign {
			background-image: url("/assets/images/ico-campaign-white.png");
			&.ico-black {
				background-image: url("/assets/images/ico-campaign-black.png");
			}
		}
		&.ico-cog {
			background-image: url("/assets/images/ico-cog.png");
			background-size: 20px;
			&.ico-black {
				background-image: url("/assets/images/ico-cog-black.png");
                background-color: transparent;
			}
		}
		&.ico-step-open {
			background-image: url("/assets/images/ico-step-open.png");
		}
		&.ico-step-current {
			background-image: url("/assets/images/ico-step-current.png");
		}
		&.ico-step-checked {
			background-image: url("/assets/images/ico-step-checked.png");
		}
		&.ico-user {
			background-image: url("/assets/images/ico-user.png");
			&.ico-black {
				background-image: url("/assets/images/ico-user-black.png");
			}
		}
		&.ico-user-card {
			background-image: url("/assets/images/ico-user-card.png");
		}
		&.ico-industry {
			background-image: url("/assets/images/ico-industry.png");
		}
		&.ico-locations {
			background-image: url("/assets/images/ico-locations.png");
		}
		&.ico-alerts {
			background-image: url("/assets/images/ico-alerts.png");
		}
		&.ico-scoring {
			background-image: url("/assets/images/ico-scoring.png");
		}
		&.ico-refine {
			background-image: url("/assets/images/ico-refine.png");
		}
		&.ico-remodel, &.ico-remodel-large {
			background-image: url("/assets/images/ico-remodel.png");
			&.ico-black {
				background-image: url("/assets/images/ico-remodel-black.png");
			}
		}
		&.ico-marketo-apikey {
			background-image: url("/assets/images/ico-marketo-apikey.png");
			background-size: 20px;
		}
		&.ico-marketo-models {
			background-image: url("/assets/images/ico-marketo-models.png");
			background-size: 20px;
		}
		&.ico-performance {
			background-image: url("/assets/images/ico-performance.png");
		}
		&.ico-tmb-user {
			background-image: url("/assets/images/ico-employee-range.png");
		}
		&.ico-tmb-chart {
			background-image: url("/assets/images/ico-revenue-range.png");
		}
		&.ico-tech-profile {
			background-image: url("/assets/images/ico-web.png");
		}
		&.ico-web {
			background-image: url("/assets/images/ico-web.png");
		}
		&.ico-chip {
			background-image: url("/assets/images/ico-chip.png");
		}
		&.ico-history {
			background-image: url("/assets/images/ico-model-history-10.png");
			background-size: 16px;
		}
		&.ico-enrichment {
			background-image: url("/assets/images/ico-enrichment.png");
			&.ico-black {
				background-image: url("/assets/images/ico-enrichment-black.png");
			}
		}
		&.ico-attributes {
			background-image: url("/assets/images/ico-attributes.png");
			background-size: 20px;
		}
		&.ico-leads {
			background-image: url("/assets/images/ico-leads.png");
			background-size: 20px;
		}
		&.ico-datatable {
			background-image: url("/assets/images/ico-datatable.png");
			background-size: 18px;
		}
		&.ico-marketo {
			background-image: url("/assets/images/ico-marketo.png");
			background-size: 16px;
			&.ico-black {
				background-image: url("/assets/images/ico-marketo-black.png");
			}
		}
		&.ico-eloqua {
			background-image: url("/assets/images/ico-app-settings.png");
			background-size: 16px;
			&.ico-black {
				background-image: url("/assets/images/ico-app-settings-black.png");
			}
		}
		&.ico-salesforce {
			background-image: url("/assets/images/ico-salesforce.png");
			background-size: 16px;
			&.ico-black {
				background-image: url("/assets/images/ico-salesforce-black.png");
			}
		}
		&.ico-api-console {
			background-image: url("/assets/images/ico-api-console.png");
			background-size: 16px;
			&.ico-black {
				background-image: url("/assets/images/ico-api-console-black.png");
			}
		}
		&.ico-arrow-open {
			background-image: url("/assets/images/ico-arrow-open.png");
			background-size: 16px;
		}
		&.ico-attr-firmographics {
			background-image: url("/assets/images/enrichments/ico-attr-firmographics.png");
		}
		&.ico-attr-geography {
			background-image: url("/assets/images/enrichments/ico-attr-geography.png");
		}
		&.ico-attr-growth-trends {
			background-image: url("/assets/images/enrichments/ico-attr-growth-trends.png");
		}
		&.ico-attr-industry {
			background-image: url("/assets/images/enrichments/ico-attr-industry.png");
		}
		&.ico-attr-intent {
			background-image: url("/assets/images/enrichments/ico-attr-intent.png");
		}
		&.ico-attr-lead-information {
			background-image: url("/assets/images/enrichments/ico-attr-lead-information.png");
		}
		&.ico-attr-online-presence {
			background-image: url("/assets/images/enrichments/ico-attr-online-presence.png");
		}
        &.ico-attr-technology-profile {
			background-image: url("/assets/images/enrichments/ico-attr-technology-profile.png");
		}
		&.ico-attr-website-keywords {
			background-image: url("/assets/images/enrichments/ico-attr-website-keywords.png");
		}
		&.ico-attr-website-profile {
			background-image: url("/assets/images/enrichments/ico-attr-website-profile.png");
		}
		&.ico-light-grey-from-black,
		&.ico-light-gray-from-black {
			opacity: .4;
		}
	}
}
