.range-container {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 10px;
	.error-message {
		padding-top: 0.3em;
		padding-left: 0.8em;
	}
}

.date-picker-container {
	flex: auto;
	width: 150px;
	min-width: 150px;
	padding: 0;
	.date-trigger {
		cursor: pointer !important;
	}
	input[type='date'] {
		&::-webkit-datetime-edit {
			display: none;
		}
	}
	input[type='text']:readonly {
		outline: none;
		border: 1px solid #ccc;
		max-width: 120px;
		border-color: rgba(204, 204, 204, 0.747);
		height: 1.65em;
		font-size: 12px;
	}

	input[type='text'].invalid-field {
		border-left: 3px solid #b50a20;
		padding-left: 4px;
	}
	&.show-inline {
		display: inline;
	}
}
