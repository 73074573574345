div.pd-pagination {
	margin-top: 0.25em;
}

select#tenantSelectionInput {
	border: 1px solid #ccc;
	height: 24em;
	margin-top: 0.5em;

	option {
		padding: 0.5em;
		cursor: pointer;

		&:nth-child(odd) {
			background-color: #f5f5f5;
		}

		&.active,
		&:hover {
			background: #26639b;
			color: #fff;
		}
	}
}

div#tenantSelectionFormContainer {
	width: 30em;
}

input.tenant-selection-search {
	width: 9em;
	display: inline;
	line-height: 1em;
	border-radius: 3px;
	border-color: #d0d0d0;

	&:focus {
		box-shadow: none;
		-webkit-box-shadow: none;
		border-color: #ccc;
	}
}

div.panel-body .columns {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

#tenantSelectionInput {
	margin: 0;
}

.panel {
	.panel-heading.custom-heading {
		padding: 4px 0;

		.columns {
			text-align: center;

			&:hover {
				text-decoration: underline;
				cursor: pointer;
			}
		}
	}

	.panel-body {
		overflow: auto;
		height: 25em;
		background: white;

		.tenant-date,
		.tenant-version {
			text-align: center;
		}

		.job-td-row {
			min-height: 2.2em;
			padding: 0.333em 0;
		}
	}
}

.tenant-select-list {
	margin: 0.5em 0 1em;

	&.deactivated {
	}
}

.tenant-header {
	font-size: 14pt;
	text-align: center;
	margin-bottom: 1em;
	padding-bottom: 0.5em;
	border-bottom: 1px solid #d0d0d0;
}

.tenant-total {
	float: right;
}

.tenant-list-item {
	&,
	& * {
		transition: none;
	}

	&.active,
	&:hover {
		background: #65b7e5;
		cursor: pointer;
		transition: none;
		color: #fff;

		.columns {
		}
	}

	&:active {
		background: #3989c4;
		cursor: default;
	}

	i {
		position: absolute;
	}

	span.badge {
		margin-top: -3px;
	}
}

.tenant-list-spinner {
	padding-top: 8em;
	color: #888;

	&,
	& * {
		position: relative;
	}

	i.fa-spinner {
		display: inline-block;
		margin: 0;
	}

	span {
		display: block;
		text-align: center;
		margin-top: 1em;
	}
}

.button.link-button.close-button {
	color: #fff;
	margin-top: -0.7em;
	margin-right: -0.3em;
}

.ng-search {
	button.button {
		float: right;
	}

	&.open-absolute .form-control,
	.search-form-control {
		width: 116px;
		left: inherit;
		right: 36px;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		padding-left: 0.75em;
		font-size: 12px;
    height: 30px;
    color: #555555;
		border: none;
    border-radius: 3px;
    outline-style: none;
    box-shadow: none;
	}
}

.button-group > .button:first-child:not(:last-child):not(.dropdown-toggle) {
	border-right: none;
}

.button-group > .button:last-child:not(:first-child),
.button-group > .dropdown-toggle:not(:first-child) {
	border-left: none;
}
