.filter-list.le-button-toggles {
	&,
	& ul {
		display: block;
		width: 100%;
	}

	.filter-list-label {
		display: inline-block;
		font-weight: bold;
		margin-bottom: 0.5em;
	}

	.filter-list-container {
		max-height: 15em;
		overflow: auto;
		width: 100%;
		transition: none;
		display: flex;
		flex-wrap: wrap;

		li {
			flex: 0 1 auto;
			margin-right: 10px;
			transition: all 0 linear 0;

			&:hover {
				background-color: transparent;
			}

			label {
				position: relative;
				display: flex;
				align-items: center;
				height: 46px;
				width: 46px;

				input {
					display: none;
					position: relative;
				}

				span:not(:last-child) {
					overflow: hidden;
					text-overflow: ellipsis;
					width: 100%;
					white-space: nowrap;
					display: inline-block;
					padding-left: 0.5em;
					padding-right: 2em;
				}

				span:last-child {
					position: relative;
					right: 0.5em;
				}

				div.item {
					border: 1px solid #ccc;
					border-radius: 50%;
					background: #f0f7fc;
					height: 100%;
					width: 100%;
					padding: 6px;
					filter: grayscale(100%);

					i {
						display: block;
						height: 100%;
						width: 100%;
					}

					div.image {
						position: absolute;
						top: 50%;
						left: 5px;
						right: 5px;
						transform: translateY(-50%);

						img {
							max-width: 100%;
							max-height: 100%;
							vertical-align: middle;
						}
					}
				}

				div.item:hover,
				input:checked + div.item {
					background: transparent;
					border-color: #6a9ace;
					filter: grayscale(0);
				}
			}
		}
	}
}
