.itemlist-filters-panel {
	position: relative;
	width: 0;
	height: 100%;
	z-index: 1;
	border: none;
	transition: all 333ms ease;
	float: left;
	opacity: 0;
	pointer-events: none;

	> ul {
		position: absolute;
		width: 280px;
		height: auto;
		transition: all 333ms ease;
		transform: translateX(-100%);
	}

	&.open {
		width: 280px;
		margin-right: 1.5em;
		opacity: 1;
		pointer-events: initial;

		> ul {
			transform: translateX(0%);
		}
	}

	ul {
		position: relative;

		li.panel-controls {
			padding: 2em;
			padding-top: 1em;
			padding-right: 0.5em;
			padding-left: 1em;
			border-bottom: 1px solid #ccc;
			height: 2.2em;
			display: flex;
			align-items: center;

			> span:first-child {
				display: flex;
				font-size: 9pt;
				align-items: center;

				> i {
					display: inline-block;
					margin-right: 0.5em;
					font-size: 14pt;
				}
			}

			> span:last-child {
				position: absolute;
				right: 0.5em;
				top: 0;
			}

			button {
				margin-left: 0.5em;

				&.clear {
					background: transparent;
				}

				&.apply {
					background: transparent;
				}

				&.close {
					background: transparent;
				}
			}

			&.controls-tabs {
				ul {
					width: 100%;
					display: flex;
					padding: 0 10px;
					flex-direction: row;
					justify-content: space-between;

					li {
						cursor: pointer;

						&:hover,
						&.active {
							color: #f16a22;
						}
					}
				}
			}
		}

		li.panel-filters {
			display: initial;
		}
	}
}

.le-itembar {
	margin: 0 0 24px;

	> li,
	> li > div {
		display: inline-block;
		vertical-align: middle;
		font-size: 10pt;
	}

	> li {
		margin-right: 0.75em;
	}

	> li.pull-right {
		margin-right: 0;

		// Using the subsequent sibling combinator (~) to select all but first li.pull-right
		& ~ li.pull-right {
			margin-right: 0.75em;
		}
	}
}

.le-itembar.vertical {
	> li {
		position: relative;
		margin: 0;
		padding: 1.25em 1em 1.5em;
		border-bottom: 1px solid #ccc;
		display: flex;
		overflow: hidden;
		max-height: 25em;
		transition: all 500ms ease;

		&:last-child {
			margin-bottom: 4em;
			border-bottom: none;
		}

		> button.filter-windowshade-toggle:first-child {
			position: absolute;
			right: 0.5em;
			top: 0.5em;

			i {
				transition: transform 333ms ease;
				transform: rotateX(0deg);
			}
		}

		&.closed {
			max-height: 3.4em;
			transition: all 333ms ease;
			padding-top: 0.9em;

			> button.filter-windowshade-toggle:first-child {
				transform: rotateX(180deg);
			}

			> div > span:first-child {
				margin-bottom: 1em;
			}

			> div > div.filter-list-toggles {
				opacity: 0;
				pointer-events: none;
			}
		}
	}

	&.le-itembar-tabbed {
		> li {
			&.closed {
				display: none;
			}
		}
	}
}
