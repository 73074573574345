@import '../../../../common/node_modules/@dnb-uux-design-system/design-tokens/build/styles/_variables.scss';

.sortButton {
	appearance: none;
	border: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0;
	background-color: transparent;
}

.sortButton:hover,
.sortButton:focus {
	background-color: #6666;
}

.sortButtonContent {
	overflow: hidden;
	text-overflow: ellipsis;
}

.notSortingBy {
	color: #aaa;
}

.sortIcon {
	font-size: $font-size-caption !important;
}

