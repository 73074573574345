div.explorer-subheader-tabs {
	position: relative;
	line-height: 3em;
	margin-top: 1em;

	ul.subheader-tabs-links {
		display: inline-block;
		li {
			display: inline-block;
			font-size: 18px;
			color: #222;
			padding: 0 0.4em 0 0;
			-webkit-transition: none;
			-moz-transition: none;
			-ms-transition: none;
			transition: none;
			text-transform: capitalize;

			&.clickable,
			&[ng-click] {
				cursor: pointer;
				&:hover {
					color: #00ABDA;
				}
			}
			&[disabled] {
				pointer-events: none;
				opacity: 0.2;
			}

			&:active,
			&.active {
				color: #0078A7;
				cursor: default;
			}
		}
	}

	ul.subheader-tabs-buttons {
		// margin-right: 14px;
		float: right;

		& > li {
			position: relative;
		}

		.confirmation-title {
			position: absolute;
			top: 2px;
			left: 0;
			margin-left: -50%;
			font-size: 0.9em;
			color: #222;

			.confermation-ok {
				padding: 0.4em;
				color: green;
			}
		}

		.export-button {
			padding-right: 0px;
		}

		.export-segment-button {
			position: relative;
		}
	}
}

.split-button {
	button {
		&:first-of-type {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			&.disabled {
				border-right: none;
				i {
					font-size: 12px;
				}
			}
		}
		&:last-of-type {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			border-left: 1px solid rgba(255, 255, 255, 0.5);
		}
	}
}

div.subheader-success-message {
	margin: 20px 10px;
}

.dl-attributes {
	padding-left: 10px;
}

.view-query {
	color: #447EBB;
	.fa {
		margin-right: 4px;
	}
}

div.subheader-container {
	padding-top: 6px;
	& ul.le-itembar {
		margin-bottom: 6px !important;
	}
}

.container-myData{
	max-width: 1200px;
    margin: 0 auto;
}
