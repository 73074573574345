@import 'common/node_modules/@dnb-uux-design-system/design-tokens/build/styles/_variables';

$headAndFootHeight: 63px;

.mask {
	width: 0;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	position: fixed;
	top: 0;
	right: 0;
	// should not mask the LeModal which is set to 1000
	// ReactTooltip's z-index default to 999, we canbe less than that
	z-index: $z-depth-dropdown;
	opacity: 0;
	transition: opacity 0.3s ease;

	& > .drawer {
		position: absolute;
		right: 0;
		height: 100%;
		width: 475px;
		background-color: #ffffff;
		// display: flex;
		// flex-direction: column;
		transition: all 0.3s ease;
		& > .drawer-header {
			width: 100%;
			height: $headAndFootHeight;
			position: absolute;
			top: 0;
			padding: 24px;
			display: flex;
			justify-content: space-between;
		}
		& > .drawer-footer {
			width: 100%;
			height: $headAndFootHeight;
			position: absolute;
			bottom: 0;
			// No exact shadow variable in `_variables`
			box-shadow: 0px -1px 4px $color-gray-primary-alpha-2;
			display: flex;
			padding: 24px;
			align-items: center;
			gap: 0 1em;
		}
		& > .drawer-body {
			height: calc(100% - #{$headAndFootHeight * 2});
			overflow: auto;
			padding: 24px;
			margin-top: $headAndFootHeight;
		}
		& > .drawer-footer-hide {
			display: none;
		}
	}
}

.show {
	width: 100vw;
	opacity: 1;
}

.hide {
	& .drawer {
		transform: translateX(100%);
	}
}

.drawer-body::-webkit-scrollbar {
	width: 11px;
}

.drawer-body::-webkit-scrollbar-thumb {
	background-color: #e3e3e3;
	box-shadow: none;
}

.drawer-body::-webkit-scrollbar-track {
	display: none;
}
