.loading {
	padding-top: 117px;
	transition-duration: 0ms;
}

.marketplaceSegments {
	display: flex;
	height: calc(100vh - 120px);
	margin-bottom: -50px;

	.mpsFilters {
		.mpsFiltersBox {
			width: 263px;
			position: relative;
			left: -263px;
		}

		height: calc(100vh - 70px);
		overflow: scroll;
		border-right: 1px solid #f1f1f1;
		width: 0;
		opacity: 0;

		&.show {
			width: 263px;
			opacity: 1;

			.mpsFiltersBox {
				left: 0;
			}
		}
	}

	.mpsMain {
		flex: 1;
		display: flex;
		flex-direction: column;
		height: calc(100vh - 70px);

		.mpsSearchContainer {
			background-color: white;
			padding: 24px;
			display: flex;

			.mpsToggleFilters {
				width: 30px;
				height: 30px;
				border-radius: 3px;
				background-color: white;
				border: 1px solid #d1d1d1;
				line-height: 30px;

				&:hover {
					background-color: darken(white, 10%);
				}

				&.active {
					background-color: #457eba;
					border-color: #457eba;

					&:hover {
						background-color: lighten(#457eba, 10%);
					}

					path {
						fill: white;
					}
				}
			}

			.mpsSortSelect {
				border: 1px solid #d1d1d1;
				border-radius: 3px;
				margin-left: 8px;
				height: 30px;
				position: relative;
				cursor: pointer;

				.mpsSortDropdown {
					width: 200px;
					display: inline-block;
					padding: 0 8px;
					line-height: 30px;

					.mpsSortDropdownOptions {
						background-color: white;
						border: 1px solid #d1d1d1;
						border-radius: 3px;
						position: relative;
						left: -9px;
						top: -2px;
						z-index: 100;
						box-shadow: 2px 2px 5px #d1d1d1;

						&.hide {
							display: none;
						}

						div {
							padding: 0 16px;
							white-space: nowrap;

							&:hover {
								background-color: darken(white, 10%);
							}
						}
					}
				}

				button {
					background-color: white;
					border: none;
					border-radius: 0 3px 3px 0;
					border-left: 1px solid #d1d1d1;
					width: 30px;
					height: 28px;
					text-align: center;
					position: absolute;
					top: 0;
					right: 0;

					&:hover {
						background-color: darken(white, 10%);
					}
				}
			}

			.mpsSearch {
				width: 310px;
				height: 30px;
				border: 1px solid #d1d1d1;
				border-radius: 3px;
				position: relative;
				overflow: hidden;
				background-color: white;
				margin-left: 8px;

				img {
					position: absolute;
					top: 7px;
					left: 10px;
					pointer-events: none;
				}

				input {
					padding-left: 32px;
					font-size: 14px;
					width: 100%;
					height: 100%;
					border: none;

					&::placeholder {
						color: black;
					}
				}
			}
		}

		.mpsStats {
			display: none;
			font-size: 16px;
			font-weight: 400;
			color: #0078a7;
			padding-left: 24px;
		}

		.tableContainer {
			height: calc(100vh - 294px);
			overflow: scroll;
			background-color: #f9f9f9;

			.mpsList {
				flex: 1;
				overflow: scroll;
				border-collapse: separate;

				thead {
					position: sticky;
					top: 0;
				}

				.noItems {
					padding: 24px;
					font-size: 16px;
					transition: none;
					border-top: 1px solid #d1d1d1;
				}

				.mpsSegment {
					td,
					th {
						font-size: 14px;
						font-weight: 400;
						padding: 12px;
						border-bottom: 1px solid #f1f1f1;
						background-color: white;

						&:first-child {
							padding-left: 24px;
						}

						&:last-child {
							padding-right: 24px;
						}

						.mpsHeader {
							display: flex;
							align-items: center;

							> *:last-child {
								margin-left: 4px;
							}
						}
					}

					th {
						font-weight: 600;
						border-bottom: 1px solid #d1d1d1;

						&.smallCol {
							min-width: 120px;
						}

						&:hover:not(:last-child) {
							cursor: pointer;
							background-color: darken(white, 5%);
						}
					}

					.runAdButton {
						height: 32px;
						border: 1px solid #0078a7;
						border-radius: 6px;
						background-color: white;
						color: #0078a7;
						white-space: nowrap;
						padding: 8px 15px;

						&:hover {
							background-color: #f1f1f1;
						}
					}
				}
			}
		}

		.pagination {
			border-top: 1px solid #d1d1d1;
			height: 64px;
			padding: 16px;
			display: flex;
			justify-content: center;

			div {
				margin: 0 12px;

				select,
				input {
					height: 30px;
					border: 1px solid #d1d1d1;
					border-radius: 3px;
					text-align: center;
				}

				button {
					width: 30px;
					height: 30px;
					line-height: 30px;
					text-align: center;
					background-color: white;
					border: none;
					border-radius: 3px;
					cursor: default;

					&:hover:not(:disabled) {
						cursor: pointer;
						background-color: #f1f1f1;
					}

					&:disabled {
						opacity: 0.4;
					}
				}
			}
		}
	}
}
