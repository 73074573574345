@import 'common/node_modules/@dnb-uux-design-system/design-tokens/build/styles/_variables.scss';

:global(.MuiDialog-paper):has(div.customSchema) {
	width: 676px;

	h2:global(.MuiDialogTitle-root) > span {
		padding-bottom: 16px;
	}

	div:global(.MuiDialogActions-root) {
		> button {
			width: 36px;
			height: 36px;
			padding-top: 0;
			padding-right: 0;
			> svg {
				width: 24px;
				height: 24px;
			}
		}
	}
}

.customSchema {
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.caption {
	color: $color-gray-secondary;
}

button:global(.MuiButton-text).noDecoration {
	text-decoration: none;
}
