::-webkit-scrollbar {
}

*::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}


/*
Search
============================================================ */
.search {
  display: inline-block;
  button, input {
    display: inline-block;
  }
  input {
    width: 140px;
    margin-left: -4px;
    padding: 7px 10px 12px;
  }
}

/*
Buttons
============================================================ */
.button, button.button,
a.button, .button a:link, .button a:focus, .button a:visited {
	display: inline-block;
	padding: 0em 1.2em;
	margin-bottom: 0;
	font-size: 12px;
	font-weight: 400;
	line-height: 28px;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-image: none;
	border: 1px solid transparent;
	color: $pearl-white;
	border-radius: 3px;
  outline: none;

  /* disable transitions on all buttons */
  &, * {
    transition: none;
  }

	&:hover {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    transition: none;
	}

	&.link-button, &.link-button a:link,
  &.link-button a:visited, &.link-button a:focus {
		display: inline;
		padding: 0;
		margin: 0;
		border: none;
		background: none;
    color: $saphire;
	}
	&.close-button {
		float: right;
	}
	&.default-button {
		border: $black-titanium solid 1px;
  }
  &.red-button {
    background-color: $ruby;
    &:hover {
      background-color: $ruby-two;
    }
    &:active {
      background-color: $ruby-three;
    }
  }
  &.green-button {
    background-color: $jade;
    &:hover {
      background-color: $jade-two;
    }
    &:active {
      background-color: $jade-three;
    }
  }
  &.yellow-button {
    background-color: #ffbd48;
    color: #fff;

    &:hover {
      background-color: #df9d28;
    }
    &:active {
      background-color: #efad38;
    }
  }
	&.blue-button {
		background-color: #457EBA;
    border: 1px solid #457EBA;
    color: #FFFFFF;
    // box-shadow: 0px 0px 10px 0px rgba(57,137,195,0.20);
    &:hover {
      background-color: #629ACC;
      border: 1px solid #629ACC;
    }
    &:active {
      background-color: #65B7E6;
      border: 1px solid #65B7E6;
		}
    &.outline-button {
      color: #447EBB;
      border-color: #447EBB;
      background-color: transparent;
      padding: 0;
      &:hover {
        color: #629ACC;
        border-color: #629ACC;
        background-color: transparent;
      }
      &:active {
        color: #65B7E6;
        border-color: #65B7E6;
        background-color: transparent;
      }
    }
	}
	&.orange-button {
		background-color: $amber;
		&:hover {
			background-color: $amber-two;
			color: $pearl-white;
		}
		&:active {
			background-color: $amber-three;
		}
		&:focus {
			color: $pearl-white;
		}
	}
  &.green-button {
    background-color: #6fbe4a;
    border: #679e4c solid 1px;
    color: $pearl-white;
    &:hover {
      background-color: #6fbe4a;
    }
    &:active {
      background-color: #6fbe4a;
    }
    &:focus {
      color: $pearl-white;
    }
  }
	&.borderless-button,
	&.small-borderless-button {
		color: #222222;//$black-titanium;
		background-color: transparent;
		border-color: transparent;
		font-size: 14px;
		width: 38px;
		padding: 0;

		&, & *,
		&:after,
		&:before,
		& *:after,
		& *:before {
			-webkit-transition: color 0s linear 0s, transform 150ms linear 0s;
			transition: color 0s linear 0s, transform 150ms linear 0s;
		}
		//&:focus,
		&:hover {
		  	color: #457EBA;
		  	border-color: #d0d0d0;
		  	background-color: #fff;
		}
		&:active {
			color: #222222;
      		border-color: $silver;
      		border-style: inset;
		  	background-color: #fff;
		}
	}
	&.small-borderless-button {
    width: auto;
    padding: 0 5px;
  }
	&.white-button {
		color: #222222;//$black-titanium;
		background-color: $pearl-white;
		border-color: $silver;

		&, & *,
		&:after,
		&:before,
		& *:after,
		& *:before {
			-webkit-transition: color 0s linear 0s, transform 150ms linear 0s;
			transition: color 0s linear 0s, transform 150ms linear 0s;
		}
		&:hover,
		&:focus {
		  	color: #457EBA;
		  	// border-color: #457EBA;
		}
		&:active {
			color: #222222;
      		border-color: $silver;
      		//background-color: $palladium;
		}
	}
  &.gray-button {
		color: #000;
		background-color: #E2E3E5;
		border-color: #E2E3E5;
		border-size: 1px;
		border-style: solid;
		&:hover {
			background-color: $silver;
		}
		&:active {
			background-color: $palladium;
		}
	}
	&.light-gray-button {
		color: $silver;
		background-color: $black-titanium;
		&:hover {
			background-color: $onyx;
		}
		&:active {
			background-color: $jet;
		}
	}
  &.small-button {
    padding: 0px 12px;
    border-radius: 2px;
    font-size: 12px;
    font-weight: bold;
  }
  &.nav-button {
    padding: 0em 2em;
    border-color: #ddd;
  }
	&.block-button {
		display: block;
		width: 100%;
	}
  &.new-tile-button {
    padding: 50px 0 0;
    background-color: transparent;
    text-align: center;
    color: #333;
    width: 100%;
    height: 100%;
    &:hover {
      background-color: transparent;
      color: #222;
    }
    &:active {
      background-color: transparent;
    }
    &:focus {
      color: #222;
    }
    span {
      display: block;
      font-size: 84px;
      line-height: 125px;
    }
  }
  &.disabled {
  	background: #f9f9f9;
    border-color: #dfdfdf;
    color: #d0d0d0;
    &.borderless-button {
    	border-color: transparent !important;
    	background-color: transparent;
    }
    &:hover {
      cursor: default;
      color: #d0d0d0;
      border-color: #dfdfdf;
      border-style: dashed;
      -webkit-box-shadow: none;
      -moz-box-shadow:    none;
      box-shadow:         none;
      background: none;
    }
  }
	@include media(">medium") {
		display: inline-block;
		//padding: 9px 15px;
		&.icon-button {
			padding: 0em 1em;
		}
		&.dropdown-toggle {
			padding: 0.75em;
		}
	}
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  &.blue-button, &.orange-button,
  &.white-button, &.gray-button {
    cursor: default;
    border: #dadada solid 1px;
	color: #999;
    background-color: #f9f9f9;
    box-shadow: none;
    pointer-events: none;
    &:hover {
      color: #bbb;
      background-color: #f0f0f0;
      // background-color: #e7e7e7;
      // color: #b7b7b7;
    }
    &:active {
      color: #bbb;
      background-color: #f0f0f0;
      // background-color: #e7e7e7;
      // color: #b7b7b7;
    }
  }
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}



.button-group,
.button-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.button-group > .button,
.button-group-vertical > .button {
  position: relative;
  float: left;
}
.button-group > .button:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0;
}
.button-group > .button:first-child {
  margin-left: 0;
}
.button-group > .button:first-child:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.button-group > .button:last-child:not(:first-child),
.button-group > .dropdown-toggle:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.button-group > .button-group {
  float: left;
}
.button-group > button:not(:last-child):not(:first-child) {
  border-right: none;
}
.button-group * {
  transition: none;
}
.button-group > .button-group:not(:first-child):not(:last-child) > .button {
  border-radius: 0;
}
.button-group > .button-group:first-child:not(:last-child) > .button:last-child,
.button-group > .button-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.button-group > .button-group:last-child:not(:first-child) > .button:first-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.button-group .dropdown-toggle:active,
.button-group.open .dropdown-toggle {
  outline: 0;
}
.button-group > .button + .dropdown-toggle {
  padding-left: 8px;
  padding-right: 8px;
}
.button-group > .button-lg + .dropdown-toggle {
  padding-left: 12px;
  padding-right: 12px;
}
.button-group.open .dropdown-toggle {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.button-group.open .dropdown-toggle.button-link {
  -webkit-box-shadow: none;
  box-shadow: none;
}



/*
Angled Buttons

Use this structure for angled buttons:
<a class="button angled-button">
  <span>Create a model</span>
</a>
============================================================ */
.button.angled-button {
    display: inline-block;
    position: relative;
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;
    margin-bottom: 2px;
    min-width: 169px;
    padding: 0 20px;
    background: #3d6590;
    border-radius: 0;
    border: 0;
    transition: none;
    animation: none;
    transform: skewX(20deg);
    &:hover {
        cursor: default;
    }
    &:first-child {
        &:before {
            display: block;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            background: inherit;
            width: 50px;
            transform: skewX(-20deg);
            transition: none;
        }
    }
    a, .angled-link {
        display: inline-block;
        padding: 0px 24px 0px 0;
        position: relative;
        transition: none;
        transform: skewX(-20deg);
        display: inline-block;
    }
    .angled-link span {
      color: rgba(255, 255, 255, 0.4);
    }
    span {
        color: #fff;
        letter-spacing: 1px;
        font-weight: normal;
    }
    &.active {
        padding-bottom: 2px;
        margin-bottom: -1px;
        background: #fafafa;
        &:not(.clickable) {
          pointer-events: none;
        }
        span {
            color: #333;
        }
        &:before {
            display: block;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            background: inherit;
            width: 50px;
            transform: skewX(-20deg);
            transition: none;
        }
    }
    &.active:nth-child(2):before {
        width: 39px;
        margin-left: -16px;
    }
    &.active:nth-child(3):before {
        width: 39px;
        margin-left: -12px;
    }
}
.create-model-summary .button.angled-button:first-of-type {
  padding-left: 50px;
  margin-left: -20px;
}


