.le-pagination-container-margin {
	margin: 1em 0;
}

.le-pagination-container-margin-top {
	margin: 1em 0 0;
}

.le-pagination-container-margin-bottom {
	margin: 0 0 1em;
}

.le-pagination {
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;

	span.le-pagination-center {
		margin: 0 0.5em;

		span.le-pagination-pagenum,
		span.le-pagination-pagetotal {
			display: inline-block;
			min-width: 1.3em;
			text-align: center;
		}
	}

	button.white-button {
		padding: 0 0.8em;
		color: #467dba;
		-webkit-transition: all 0s linear;
		-moz-transition: all 0s linear;
		-ms-transition: all 0s linear;
		transition: all 0s linear;
	}

	button[disabled],
	button.active {
		pointer-events: none;
		cursor: default;
		outline: 0;
	}

	button[disabled] {
		color: #ccc;
	}

	.active {
		color: red;
	}
}
