.enrichment-wizard-container,
.container.query-results {
	padding: 0;
	@media (max-width: 1300px) {
		padding: 0 2.52766%;
	}
	&.dashboardrules {
		max-width: 1200px;
		padding: 0 2.52766%;
		padding-top: 1.5em;
		margin: 0 auto;
		.success-message {
			width: auto;
		}
	}
	.loading {
		background: none; // override _progress.scss
	}
}
.open-nav .enrichment-wizard-container,
.open-nav .container.query-results {
	@media (max-width: 1491px) {
		padding: 0 2.52766%;
	}
	&.dashboard-rules {
		max-width: 1200px;
		padding: 0 2.52766%;
		margin: 0 auto;
		padding-top: 40px;
		&.navigation {
			&:after {
				display: table;
				clear: both;
				content: '';
			}
		}
	}
}
.open-nav .enrichment-wizard-container.dashboardrules {
	@media (max-width: 1491px) {
		max-width: 1200px;
		padding: 0 2.52766%;
		margin: 0 auto;
		padding-top: 40px;
		&.navigation {
			&:after {
				display: table;
				clear: both;
				content: '';
			}
		}
	}
}
attr-categories,
.enrichment-wizard-container {
	position: relative;
	$box-width: calc(
		99.99% / 3
	); //99.99 is because of IE and rounding errors at 100%/3 (or any odd number)
	@mixin make-background-color($color, $opacity) {
		background-color: $color;
		background-color: rgba($color, $opacity);
	}
	&.dashboard-rules {
		&.navigation {
			&:after {
				display: table;
				clear: both;
				content: '';
			}
		}
	}
	.enrichment-wizard-category-pagination {
		.pd-pagination {
			text-align: right;
			padding-right: 1em;
			pointer-events: none;
		}
		.pd-pagination-center,
		.pd-pagination-start,
		.pd-pagination-end {
			display: none;
		}
		.button {
			padding: 0.1em 1em;
			pointer-events: auto;
		}
	}
	:not(.section-insights) .enrichment-wizard-category-pagination {
		opacity: 0;
		.pd-pagination {
			text-align: none;
			margin: 0;
			position: absolute;
			left: -1em;
			right: -1em;
			margin-top: 0;
			z-index: 2;
			height: 10em;
			display: flex;
			align-items: center;
		}
		.pd-pagination-prev {
			position: absolute;
			left: 0px;
		}
		.pd-pagination-next {
			position: absolute;
			right: 1px;
		}
		.button {
			padding: 0em 0.75em;
		}
	}
	.carousel-container {
		position: relative;

		.enrichment-wizard-category-pagination {
			opacity: 1;
		}

		button.pd-pagination-prev,
		button.pd-pagination-next {
			background-color: #fff;
			border-color: #ccc;
			color: #bbb;
			border-radius: 50%;
			width: 31px;

			&:hover {
				border-color: #aaa;
				color: #999;
			}
			S &:active {
				border-color: #bbb;
				color: #aaa;
			}
		}

		button.borderless-button.pd-pagination-prev {
			left: -1px;
		}

		button.borderless-button.pd-pagination-next {
			right: -1px;
		}
	}
	.filters-tabs {
		@include flex-container;
		& > li {
			@include flex-item(0 1 auto);
			&.ratingsengine-selected {
				font-size: 19px;
				margin-bottom: 10px;
				& > ul {
					@include flex-container;
					& > li {
						@include flex-item(0 1 auto);
						opacity: 0.7;
						padding-right: 40px;
						position: relative;
						&:after {
							position: absolute;
							content: '';
							top: 20%;
							right: 20px;
							bottom: 20%;
							border-right: 1px solid #d0d0d0;
						}
						&:first-child {
							padding-right: 0;
						}
						&:first-child:after,
						&:last-child:after {
							display: none;
						}
						&:hover {
							color: #457eba;
						}
						&.selected {
							opacity: 1;
							color: #ef6a30;
						}
						.ico {
							position: relative;
							top: -2px;
							width: 25px;
							height: 25px;
							margin-right: 10px;
							background-color: black;
						}
					}
				}
			}
		}
	}
	.filters {
		@include flex-container;
		margin-top: 15px;
		margin-bottom: 15px;
		// margin-right: 14px;
		input[type='radio'],
		input[type='checkbox'] {
			margin: 0;
			vertical-align: middle;
		}
		.select-more {
			border-radius: 6px;
		}
		& > li {
			@include flex-item(0 1 auto);
			margin-right: 10px;
			label {
				width: auto;
				vertical-align: middle;
				padding: 1px 5px;
			}

			&:last-child {
				margin-right: 0;
			}
			&[disabled] {
				pointer-events: none;
				opacity: 0.4;
			}
			&.query {
				flex: 1 1 auto;
				margin-right: 0.5em;
				.ng-search {
					white-space: nowrap;
				}
				span.fa {
					min-width: 1.3em;
				}
				input {
					padding-left: 0;
				}
			}
			&.download-link {
				a.button {
					line-height: 1.5;
				}
			}
			&.filter {
				&.dropdown-container {
					z-index: 2;
					.dropdown {
						.selected,
						.enabled,
						.highlighted {
							&.hide:not(:last-child) {
								border-bottom: 1px solid #333;
								padding-bottom: 5px;
								margin-bottom: 10px;
							}
						}
						.internal:not(:first-child) {
							&.show {
								border-top: 1px solid #333;
								padding-top: 5px;
								margin-top: 10px;
							}
						}
					}
				}
				li {
					padding: 0 7px;
				}
			}
			&.download {
				li {
					padding: 5px;
				}
				a {
					color: #fafafa;
					&:hover {
						color: #fafafa;
					}
					i {
						margin-right: 5px;
					}
				}
				button {
					&.select-more {
						span {
							&::before {
								-webkit-transition: color 0s linear 0s;
								-moz-transition: color 0s linear 0s;
								transition: color 0s linear 0s;
							}
						}
					}
				}
			}
			&.account-lookup button {
				height: 3em;
			}
			&.info {
				flex: 1 1 auto;
				position: relative;
				z-index: 200;
				& > div {
					position: relative;
					top: 50%;
					transform: translateY(-50%);
					border-left: 1px solid #d0d1d0;
					padding-left: 18px;
					font-size: 16px;
					i {
						color: #457eba;
						font-size: 19px;
					}
				}
			}
		}
	}

	.select-all-toggle {
		.button.link-button {
			font-size: 16px;
			&:first-of-type {
				margin-right: 2px;
			}
			&:last-of-type {
				margin-left: 2px;
			}
		}
	}

	.enrichment-wizard-pagination {
		float: right;

		pd-pagination-controls .pd-pagination {
			text-align: right;
			// margin-right: 15px;
		}
	}

	.alt-title {
		font-size: 14px;
		color: #5e86da;
	}
	.categories {
		@include flex-container;
		margin-bottom: 20px;
		margin-right: 2px;
		&:not(.has-category) {
			-webkit-flex-wrap: wrap;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
			* {
				transition: none;
			}
			.category {
				@include flex-item(0 0 auto);
				width: $box-width;
				padding: 10px 14px 0 0;
				&:nth-child(3n) {
					padding-right: 0;
				}
				.box-inner {
					position: relative;
					h2 {
						margin-top: 8px;
						font-size: 20px;
						.category-label {
							max-height: 45px;
							height: 1.1em;
							white-space: initial;
							.category-label-span {
							}
						}
					}
					.count {
						position: absolute;
						top: 5px;
						right: 15px;
						font-size: 20px;
						.tooltip_ {
							color: white;
							font-size: 12px;
							width: auto;
							.cover {
								white-space: nowrap;
								padding-right: 20px;
							}
						}
					}
					.attributes {
						.attribute {
							padding-left: 20px;
							&.no-highlights {
								padding-left: 2px;
							}
							& > * {
								pointer-events: none;
							}
							&.has-highlight:not(.no-highlights) {
								position: relative;
								&:before {
									@include font-awesome-inline('\f005');
									display: block;
									position: absolute;
									top: calc(50% - 1px);
									left: 5px;
									transform: translateY(-50%);
								}
							}
							.column {
								strong {
									position: relative;
									padding-left: 15px;
								}
							}
						}
					}
				}
			}
		}
		&.has-category {
			* {
				transition: none;
			}
			.category {
				width: 50px;
				max-width: 30%;
				h2 {
					margin-top: 10px;
				}
				@media screen and (max-width: 850px) {
					.category-label {
						/* This negatively affects the iframe Insights and causes label to dissapear */
						/*display: none;*/
					}
				}
				.box-inner {
					margin: 0 -2px 0 0px;
					& > span {
						padding: 5px 7px 3px;
						border-color: #ddd;
						border-style: solid;
						border-width: 0 1px 1px;
					}
				}
			}
		}
		.category {
			@include flex-item(1 0 auto);
			padding: 0px 6px 0 0;
			&.active {
				pointer-events: none;
				cursor: default;
			}
			.box-inner {
				text-align: center;
				& > span {
					padding: 10px;
				}
				&.active {
					& > span {
						position: relative;
						z-index: 1;
					}
					position: relative;
					& > span {
						padding: 5px 7px 3px;
						border-width: 1px;
						border-style: solid;
						border-top: none;
					}
					&:after {
						content: '';
						display: block;
						position: absolute;
						left: calc(50% - 10px);
						bottom: -10px;
						height: 20px;
						width: 20px;
						background: white;
						transform: rotate(45deg);
						border-bottom-width: 1px;
						border-bottom-style: solid;
						border-right-width: 1px;
						border-right-style: solid;
						border-color: inherit;
						z-index: 1;
					}
				}
				h2 {
					text-align: center;
					font-size: 15px;
					margin-bottom: 5px;
					white-space: nowrap;
					.category-icon-container {
						margin-bottom: 5px;
						display: inline-block;
						margin: 0 auto;
						border-width: 1px;
						border-radius: 50%;
						border-style: solid;
						-webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
						-moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
						box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
					}
					.category-icon {
						padding: 25px;
						-webkit-mask-repeat: no-repeat;
						mask-repeat: no-repeat;
						-webkit-mask-size: 20px;
						mask-size: 20px;
						-webkit-mask-position: center center;
						mask-position: center center;
					}
					.category-label {
						.category-label-span {
							@include text-ellipsis();
							display: inline-block;
							max-width: calc(100% - 20px);
						}
						.more-info {
							padding: 2px;
							text-align: center;
							width: 11px;
							height: 11px;
							position: relative;
							top: -8px;
							font-size: 8px;
							text-transform: none;
							cursor: pointer;
							.tooltip_ {
								.cover {
									text-align: center;
									color: white;
								}
							}
						}
					}
				}
				.count {
					display: block;
					font-weight: bold;
					font-size: 16px;
					margin-bottom: 10px;
				}
			}
		}
		&.has-category {
			.category {
				&:last-child {
					padding-right: 0;
				}
			}
		}
		.first-spot-info-card {
			.box-inner {
				text-align: left;
				border-top-width: 1px;
				border-radius: 4px;
			}
		}
		.company {
			.box-inner {
				&.company-info {
					font-size: 11px;
					h2,
					h3 {
						font-size: 100%;
						font-weight: normal;
						text-transform: none;
						text-align: left;
					}
					h2 {
						@include text-ellipsis;
						font-size: 20px;
						.attribute-feedback-container {
							display: inline;
							position: relative;
							padding-right: 17px;
							attribute-feedback-button {
								display: none;
								pointer-events: auto;
								position: absolute;
								top: 50%;
								transform: translateY(-50%);
								right: 2px;
								font-size: 11px;
								div.infodot {
									color: #0c8bc7;
								}
							}
						}
						&:hover {
							attribute-feedback-button {
								display: block;
								.feedback-tooltip {
								}
							}
						}
					}
					.info {
						margin-bottom: 10px;
					}
					.details {
						font-size: 12px;
						h3 {
							font-weight: bold;
						}
						& > div {
							margin-bottom: 10px;
							border-top: 1px solid rgba(0, 0, 0, 0.05);
							padding-top: 5px;
							&:last-child {
								margin-bottom: 0;
							}
						}
						& > ul {
							& > li {
								@include flex-container;
								vertical-align: middle;
								padding: 2px 5px;
								font-weight: bold;
								&:nth-child(odd) {
									border-top: 1px solid rgba(0, 0, 0, 0.05);
									background: rgba(0, 0, 0, 0.05);
								}
								h3 {
									@include flex-item(1 1 auto);
								}
								&:hover {
									attribute-feedback-button {
										display: block;
									}
								}
							}
						}
						.attribute-feedback-container {
							display: inline;
							position: relative;
							padding-left: 17px;
							attribute-feedback-button {
								display: none;
								pointer-events: auto;
								position: absolute;
								top: 50%;
								transform: translateY(-50%);
								left: 2px;
								div.infodot {
									color: #0c8bc7;
								}
							}
						}
						.sales-employees .sales,
						.sales-employees .employees,
						.industry {
							.attribute-feedback-container {
								padding-left: 0;
								padding-right: 17px;
								attribute-feedback-button {
									left: auto;
									right: 2px;
								}
							}
							&:hover {
								attribute-feedback-button {
									display: block;
								}
							}
						}
						.sales-employees {
							@include flex-container;
							padding-top: 0;
							& > div {
								@include flex-item(1 1 auto);
								width: 50%;
								border-right: 1px solid rgba(0, 0, 0, 0.05);
								padding: 0 10px;
								padding-top: 5px;
								&:first-child {
									padding-left: 0;
								}
								&:last-child {
									border-right: none;
								}
							}
						}
					}
				}
			}
		}
		.lattice-ratings {
			&.box-inner {
				& > h2 {
					.category-icon.ratings-logo {
						img {
							margin-left: -2px;
						}
					}
				}
			}
			.lattice-ratings {
				position: relative;
				margin-top: 15px;
				.lattice-rating-list {
					font-size: 11px;
					&:nth-child(even) {
						background: rgba(0, 0, 0, 0.06);
					}
					padding: 5px 0;
					.rating-item {
						@include flex-container;
						& > li {
							@include flex-item(0 1 auto);
							min-width: 40px;
							margin-right: 5px;
							&:nth-child(1) {
								text-align: right;
								padding-right: 5px;
							}
							&.rating-label {
								width: 50px;
								margin-right: 0;
								text-align: left;
								& > input {
									pointer-events: none;
								}
							}
							&.rating-lift,
							&.rating-count {
								width: 40px;
							}
							&.rating-bar {
								@include flex-item(1 1 auto);
								margin-right: 20px;
								& > div {
									background: #5097dc;
									height: 80%;
									position: relative;
									top: 50%;
									transform: translateY(-50%);
									&.A-plus {
										background-color: #4ba6ff;
									}
									&.A {
										background-color: #5097dc;
									}
									&.B {
										background-color: #4188cd;
									}
									&.C {
										background-color: #337abf;
									}
									&.D {
										background-color: #2067ac;
									}
									&.F {
										background-color: #0d5499;
									}
								}
							}
						}
					}
					&:nth-child(1) {
						font-size: 9px;
						color: #1a1a1a;
						padding-top: 5px;
						text-transform: uppercase;
					}
					/*
                    &:nth-child(2) .rating-bar > div {
                        opacity: .5;
                    }
                    &:nth-child(3) .rating-bar > div {
                        opacity: .6;
                    }
                    &:nth-child(4) .rating-bar > div {
                        opacity: .7;
                    }
                    &:nth-child(5) .rating-bar > div {
                        opacity: .8;
                    }
                    &:nth-child(6) .rating-bar > div {
                        opacity: .9;
                    }
                    &:nth-child(7) .rating-bar > div {
                        opacity: 10;
                    }
                    */
				}
				.rating-slices {
					position: absolute;
					top: 25px;
					right: 90px;
					left: 50px;
					bottom: 5px;
					font-size: 10px;
					pointer-events: none;
					@include flex-container;
					.rating-slice {
						@include flex-item(1 1 auto);
						height: 100%;
						position: relative;
						border-left: 1px solid #d0d1d0;
						strong {
							position: absolute;
							bottom: -16px;
							left: -4px;
							text-align: right;
						}
					}
				}
			}
		}
	}

	.subcategories {
		@include flex-container;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		clear: both;
		.subcategory {
			@include flex-item(0 0 auto);
			width: $box-width;
			padding: 14px 14px 0 0;
			&:nth-child(3n) {
				padding-right: 0;
			}
			.box-inner {
				position: relative;
				text-align: center;
				height: 100%;
				background-color: white;
				& > span {
					padding: 10px;
				}
				h2 {
					text-align: center;
					font-size: 16px;
					margin-top: 0.5em;
					margin-bottom: 1em;
					font-weight: bold;
					.category-label {
						height: 35px;
						overflow: hidden;
					}
					.category-icon-container {
						margin-bottom: 5px;
						display: inline-block;
						margin: 0 auto;
						border-width: 1px;
						border-radius: 50%;
						border-style: solid;
						-webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
						-moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
						box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
					}
					.category-icon {
						padding: 25px;
						-webkit-mask-repeat: no-repeat;
						mask-repeat: no-repeat;
						-webkit-mask-size: 20px;
						mask-size: 20px;
						-webkit-mask-position: center center;
						mask-position: center center;
					}
					// .category-icon {
					//     height: 36px;
					//     width: 36px;
					//     border-width: 1px;
					//     border-style: solid;
					//     //border-color: #d0d1d0;
					//     padding: 8px;
					//     border-radius: 50%;
					//     margin: 0 auto;
					//     margin-bottom: 5px;
					//     img {
					//         display: block;
					//         margin: 0 auto;
					//         margin-bottom: 5px;
					//         height: 100%;
					//         width: 100%;
					//     }
					// }
				}
				.count {
					position: absolute;
					top: 0.5em;
					right: 1em;
					font-weight: bold;
					font-size: 16px;
					.tooltip_ {
						color: white;
						font-size: 12px;
						width: auto;
						.cover {
							white-space: nowrap;
							padding-right: 20px;
						}
					}
				}
			}
		}
	}

	.subcategory-back {
		font-size: 18px;
		display: inline-block;
	}

	&:not(.section-browse) {
		.enrichments {
			.box {
				.box-inner {
					height: auto;
					width: 100%;
				}
			}
		}
	}
	&.section-team {
		.categories:not(.has-category) .category,
		.subcategory {
			.box-inner {
				padding-bottom: 4em;
			}
		}
	}
	.enrichments {
		@include flex-container;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		clear: both;
		.box {
			@include flex-item(0 0 auto);
			@include flex-container;
			width: $box-width;
			min-height: 230px; // attribute tiles without descriptions need min height to avoid button overlap
			padding: 14px 14px 0 0;
			&:nth-child(3n) {
				padding-right: 0;
			}
			&.box-value-no {
				opacity: 0.5;
			}
			.box-inner {
				position: relative;
				text-align: center;
				//padding-bottom: 3.5em;
				//height: 200px;
				& > span {
					display: flex;
					flex-direction: column;
					padding: 10px 10px 35px 10px; // add extra padding at bottom for button room
				}
				.header {
					h2 {
						padding: 0;
						font-size: 16px;
						margin: 0.45em 0 1.25em;
						font-weight: bold;
						.highlighted-decoration {
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							white-space: nowrap;
							margin: 0 10px;
							&:first-child {
								right: 100%;
							}
							&:last-child {
								left: 100%;
							}
						}
						.category-icon-container {
							position: relative;
							margin-bottom: 5px;
							display: inline-block;
							margin: 0 auto;
							border-width: 1px;
							border-radius: 50%;
							border-style: solid;
							-webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
							-moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
							box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
						}
						.category-icon {
							padding: 25px;
							-webkit-mask-repeat: no-repeat;
							mask-repeat: no-repeat;
							-webkit-mask-size: 20px;
							mask-size: 20px;
							-webkit-mask-position: center center;
							mask-position: center center;
						}
						// .category-icon {
						//     position: relative;
						//     height: 36px;
						//     width: 36px;
						//     border-width: 1px;
						//     border-style: solid;
						//     // border-color: #d0d1d0;
						//     padding: 8px;
						//     border-radius: 50%;
						//     margin: 0 auto;
						//     margin-bottom: 5px;
						//     img {
						//         display: block;
						//         margin: 0 auto;
						//         margin-bottom: 5px;
						//         height: 100%;
						//         width: 100%;
						//     }

						// }
						strong {
							display: block;
							max-height: 3.2em;
							overflow: hidden;
						}
						.more-info {
							padding: 2px;
							text-align: center;
							width: 11px;
							height: 11px;
							position: relative;
							top: -2px;
							font-size: 8px;
							text-transform: none;
							cursor: pointer;
							.tooltip_ {
								.cover {
									text-align: center;
									color: white;
								}
							}
						}
					}
				}
				.description {
					margin: 1em 0;
					border: 1px solid #d0d1d0;
					border-left: 0;
					border-right: 0;
					overflow: hidden;
					overflow-y: auto;
					max-height: 9.8em;
					span {
						font-size: 12px;
						color: black;
						line-height: 1.4em;
						display: inline-block;
						padding: 0.5em 0;
						text-align: left;
					}
					strong {
						font-size: 16px;
						font-weight: bold;
						padding: 0.5em 0;
						display: inline-block;
					}
				}
				.stats-boolean {
					h3 {
						color: inherit;
					}
					h4 {
						color: #1a1a1a;
						font-size: 14px;
					}
					p {
						color: #1a1a1a;
						font-size: 10px;
						font-weight: bold;
					}
				}
				.stats {
					font-size: 11px;
					h5 {
						font-size: 9px;
						@include flex-container;
						label {
							@include flex-item(0 1 auto);
							text-align: center;
							color: #adadad;
							text-transform: uppercase;
							&:first-child {
								text-align: left;
							}
							&:last-child {
								text-align: right;
							}
						}
					}
					ul.attribute-stats {
						li.attribute-stat {
							& * {
								-webkit-transition: none;
								transition: none;
							}
							&:nth-child(odd) {
								background-color: rgba(0, 0, 0, 0.05);
							}
							ul.graph {
								@include flex-container;
								color: #000;
								li {
									margin: 0 5px 0 2px;
									padding: 4px 0;
									&.label {
										@include flex-item(0 1 auto);
										position: relative;
										width: 100px;
										max-height: 31px;
										overflow: hidden;
										word-wrap: break-word;
										text-align: right;
										line-height: 18px;
										text-overflow: ellipsis;
										white-space: nowrap;
										//padding-left: 20px;
										& > i {
											//position: absolute;
											//top: -1px;
											//left: -2px;
											float: left;
											padding-right: 3px;
										}
									}
									&.bar_ {
										@include flex-item(1 1 auto);
										border-left: 1px solid #d0d1d0;
										div {
											height: 100%;
											max-width: 100%;
											span {
												display: block;
												height: 100%;
												position: relative;
												// top: 50%;
												// transform: translateY(-50%);
											}
										}
									}
									&.count,
									&.lift {
										@include flex-item(0 1 auto);
										white-space: nowrap;
										min-width: 3em;
										span {
											display: block;
											position: relative;
											// top: 50%;
											// transform: translateY(-50%);
										}
									}
								}
							}
							&.disable-bar {
								ul.graph {
									li {
										&.label {
											text-align: left;
										}
										&.bar_ {
											div {
												display: none;
											}
										}
									}
								}
							}
						}
						li.attribute-stat:not(.non-interactive) {
							&:hover ul.graph {
								color: #fff;
								li.bar_ {
									border-left-color: #fff;
									div span {
										background-color: #fff;
										opacity: 0.5;
									}
								}
							}
						}
						li.non-interactive {
							background-color: initial !important;

							&:nth-child(odd) {
								background-color: rgba(0, 0, 0, 0.05) !important;
							}

							ul {
								cursor: default;

								li.label {
									color: initial !important;
								}
							}
						}
					}
				}
				span.lesser-description {
					line-height: 1.3em;
					display: inline-block;
					color: black;
					text-align: left;
				}
				attribute-feedback-button {
					position: absolute;
					top: 0;
					right: 0;
				}
			}
		}
	}

	.load-status {
		margin: 0 auto;
	}

	.count {
		em {
			font-style: italic;
		}
		i {
			font-size: 10px;
			margin-left: 5px;
			color: rgba(0, 0, 0, 0.5);
		}
	}

	.round-box {
		background: #fafafa;
		border: none;
		border-top: 5px solid #ccc;
		border-radius: 0 0 5px 5px;

		& > span {
			position: relative;
			display: block;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;
			background-color: white;
			height: 100%;
		}

		& > span.disabled {
			background-color: #ededed;
		}

		& > span:active {
			border-style: inset;
			border-top: none;
		}
	}

	.box-inner {
		height: 100%;
		&:hover {
			ul.attributes {
				& > li {
					&:nth-child(even) {
						transition-duration: 0s;
						background-color: rgba(0, 0, 0, 0.09);
					}
				}
			}
		}
		div.attribute-modeliteration-toggle {
			display: block;
			margin-top: auto;
			height: 1.5em;
			i.fa-toggle-on {
				font-size: 1.6em;
				position: absolute;
				left: 0;
				right: 0;
				color: #60af3a;

				&,
				&:before,
				&:after {
					-webkit-transition: none;
					transition: none;
				}
				&.fa-rotate-180 {
					color: #aaa;
				}
			}
		}
		div.attribute-importance {
			position: absolute;
			top: 0px;
			text-align: left;
			z-index: 1;
			width: 100%;
			color: black;
			div.attribute-warning {
				background: #5b5b5b;
				color: #fff;
				position: absolute;
				top: -5px;
				line-height: 1em;
				padding: 0.5em 0 0.4em;
				font-size: 8pt;
				width: 100%;
				cursor: pointer;
				i {
					color: orange;
					margin: 0 1.25em;
				}
			}
			span {
				display: block;
				text-transform: uppercase;
				padding-left: 10px;
				&.attribute-importance-label {
					font-size: 0.7em;
					padding-top: 6px;
				}
				&.attribute-importance-number {
					font-size: 1em;
					font-weight: bold;
					line-height: 1em;
				}
			}
		}
		span.attribute-count {
			display: inline-block;
			position: absolute;
			top: 0.8em;
			right: 0.8em;
			width: 2.5em;
			padding: 0.1em 0.3em 0;
			color: #ffffff;
			border-radius: 3px;
		}
		span.free-text-icon {
			margin-right: 5px;
			font-weight: bold;
		}
		ul.attributes {
			margin-top: 15px;
			font-size: 11px;
			text-align: left;
			> li > div:not(.data-value-yes):not(.inherit-color) {
				color: #1a1a1a;
			}
			& > li {
				@include flex-container;
				padding: 5px;

				&:nth-child(even) {
					background-color: #f0f0f0;
				}
				&:first-child {
					font-size: 9px;
					color: #adadad;
					text-transform: uppercase;
					padding-bottom: 0;
					.column.label {
						text-transform: uppercase;
					}
				}
				.attribute-feedback-container {
					display: inline;
					position: relative;
					//                     padding-left: 15px;
					attribute-feedback-button {
						display: none;
						pointer-events: auto;
						position: absolute;
						top: -1px;
						left: 0;
						div.infodot {
							color: #0c8bc7;
						}
					}
				}
				&:hover {
					attribute-feedback-button {
						display: block;
					}
				}
				& > .column {
					@include flex-item(1 1 auto);
					padding: 0;
					&.label {
						@include text-ellipsis();
						margin-right: 5px;
						max-width: 80%;
					}
					&.data {
						@include flex-item(0 1 auto);
						text-align: right;
						height: 1.3em;
						text-overflow: ellipsis; //ben
						overflow: hidden;
						min-width: 26%;
						/*
                        &:not(.records) {
                            min-width: 20%;
                        }
                        */
						&.inherit-color {
							color: inherit;
						}
						&.data-value-yes {
							font-weight: bold;
						}
						&.lift {
							overflow: visible;
						}
						& > em {
							display: inline-block;
							background-color: white;
							border-radius: 3px;
							overflow: hidden;
							width: 2.5em;
							position: relative;
							top: -2px;
							div {
								display: inline-block;
								padding: 4px;
								color: white;
								width: 100%;
								text-align: center;
							}
						}
					}
				}
				/*
                &:nth-child(2) > .column.data.lift em div {
                    opacity: .4;
                }
                &:nth-child(3) > .column.data.lift em div {
                    opacity: .55;
                }
                &:nth-child(4) > .column.data.lift em div {
                    opacity: .7;
                }
                &:nth-child(5) > .column.data.lift em div {
                    opacity: .85;
                }
                &:nth-child(6) > .column.data.lift em div {
                    opacity: 1;
                }
                */
			}
		}
		ul.attribute-stats,
		ul.attributes {
			& > li {
				&,
				.column,
				.column strong,
				i:before {
					-o-transition-property: none;
					-moz-transition-property: none;
					-ms-transition-property: none;
					-webkit-transition-property: none;
					transition-property: none;
				}
				div.column {
					&.checkspace {
						min-width: 1.5em;
						max-width: 1.5em;
						i.plus {
							display: none;
							width: 1.5em;
							color: #fff;
						}
						i.attribute-use-count {
							font-weight: bold;
							margin-left: 2px;
						}
					}
				}
				li.column {
					&.checkspace {
						i.plus {
							display: none;
							color: #fff;
						}
						i.plus,
						i.fa-check {
							margin-top: 3px;
						}
						i.attribute-use-count {
							font-weight: bold;
							margin-left: 2px;
							margin-top: 0;
						}
					}
				}
				&.attribute:not(.no-edit):hover .column {
					&,
					strong {
						color: #fff !important;
					}
					&.checkspace i {
						color: #fff;
						display: none;
						&.plus {
							display: inline-block;
							color: #fff;
							&.fa-plus {
								padding-left: 1px;
							}
						}
					}
				}
			}
		}
		.attributes-more {
			text-transform: uppercase;
			color: #3a83c9;
			margin-top: 10px;
			font-weight: bold;
			font-size: 9pt;
		}
		&:hover .attributes-more {
			text-decoration: underline;
		}
		input.segment-select {
			vertical-align: middle;
		}
		.controls {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0.5em;
			.control {
				@include flex-item(1 0 auto);
				@include flex-container;
				position: relative;
				min-width: 135px;
				button,
				.button {
					@include flex-item(0 0 auto);
					-webkit-align-self: center;
					-ms-flex-item-align: center;
					align-self: center;
					margin: 0 auto;
					min-width: 170px;

					position: relative;
					padding: 0px 9px;
					font-size: 10px;
					font-weight: bold;
					color: #1a1a1a;
					border-radius: 1px;
					box-shadow: 0 0 10px 0 rgba(57, 137, 195, 0.1);
					background-color: #e5e5e5;
					border: solid 1px #bbb;

					&:hover {
						border-style: outset;
						color: #457eba;
					}
					&:active {
						border-style: inset;
						color: #000000;
					}
					span {
						padding-right: 20px;
					}
				}
			}
			.ratings-selection,
			.enrich {
				& button {
					&:after {
						@include font-awesome-inline('\f00d');
					}
				}
				&.dashboardrules button {
					&:after {
						@include font-awesome-inline('\f067');
					}
				}
				button {
					&:after {
						position: absolute;
						top: 0;
						bottom: 0;
						left: auto;
						right: 0;
						background-color: #fff;
						padding: 0px 5px;
					}
					&.selected {
						background-color: #fafafa;
						span {
							padding: 0;
							padding-left: 20px;
						}
						&:after {
							@include font-awesome-inline('\f00c');
							left: 0;
							right: auto;
							color: #6fbe4a;
							border-right: 1px solid #bbb;
						}
					}
				}
			}
			.highlight {
				&[disabled] {
					pointer-events: none;
					opacity: 0.5;
				}
				button,
				.button {
					overflow: visible;
					font-size: 10px;
					position: relative;
					text-transform: none;
					text-align: left;
					padding: 0px 5px 2px;
					&:after {
						@include font-awesome-inline('\f0d7');
						position: absolute;
						top: 0;
						bottom: 0;
						left: auto;
						right: 0;
						background-color: inherit;
						padding: 0px 5px 3px;
					}
					&.enabled {
						background-color: #fafafa;
						&:after {
							border-left: 1px solid #bbbbbb;
						}
					}
					.highlightOptions {
						display: none;
						position: absolute;
						top: 100%;
						left: -1px;
						right: -1px;
						background: white;
						border: 1px solid #bbb;
						z-index: 1;
						padding-top: 10px;
						border-radius: 0 0 1px 1px;
						&.open {
							display: block;
						}
						li {
							&.current {
								//pointer-events: none;
								cursor: default;
							}
							margin: 3px 5px;
							&.note {
								border-top: 1px solid #d0d1d0;
								padding: 8px 0;
								margin-top: 9px;
							}
							&.disable-enable-all {
								//pointer-events: none;
								cursor: default;
							}
						}
					}
					.highlight-icon {
						position: relative;
						padding-left: 16px;
						&:after {
							@include font-awesome-inline('\f013');
							display: block;
							position: absolute;
							top: 50%;
							left: 0;
							transform: translateY(-51.5%);
						}
						&.enabled:after {
							@include font-awesome-inline('\f00c');
							color: #0f9e4d;
						}
						&.disabled:after {
							@include font-awesome-inline('\f111');
							font-size: 5px;
							margin-left: 3px;
						}
						&.highlighted:after {
							@include font-awesome-inline('\f005');
							color: #f78b3a;
						}
					}
				}
			}
		}
	}

	.dropdown-container {
		position: relative;
		z-index: 1;
		&.active {
			z-index: 2;
		}
		h2.active {
			background: #1a1a1a;
			border-radius: 5px 5px 0 0;
			color: #fafafa;
		}
		h2.selected {
			background: #1a1a1a;
			color: #fafafa;
		}
		.dropdown {
			box-shadow: 0px 0px 11px 2px #00000054;
			position: absolute;
			bottom: 3px;
			right: 1px;
			transform: translateY(100%);
			background: #1a1a1a;
			color: #fafafa;
			white-space: nowrap;
			padding: 5px;
			border-radius: 4px;
			display: none;
			&.open {
				display: inline-block;
			}
		}
		&.download {
			.dropdown {
				left: auto;
				right: 1px;
			}
		}
	}
	.box-inner ul.attributes > li,
	.box-inner .stats ul > li {
		input {
			pointer-events: none;
		}
	}

	*[ng-click]:not(.ignore-ngclick) {
		cursor: pointer;
	}

	[data-corner-ribbon] {
		position: absolute;
		left: -6px;
		top: -9px;
		/* z-index: 1; -overlaps highlight menu dropdown */
		overflow: hidden;
		height: 77px;
		width: 77px;
		strong {
			font-size: 12px;
			color: #1a1a1a;
			text-transform: uppercase;
			text-align: center;
			line-height: 24px;
			transform: rotate(-45deg);
			width: 100px;
			display: block;
			background: #d0d1d0;
			box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
			position: absolute;
			top: 15px;
			left: -22px;
			&::before {
				content: '';
				position: absolute;
				top: 100%;
				left: 0px;
				z-index: -1;
				border-left: 3px solid #d0d1d0;
				border-right: 3px solid transparent;
				border-bottom: 3px solid transparent;
				border-top: 3px solid #d0d1d0;
			}
			&::after {
				content: '';
				position: absolute;
				top: 100%;
				right: 0px;
				z-index: -1;
				border-left: 3px solid transparent;
				border-right: 3px solid #d0d1d0;
				border-bottom: 3px solid transparent;
				border-top: 3px solid #d0d1d0;
			}
		}
	}

	.disabled-grayout {
		.color {
			color: gray;
		}
		.border {
			border-color: gray;
		}
		.border-top {
			border-top-color: gray;
		}
		&.background:not(.active):hover,
		&.active.active-glow {
			border-color: gray;
		}
		.background:not(.active):hover > span,
		.active.active-glow > span {
		}
		.background-color {
			background-color: gray;
		}
		&.category,
		&.subcategory {
			.box-inner {
				&.background {
					background-color: #f7f7f7;
				}
			}
		}
		img {
			filter: grayscale(100%);
		}
		&.enrichment {
			.box-inner {
				background-color: #f7f7f7;
			}
		}
	}
	.enrichments-loading-bar {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 10000;
		& > div {
			height: 5px;
			background-color: $datacloud-color-hg-technology-profile;
		}
	}
	.no-results-lookup {
		margin: 0 auto;
		width: 80%;
		padding: 30px;
		border: 1px solid #d0d0d0;
		border-radius: 10px;
		margin-top: 40px;
		h2,
		p {
			margin-bottom: 10px;
		}
	}
	attribute-feedback-modal {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%);
		background-color: white;
		overflow: hidden;
		border: 1px solid #d0d1d0;
		border-radius: 4px;
		z-index: 100;
		.close {
			position: absolute;
			top: 2px;
			right: 5px;
			color: #d0d1d0;
			&:hover {
				color: #111;
			}
		}
		.attribute-feedback-container {
			@include flex-container;
			flex-direction: column;
			pointer-events: auto;
			.section {
				@include flex-item(0 1 auto);
				padding: 10px 20px;
				&.middle {
					@include flex-item(1 1 auto);
					overflow: hidden;
					overflow-y: auto;
					padding: 10px 0;
				}
				.sub-section {
					padding: 10px 20px;
				}
			}
			.attribute-feedback-header {
				padding: 20px;
			}
			h1 {
				font-size: 24px;
				margin-bottom: 10px;
			}
			h2 {
				padding: 10px 20px;
				text-align: center;
				font-size: 15px;
				white-space: nowrap;
				margin: 10px 0;
				border-top: 1px solid #d0d1d0;
				border-bottom: 1px solid #d0d1d0;
				.icon {
					height: 40px;
					width: 40px;
					border-width: 1px;
					border-style: solid;
					border-color: #d0d1d0;
					padding: 10px;
					border-radius: 50%;
					margin: 0 auto;
					margin-bottom: 5px;
					img {
						display: block;
						margin: 0 auto;
						margin-bottom: 5px;
						height: 100%;
						width: 100%;
					}
				}
				.label {
					@include text-ellipsis();
					font-size: 16px;
					font-weight: bold;
				}
			}
			h3 {
				font-size: 16px;
				text-align: center;
				border-bottom: 1px solid #d0d1d0;
				text-transform: none;
			}
			h4 {
				font-size: 100%;
				margin-bottom: 10px;
			}
			dl,
			dt,
			dd {
				font-size: 100%;
				font-weight: bold;
			}
			.user-input-record {
				font-weight: bold;
			}
			.feedback {
				label {
					display: block;
					font-weight: bold;
				}
				input,
				textarea {
					padding: 5px;
					width: 100%;
					border: 1px solid #d0d1d0;
				}
				& > li {
					margin-bottom: 10px;
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
			.attribute-feedback-footer {
				padding: 10px 20px;
				border-top: 1px solid #d0d1d0;
				button:last-child {
					float: right;
				}
			}
		}
	}
	attribute-feedback-button {
		.infodot {
			line-height: 11px;
			&:hover {
				.feedback-tooltip {
					opacity: 1;
				}
			}
		}
		.feedback-tooltip {
			position: absolute;
			top: -39px;
			left: 50%;
			transform: translateX(-50%);
			opacity: 0;
			&:not(.for-specificity) {
				transition: opacity 2500ms;
			}
			div.cover:not(.for-specificity) {
				background-color: black;
				color: #f1f1f1;
				white-space: nowrap;
				padding: 8px;
			}
			&:before,
			&:after {
				content: '';
				display: block;
				position: absolute;
				left: calc(50% - 7px);
				bottom: -7px;
				height: 15px;
				width: 15px;
				transform: rotate(45deg);
			}
			&:before {
				box-shadow: inherit;
				background: #222831;
				z-index: -10;
			}
			&:after {
				background: inherit;
				z-index: 10;
			}
		}
		.menu {
			position: relative;
			text-align: right;
			button.attribute-feedback-menu-button {
				padding: 0.5em 0.75em 0.35em 0.8em;
				margin-right: 0;
				font-size: larger;
				transition: all 0s linear;
				background-color: transparent;
				border: 0;
				border-bottom-width: 1px;
				&.open {
					background: #222;
					color: #fff;
					border-radius: 4px;
					border-bottom-right-radius: 0;
					border-bottom-left-radius: 0;
					border-top-left-radius: 0;
					&:hover * {
						color: #fff;
					}
				}
				&:hover {
					* {
						color: #f16a22;
					}
				}
			}
			ul.attribute-feedback-menu {
				position: absolute;
				right: 0;
				z-index: 200;
				background: #222;
				padding: 10px 0;
				font-size: 16px;
				-webkit-border-bottom-left-radius: 6px;
				-moz-border-bottom-left-radius: 6px;
				border-bottom-left-radius: 6px;
				-webkit-border-top-left-radius: 6px;
				-moz-border-top-left-radius: 6px;
				border-top-left-radius: 6px;
				font-family: 'ProximaNova-Regular', 'Open Sans', sans-serif;
				li {
					display: block;
					list-style: none;
					padding: 0.3em 1em;
					text-align: right;
					color: #eee;
					white-space: nowrap;
					&:hover {
						background: #444;
						transition: none;
						color: #f16a22;
					}
					&:last-of-type {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}

.enrichment-wizard-container .enrichments .box .box-inner.disabled-by-admin {
	border-top-color: #adadad;

	.category-icon-container {
		border-color: #adadad;
	}
	&.round-box > span {
		background-color: #ededed;
	}
	.category-icon,
	.background-color {
		background-color: #adadad;
	}
	.div.attribute-modeliteration-toggle i.fa-toggle-on {
		color: #adadad;
	}
	.attribute-modeliteration-toggle {
		&.show-tooltip {
			position: initial;

			&.top > .tooltip_ {
				bottom: calc(1.1em + 30px);
			}
		}
	}
}
.insights-content {
	.enrichment-wizard-container {
		&.container {
			margin: 0;
			padding: 12px 0px 12px 12px;
		}
		.categories .category .box-inner {
			text-align: left;

			h2 {
				margin: 0;
				text-align: left;

				.category-icon-container {
					display: none;
				}
			}

			.count {
				margin: 0;

				strong {
					font-weight: normal;
				}
			}
		}
	}
}
.wizard-content {
	.enrichment-wizard-container {
		.box-inner > span {
			border-left: 1px solid #d0d0d0;
			border-right: 1px solid #d0d0d0;
			border-bottom: 1px solid #d0d0d0;
		}
	}
}

.free-text-attribute {
	font-size: 12px;
	color: #adadad;
	font-weight: bold;
}

.success-message {
	background: #71bc51;
	margin: 20px 0;
	padding: 15px 20px;
	border-radius: 3px;
	width: 99%;
	h3 {
		color: #fff;
		font-size: 18px;
		display: inline-block;
		text-transform: none;
	}
	i {
		float: right;
	}
}

.warning-message {
	color: #fff;
	background-color: #ffa500;
	margin: 20px 0;
	padding: 15px 20px;
	border-radius: 3px;
	width: 99%;
	h3 {
		color: #fff;
		font-size: 18px;
		display: inline-block;
		text-transform: none;
	}
	i {
		float: right;
	}
}
