section.analysis-tabs.tabs.interior {
	ul {
		li {
			.enrichmentCounts {
				span:not(:first-child) {
					margin-left: 8px;
					padding-left: 10px;
					border-left: 1px solid #222;
					margin-right: 0px;
					padding-right: 0.1em;
					padding-top: 1px;
					line-height: 0.7em;
					display: inline-block;
				}

				span:first-child {
					margin: 0;
					padding: 0;
					border: none;
				}

				.countLabel {
					position: relative;
					bottom: 3px;
					margin-left: 4px;
				}
			}
		}
	}
}

.subNavBar {
	display: flex;
	margin: 24px;
	background-color: white;
	position: sticky;
	z-index: 10;
	top: 0;

	& > li {
		display: flex;
		align-items: center;
		padding: 0 12px;
	}

	.subNav {
		padding-left: 0;
	}

	.segmentTabs {
		display: flex;
		align-items: center;
		width: 100%;

		.MuiTabs-root {
			padding: 0;
		}
	}

	.back {
		padding-left: 0;
		padding-right: 0;
		color: #656769;
		font-weight: bold;
		cursor: pointer;

		i {
			margin-right: 10px;
		}
	}
}

.divider {
	padding: 0 16px;
	margin: 0 16px;
	display: flex;
	align-items: center;

	div {
		height: 24px;
		width: 1px;
		background: #ddd;
	}
}

.navItems {
	display: flex;

	.navItem {
		color: #656769;
		font-weight: bold;
		margin: 0 16px;
		padding: 18px 0;
		transition: none;
		white-space: nowrap;
		cursor: pointer;

		&[disabled] {
			pointer-events: none;
			opacity: 0.5;
		}

		&:first-child {
			margin-left: 0;
		}
	}

	.active {
		color: #0078a7;
		border-bottom: 2px solid rgba(0, 120, 167, 1);
		pointer-events: none;
	}

	.transitioning {
		pointer-events: none;
		border-bottom: 2px solid rgba(0, 120, 167, 0.5);
	}
}

.buttonsSegments {
	margin-left: auto;
	margin-right: 1.5em;
}

.segmentEditHeader {
	background-color: white;
	padding: 0 24px;

	.navgationBar {
		width: 900px;
		height: 40px;
	}

	.nameInput {
		padding-right: 30px;

		input {
			min-width: 820px;
		}
	}
}

.segmentEditHeaderJourneyX .navgationBar{
	width: 900px;
}

.tooltipSecondeRow {
	padding: 0 16px;
	margin: 70px 16px 0;
	display: flex;
	align-items: center;
}
