.query-results {
	min-width: 680px;
	margin-top: 1.5em;
	// padding: 0;
	h2 {
		background-image: url('/assets/images/ico-targets.png');
		margin: 0 0 20px;
		padding: 5px 0px 2px 40px;
		background-repeat: no-repeat;
		background-position: left;
	}
	.loading {
		background: url('/assets/images/spinners/spinner-lattice-dots-100x100.gif')
			no-repeat center top;
		padding-top: 115px;
		text-align: center;
		width: 100%;
		margin: 60px auto 0;
		font-size: 18px;
	}
	.checkbox-input {
		input[type='number'] {
			width: 80px;
			padding-right: 0;
		}
		p {
			display: block;
			padding-left: 22px;
		}
	}
	.buckets-about {
		margin-bottom: 10px;
		strong {
			font-weight: bold;
		}
	}
	.bucket-checkboxes li {
		// width: 19%; // replaced with flex-item
	}
	.unscored-accounts {
		margin-top: 10px;
		strong {
			font-weight: bold;
		}
		input {
			position: relative;
			top: 2px;
		}
	}
	.exclude-filter {
		margin-bottom: 10px;
	}
	.pd-pagination {
		float: right;
		display: inline-block;
		text-align: right;
	}
	.targets-filters {
		margin-bottom: 20px;
		.buckets-container {
			margin-top: 30px;
		}
	}
	.filters {
		@include flex-container;
		justify-content: center;
		margin-bottom: 20px;
		.query {
			@include flex-item(1 0 auto);
		}
		.buttons {
			@include flex-item(1 0 auto);
			text-align: right;
			margin: 0 8px;
			button {
				margin-left: 6px;
			}
		}

		&.pagination {
			min-height: 20px;
		}
	}

	table {
		.results-table-item-company,
		.results-table-item-website {
			cursor: pointer;
			color: #3989c4;

			&:active,
			&:active {
				text-decoration: underline;
				color: #0f72a0;
			}

			&:hover,
			&:hover {
				text-decoration: underline;
				color: #1f73b0;
			}
		}

		.sort:after {
			font-family: FontAwesome;
			text-anchor: middle;
			margin-left: 5px;
			position: absolute;
		}

		.sort-dsc .sort:after {
			content: '\f0d7';
		}

		.sort-asc .sort:after {
			content: '\f0d8';
		}
	}

	.recommendationCounts {
		@include flex-container;
		border: 1px solid #ddd;
		margin-bottom: 20px;
		& > li {
			@include flex-item(0 1 auto);
			padding: 10px;
			border-right: 1px solid #ddd;
			&:last-child {
				border-right: 0;
			}
			h3 {
				color: #999;
				font-size: 10px;
				font-weight: normal;
			}
		}
	}
	& > .error-message {
		display: flex;
		align-items: center;
		& > h4 {
			margin-left: 0.3em;
			font-size: 16px;
			color: red;
		}
	}
}

.query-builder {
	margin-top: 1.5em;

	hr {
		opacity: 0.25;
		margin: 24px 0;
		border-style: dashed;
	}

	.controls {
		margin-bottom: 10px;
		@include flex-container;
		justify-content: center;

		.buttons {
			@include flex-item(1 0 auto);
			text-align: right;
			margin: 0 8px;
			button {
				margin-left: 6px;
			}
		}
	}

	strong {
		font-weight: bold;
	}

	.row.filter-group.disabled {
		pointer-events: none;
	}
}

.query-filter {
	display: flex;
	flex-direction: row;
	position: relative;
	background: white;
	border: 1px solid #ccc;
	border-radius: 2px;
	font-weight: bold;
	margin: 1em 0;
	padding: 0.3em 1em;
	.category-firmographics {
		.query-lift-bg {
			background-color: $datacloud-color-firmographics;
		}
		.query-category-color {
			color: $datacloud-color-firmographics;
		}
	}
	.category-growth-trends {
		.query-lift-bg {
			background-color: $datacloud-color-growth-trends;
		}
		.query-category-color {
			color: $datacloud-color-growth-trends;
		}
	}
	.category-intent {
		.query-lift-bg {
			background-color: $datacloud-color-intent;
		}
		.query-category-color {
			color: $datacloud-color-intent;
		}
	}
	.category-online-presence {
		.query-lift-bg {
			background-color: $datacloud-color-online-presence;
		}
		.query-category-color {
			color: $datacloud-color-online-presence;
		}
	}
	.category-technology-profile {
		.query-lift-bg {
			background-color: $datacloud-color-hg-technology-profile;
		}
		.query-category-color {
			color: $datacloud-color-hg-technology-profile;
		}
	}
	.category-website-keywords {
		.query-lift-bg {
			background-color: $datacloud-color-website-keywords;
		}
		.query-category-color {
			color: $datacloud-color-website-keywords;
		}
	}
	.category-website-profile {
		.query-lift-bg {
			background-color: $datacloud-color-website-profile;
		}
		.query-category-color {
			color: $datacloud-color-website-profile;
		}
	}
	.category-product {
		.query-lift-bg {
			background-color: $datacloud-color-product;
		}
		.query-category-color {
			color: $datacloud-color-product;
		}
	}
	.category-contact {
		.query-lift-bg {
			background-color: $datacloud-color-contact;
		}
		.query-category-color {
			color: $datacloud-color-contact;
		}
	}
	.category-engagement {
		.query-lift-bg {
			background-color: $datacloud-color-engagement;
		}
		.query-category-color {
			color: $datacloud-color-engagement;
		}
	}
}

.query-filter-action {
	width: 60px;
	display: flex;
	align-items: center;
	justify-content: center;

	a {
		color: black;
	}
}

.query-filter-name {
	flex: 1 0 auto;
	max-width: 500px;
}

.query-filter-type {
	width: 15%;
	padding-top: 20px;
	padding-left: 2px;
	color: #888888;
}

.query-filter-buckets {
	flex: 0 0 auto;
	width: 40%;
	.query-lift-bg {
		display: inline-block;
		text-align: center;
		color: white;
		background: black;
		width: 42px;
		border-radius: 4px;
	}
}

.query-filter-value {
	width: 50%;
}
.query-filter-table {
	display: table;
	width: 100%;
}
.query-filter-row {
	display: table-row;
	height: 1.8em;
}
.query-filter-cell {
	display: table-cell;
	vertical-align: top;
	padding: 1px 4px;
}
.query-filter-header {
	text-transform: uppercase;
	color: #bbb;
	font-size: 12px;
	height: auto;
}

.query-filter-delete {
	color: black;
	text-align: right;
	a {
		color: black;
		text-decoration: none;
	}
}

.query-filter.query-any .fa.arrow:before {
	content: '\f062'; /* up */
}

.query-filter.query-all .fa.arrow:before {
	content: '\f063'; /* down */
}

.main-content section.target-tabs:last-child {
	margin-bottom: 0;
}
