.textWrapper {
	font-size: 12px;
}

.pointText {
	font-size: 14px;
	font-weight: 600;
}

.copyWrapper {
	max-width: 300px;
	vertical-align: bottom;
}
