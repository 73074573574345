@import 'common/node_modules/@dnb-uux-design-system/design-tokens/build/styles/_variables.scss';

.scrim {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background-color: #222831bb;
	z-index: $z-depth-modal;
	display: flex;
	align-items: center;
	justify-content: center;
}

.editButton {
	color: #457eba;
	background-color: transparent;
	border: none;
}

.scrim * {
	transition: none;
}

.editableInput {
	font: inherit;
	border: none;
	border-bottom: 1px dotted;
}

.modalBody {
	width: 65vw;
	background-color: white;
	border: 1px solid #d0d0d0;
	border-radius: 0.2em;
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

.warningBanner {
	font-size: 12px;
	width: fit-content;
}

.header {
	display: flex;
	padding: 14px 32px;
	align-items: center;
	justify-content: space-between;
}

.content {
	border: solid #f1f1f1;
	border-width: 1px 0;
	flex: 1;
}

.footer {
	padding: 1em 1em 0;
	display: flex;
	height: 84px;
	align-items: center;
	justify-content: flex-end;
}

.footer > button:first-child {
	margin-right: 5px;
}

.editor {
	height: 50vh;
	display: flex;
	align-items: stretch;
	justify-content: stretch;
}

.categories {
	border-right: 1px solid #f1f1f1;
	overflow-y: auto;
	flex: 1;
}

.categoryRow {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.category {
	border-left: 2px solid transparent;
}

.category button {
	appearance: none;
	border: 0;
	background: transparent;
	font: inherit;
	color: inherit;
	padding: 0.6em 2em;
	width: 100%;
	text-align: left;
}

.category.active {
	border-color: #f16a24;
	color: #f16a24;
}

.toggles {
	flex: 2;
	display: flex;
	flex-direction: column;
}

.searchBar {
	border-bottom: 1px solid #f1f1f1;
	padding: 0.5em 1em;
}

.togglesTree {
	position: relative;
	overflow-y: auto;
	font-size: 16px;
	padding: 1em 1em 0;
}

.togglesTree ul {
	margin-left: 1.2em;
}

.togglesTreeLabel, .toggleItemLabel {
	display: flex;
	align-items: center;
}

.columnCheckBox{
	display: flex;
  align-items: center;
}

.togglesTreeLabel > svg, .toggleItemLabel > svg {
	margin-left: 4px;
}

.searchInput {
	appearance: none;
	border: 1px solid #dfdfdf;
	width: 100%;
	padding: 0.4em 1em;
	font: inherit;
	color: inherit;
}

.order {
	background-color: #fafbfb;
	border-left: 1px solid #f1f1f1;
	overflow-y: auto;
	flex: 1;
	padding: 1em;
}

.selectedCount {
	margin-bottom: 1em;
}

.columnGroupName {
	text-transform: none;
	font-size: 1.2em;
	color: #666;
}

.columnGroup {
	padding: 0.4em 0;
}

.column {
	background-color: white;
	border: 1px solid #d0d0d0;
	border-radius: 0.3em;
	padding: 0.2em 0.8em;
	margin: 0.5em 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.columnDragHandle {
	color: #d0d1d0;
	margin-right: 0.8em;
	padding: 0 0.2em;
}

.columnDragHandle:focus {
	color: black;
	outline: 0;
}

.columnDragHandle.dragging {
	color: #629acc;
}

.columnDragHandle svg {
	vertical-align: middle;
}

.columnName {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.columnDisabled {
	opacity: 0.4;
}
