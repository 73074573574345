.triStateCheckbox {
	display: flex;
	flex-direction: row;
	line-height: 18px;
	align-items: center;

	img {
		margin-right: 16px;
		height: 16px;
	}
}
