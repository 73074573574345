$bgBlue: #e6f2e8;
$textBlue: #0a7f21;
$LastImportStatus: ('Importing', $bgBlue, $textBlue),
	('Partial', #f8ede5, #be4b00), ('Failed', #f8e6e6, #be0b0e),
	('Success', rgba(0, 81, 114, 0.1), #005172);
$ActivationStatus: ('Connected', #e6f2e8, #0a7f21),
	('Disconnected', $bgBlue, $textBlue);
$SystemTypeLabel: ('Auto', #efeaf1, #632a6e), ('UI', #e6f1eb, #007132),
	('S3', #e6eef1, #005172), ('Beta', #f5eaee, #9b3054),
	('File', #e3f2eb, #108342);
$PriorityStatus: ('P_1st', #d21e17, white), ('P_2nd', #f4bb14, black),
	('P_3rd', #57a11c, white);
$lists: (
	$LastImportStatus,
	$ActivationStatus,
	$SystemTypeLabel,
	$PriorityStatus
);
$FontSize: 12px;
$Bold: 600;

@each $list in $lists {
	@each $name, $bgColor, $textColor in $list {
		.ItemValueContainer.#{$name} {
			background: $bgColor;

			.ItemValue {
				color: $textColor;
			}
		}
	}
}

.P_1st {
	display: flex;
}

.TextColumnContainer {
	display: flex;

	> span {
		margin: 0 4px;
		&:first-child {
			margin-left: 0;
		}
		&:last-child {
			margin-right: 0;
		}
	}
}

.ItemValueContainer {
	justify-content: center;
	align-items: center;
	padding: 4px 8px;
	font-size: $FontSize;
	line-height: 16px;
	border-radius: 12px;
	background: white;
}

.textEllipsis,
.ItemValue {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
