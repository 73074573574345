.tabs-subheader-info {
	& > ul {
		padding-bottom: 0.4em;
	}

	& .tab-carousel {
		position: relative;

		// FIXME: These styles are duplicated everywhere they're used. le-carousel isn't used, so clean up its api and adopt it
		& .pd-pagination {
			& > .pd-pagination-start,
			& > .pd-pagination-center,
			& > .pd-pagination-end {
				display: none;
			}

			& > .pd-pagination-prev,
			& > .pd-pagination-next {
				position: absolute;
				top: 50%;
				border-radius: 50%;
				width: 31px;
				background-color: #fff;
				border-color: #ccc;
				color: #bbb;

				&:hover {
					border-color: #aaa;
					color: #999;
				}
			}

			& > .pd-pagination-prev {
				left: 0;
				transform: translate(-50%, -50%);
			}

			& > .pd-pagination-next {
				right: 0;
				transform: translate(50%, -50%);
			}
		}

		& li {
			height: unset;
		}
	}

	& .badge-spacer {
		height: 2em;
	}

	& .badge {
		background-color: rgba(223, 113, 64, 0.2);
		color: #ae5221;
		border-radius: 1em;
		padding: 0.2em 0.8em;
	}

	& .active .badge {
		background-color: rgba(223, 113, 64, 0.6);
		color: white;
	}
}
