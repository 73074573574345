div.le-banner {
	.blue-badge {
		cursor: pointer;
	}
	.long-word {
		word-wrap: break-word;
	}
	.messaging-message {
		width: 100%;
		max-height: 10.5em;
		overflow: auto;
		overflow-wrap: anywhere;
	}
}
