@import 'common/node_modules/@dnb-uux-design-system/design-tokens/build/styles/_variables';

$normalBorderColor: #d0d1d0;
$normalBorder: 2px solid $normalBorderColor;

.mask {
	background-color: rgba(34, 40, 49, 0.7);
	position: fixed;
	top: 0;
	left: 0;
	// set z-index lower than le-modal
	z-index: $z-depth-modal;
	width: 100vw;
	height: 100vh;
}

.modal {
	width: 580px;
	background-color: #fff;
	margin: 0 auto;
	transform: translateY(calc(50vh - 50%));
	border: $normalBorder;
	border-radius: 10px;

	&.info {
		border: none;
	}
}

.header {
	padding: 22px 26px;
	font-weight: 600;
	font-size: 18px;
	line-height: 100%;
	text-transform: capitalize;
	border-bottom: 1px solid $normalBorderColor;

	.info & {
		background-color: #689cca;
		border: 1px solid #689cca;
		border-radius: 8px 8px 0px 0px;
		color: #fff;
		font-weight: normal;
		&::before {
			content: '\f05a';
			font-size: 26px;
			font-family: FontAwesome;
			vertical-align: middle;
			margin-right: 19px;
		}
	}
}

.body {
	padding: 22px 27px;
	overflow: auto;
	.info & {
		border-left: $normalBorder;
		border-right: $normalBorder;
	}
}

.footer {
	padding: 16px 19px 17px;
	display: flex;
	justify-content: space-between;
	border-top: 1px solid $normalBorderColor;

	button {
		width: 102px;
		height: 30px;
		font-weight: 600;
		font-size: 12px;
		line-height: 100%;
		background-color: #fff;
		border: 1px solid #e0e0e0;
		border-radius: 3px;

		&.confirmButton {
			color: #fff;
			background: #457eba;
			border: 1px solid #457eba;
		}
	}

	.info & {
		border-left: $normalBorder;
		border-right: $normalBorder;
		border-bottom: $normalBorder;
		border-radius: 0 0 10px 10px;
	}
}

.closeButton {
	float: right;
	color: #fff;
	font-size: 32px;
}
