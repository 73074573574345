@import '../../../../common/node_modules/@dnb-uux-design-system/design-tokens/build/styles/_variables.scss';

.numericRange-date-wrap {
	.MuiFormControl-root.DNBTextField {
		width: 130px;
	}
	&.small {
		.MuiFormControl-root.DNBTextField {
			width: auto;
			.MuiIconButton-root {
				padding: $spacing-1;
			}
			.MuiInputBase-input {
				padding-right: $spacing-1;
			}
		}
	}
}

.numeric-range-container {
	display: flex;
	align-items: center;
	.numeric-range-input {
		width: 104px;
	}
	.numeric-range-slider {
		flex: 1;
		text-align: center;
	}
}

.quickTimeRangeWrap {
	display: flex;
	justify-content: center;
	outline: $border-thin $color-gray-secondary;
	border-radius: $border-radius-compact;
	button.quickTimeSelect {
		border: none;
		border-radius: $border-radius-compact 0 0 $border-radius-compact;
		border-right: $border-thin $color-gray-secondary;
	}
	.MuiFormControl-root.DNBTextField {
		width: 240px;
		min-width: unset;
		.DNBInputBase {
			box-shadow: none;
		}
	}
	.DNBTextField .MuiOutlinedInput-notchedOutline {
		border: none;
	}

	.numericRange-date-wrap {
		box-shadow: inset $shadow-minus-1;
		border-radius: 0 $border-radius-compact $border-radius-compact 0;
	}

	&.double {
		.MuiFormControl-root.DNBTextField {
			width: 130px;
		}
	}
}
