@import 'common/node_modules/@dnb-uux-design-system/design-tokens/build/styles/_variables.scss';

div.advanced-query-builder,div.advancedQueryBuilder {
	query-builder-list {
		width: 100%;
		.query-list-undo {
			text-decoration: unset;
			display: flex;
			color: #0078a7;
			align-items: normal;
		}
	}
	.query-list-display {
		width: 100%;
	}
	ul.query-list {
		display: inline;
		// vertical-align: text-top;

		li {
			display: inline;

			span {
				display: inline;
				cursor: pointer;
			}

			span.query-list-number-redesign {
				&>span {
					color: white;
					cursor: pointer;
				}

				&.ignored {
					color: $datacloud-color-ignored;
				}
				&>div:hover {
					box-shadow: 0px 4px 8px 0px rgba(22, 25, 25, 0.4);
				}
				&>div.active-link {
					box-shadow: 0px 4px 8px 0px rgba(22, 25, 25, 0.4);
				}
			}

			span.query-list-operator {
				text-transform: uppercase;
				font-weight: bold;
				display: inline-block;

				div {
					margin-right: 0.8rem;
				}
			}

			.query-section-operator-dropdown {
				display: inline-flex;
				&>button {
					display: inline-flex;
					border: none;
					box-shadow: none;
				}
				&>div li {
					display: flex;
				}
			}

			span.query-list-parenthesis {
				display: inline-block;
				padding-right: 0.8rem;
			}

			span.query-list-parenthesis-label {
				line-height: 1em;
				margin-top: 2px;
				display: inline;
				position: absolute;
				margin-left: 2px;
			}

			span.query-list-section-end .query-list-parenthesis-label {
				margin-left: -2px;
			}
		}

		li:last-child {
			&> div.query-list-operator {
				display: none;

				.query-list-operator-alternate {
					display: block;
					margin-right: 0;
					float: left;
				}
			}
			&> span.query-list-operator {
				display: none;
			}
		}

		&.query-list-root {
			.query-list {
				// border-top: 1px dashed #999;
				// margin-top: 2px;
				padding: 0 1px;
			}

			&.query-list,
			&.query-list > li > ul {
				border-top: none;
				margin-top: 0;
				padding: 0;
			}
		}

		&.query-list-root {
			> li {
				> .query-list-parenthesis,
				> span.query-list-operator-alternate {
					display: none;
				}
			}
		}
	}

	.query-list-type-and {
		& > span > div.item-operator-and {
			display: inline;
		}

		& > span > div.item-operator-or {
			display: none;
		}
	}

	.query-list-type-or {
		& > span > div.item-operator-and {
			display: none;
		}

		& > span > div.item-operator-or {
			display: inline;
		}
	}
}
