$max-height: 500px;
$slide-transition: 200ms;
$sidebar-background-color: #00344e;
@mixin logo-lattice {
	display: inline-block;
	width: 158px;
	height: 25px;
	background-repeat: no-repeat;
}

@mixin logo-img {
	img {
		width: auto;
		vertical-align: top;
		height: 26px;
	}
}

@import './mixins.scss';

#inner-wrap {
	@include flex-container;
	min-height: 100vh;
}

section.insights-content {
	@include flex-item(1 1 auto);
	position: relative;
}
section.main-content {
	@include flex-item(1 1 auto);
	@include transition(padding $slide-transition ease);
	position: relative;
	padding-left: 60px;
	@media screen and (max-height: $max-height) {
		padding-left: 60px;
	}
	&.no-sidebar {
		padding: 0;
	}
}
section.summary-blank-line {
	border-top: 1px solid #ccc;

	&,
	&:last-child {
		margin: 0;
		padding: 0;
	}
}

span.ico-container {
	display: inline-block;
	height: 36px;
	width: 36px;
	padding: 4px;
	border-radius: 50%;
	text-align: center;
	position: relative;
	animation-duration: 5s;
	&.page-icon {
		.ico {
			filter: invert(66%);
			&.ico-marketo {
				height: 15px;
				width: 23px;
			}
			&.ico-api-console {
				height: 16px;
			}
			&.ico-analysis {
				height: 21px;
				width: 18px;
			}
			&.ico-segments {
				height: 24px;
				width: 24px;
			}
			&.ico-model {
				height: 22px;
				width: 22px;
			}
			&.ico-attributes {
				height: 22px;
				width: 22px;
			}
			&.ico-eloqua {
				height: 22px;
				width: 22px;
			}
			&.ico-jobs {
				height: 24px;
				width: 24px;
			}
			&.ico-connectors {
				height: 24px;
				width: 24px;
			}
		}
	}
	.ssvi-ico {
		height: 33px !important;
		width: 198px !important;
		filter: none !important;
	}
	.ico {
		position: absolute;
		top: 50%;
		left: 50%;
		mask-repeat: no-repeat;
		-webkit-mask-repeat: no-repeat;
		transform: translateY(-50%) translateX(-50%);
		height: 24px;
		width: 24px;
		&.ico-marketo {
			height: 16px;
		}
		&.ico-user {
			height: 21px;
			width: 21px;
		}
		&.ico-api-console {
			height: 23px;
		}
		&.ico-salesforce {
			height: 40px;
			width: 45px;
		}
		&.ico-connectors {
			height: 24px;
			width: 24px;
		}
		&.ico-campaign {
			background-size: 21px;
		}
		&.ico-remodel-large {
			height: 26px;
			width: 24px;
		}
	}
}

li.transitioning {
	.ico-container {
		animation-name: transitioning;
		animation-duration: 2s;
		animation-iteration-count: infinite;
	}
}

@keyframes transitioning {
	0% {
		background-color: #555;
	}
	50% {
		background-color: #ef6a30;
	}
	100% {
		background-color: #555;
	}
}

.menu.model-view {
	.ico {
		height: 20px;
		width: 20px;
	}
}

nav.primary-nav {
	@include flex-item(0 1 auto);
	@include transition(width $slide-transition ease);
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	z-index: 20; // for tabs to hide left edge of first tab since this used to be position fixed
	min-height: 100%; // on page load make sure sidebar is 100% high
	width: 60px;
	min-width: 60px; // protection for IE
	@media screen and (max-height: $max-height) {
		//overflow: auto;
		//overflow-x: hidden;
		width: 60px;
	}
	*[disabled] {
		pointer-events: none;
		opacity: 0.5;
	}
	.lp-sidebar-menu,
	.pd-sidebar-menu {
		background: $sidebar-background-color;
		padding-left: 11px;
		padding-bottom: 75px;
		height: 100vh;
	}
	.pd-sidebar-menu {
		padding-top: 16px;
	}
	.lp-sidebar-menu {
		padding-top: 108px;
	}
	.label {
		display: inline-block;
		font-size: 12px;
		opacity: 0;
		position: relative;
		padding: 0 10px;
		vertical-align: middle;
		top: calc(-30px / 2);
		width: calc(100% - 50px);
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		letter-spacing: 1px;
		.badge {
			display: none;
		}
	}
	.open-button {
		background: rgba(255, 255, 255, 0.05);
		border: 0;
		border-top: 1px solid rgba(255, 255, 255, 0.05);
		width: 38px;
		text-align: center;
		font-size: 20px;
		padding: 0;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 100;
		overflow: hidden;
		height: 40px;
		width: 100%;
		&:after {
			content: 'collapse';
			font-family: 'ProximaNova-Regular', Arial, sans-serif;
			color: white;
			text-transform: uppercase;
			font-size: 12px;
			position: relative;
			top: -5px;
			opacity: 0;
			display: inline;
		}
		.ico-arrow-open {
			position: absolute;
			left: 4px;
			height: 50px;
			width: 50px;
			color: white;
			top: -4px;
		}
	}
	.logo-container {
		position: absolute;
		left: 0;
		right: 0;
		top: 20px;
		text-align: center;
		.logo {
			margin: 0 0 0 12px;
			overflow: hidden;
			width: 30px;
			opacity: 1;
			filter: unset;
			@include logo-img;
			.logo-lattice {
				@include logo-lattice;
				background-image: url(/assets/images/logo-dnb-lattice-nav-collapsed.svg);
			}
		}
	}
	.revup-abx-product-switcher {
		position: absolute;
		left: 4px;
		right: 4px;
		top: 64px;
		z-index: 1;
		color: white;
		cursor: pointer;
		background-color: $sidebar-background-color;

		.selected-product-label {
			display: flex;
			border-radius: 4px;
			justify-content: space-between;
			align-items: center;
			background-color: rgba(255, 255, 255, 0.1);
			padding: 8px 4px;
			white-space: nowrap;

			&.long {
				display: none;
			}
		}

		&.active {
			.product-switcher-options {
				margin-top: 4px;
				width: 400%;
				background-color: $sidebar-background-color;
			}
		}

		ul {
			display: none;
			background-color: rgba(255, 255, 255, 0.1);
			padding: 4px;
		}

		&.active ul {
			display: flex;
			flex-direction: column;
			width: 100%;
			border: none;
			list-style: none;

			li {
				button {
					background-color: inherit;
					width: 100%;
					color: white;
					border: none;
					cursor: pointer;
					text-align: left;
					padding: 8px 4px;

					&:hover {
						background-color: rgba(255, 255, 255, 0.2);
					}
				}
			}
		}
	}
	.menu {
		text-align: left;
		text-transform: uppercase;
		&:hover {
			color: #fff;
		}
		ul {
			display: none;
			margin: -12px 0 26px 47px;
			a {
				padding: 4px 0;
			}
		}
		li {
			white-space: nowrap;
		}
		a {
			display: inline-block;
			width: 37px;
		}
		li {
			& > a {
				font-size: 14px;
				color: #fff;
				opacity: 0.5;
			}
			&:hover > a {
				opacity: 1;
				transition: none;
				span:first-of-type {
					transition: none;
					span:not(.no-svg) {
						background-color: white;
					}
				}
				&.disabled {
					opacity: 0.5;
					span:first-of-type {
						cursor: default;
						transition: none;
						span:not(.no-svg) {
							&.ico-ratings {
								background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23 25'><g data-name='Layer 2'><g id='Content'><path fill='%235E5E5E' d='M13,11.05A4.95,4.95,0,1,0,18,16,5,5,0,0,0,13,11.05Zm2.13,8.22L13,17.73l-2.13,1.54.82-2.5L9.56,15.23h2.63l.81-2.5.81,2.5h2.63l-2.12,1.54Z'/><polygon fill='%235E5E5E' points='9 0 9 4 13 4 9 0'/><path fill='%235E5E5E' d='M13,9V5H8V0H0V16H6a7,7,0,0,1,7-7Z'/></g></g></svg>")
									no-repeat 4px 3px;
							}
						}
					}
				}
			}
			&.active > a span:first-of-type,
			&.active:hover > a span:first-of-type,
			&.activate > a span:first-of-type,
			&.activate:hover > a span:first-of-type {
				transition: all 250ms ease 1ms;
				span {
					background-color: white;
					&.ico-campaign {
						background-color: transparent;
					}
				}
			}
		}
		& > li > a span:first-of-type {
			font-size: 27px;
		}
		li.active > a {
			color: #fff;
			opacity: 1;
		}
		.fa,
		.ico {
			color: #fff;
			&.blue-text {
				color: $blue-text;
			}
		}
		.fa-circle-thin,
		.fa-check-circle-o {
			background-color: #222831;
		}

		&.progress-menu {
			li > a {
				position: relative;
			}
			ul {
				margin-top: 0;
				margin-left: 44px;
				span.badge {
					margin-top: 6px;
				}
			}
			li:after {
				display: table;
				content: '';
				clear: both;
			}
			.label {
				width: calc(100% - 80px);
				top: -10px;
			}
			li:hover .label {
			}
			span.badge {
				margin-top: 13px;
				margin-right: 10px;
				position: absolute;
				top: 0;
			}
			& > li.active > a span:first-of-type span,
			& > li.active:hover > a span:first-of-type {
				background: none;
			}
			li:hover > a span:first-of-type span:not(.no-svg) {
				background-color: inherit;
			}
			& > li > a span:first-of-type span {
				background: none;
				vertical-align: top;
			}
			& > li > a span:first-of-type .pd-sidebar-category-icon span {
				background: inherit;
			}
		}
	}
	.menu-header {
		padding: 10px 0;
		&:after {
			display: table;
			content: '';
			clear: both;
		}
		a.button.icon-button {
			float: left;
			background: #65696f;
			border-radius: 50%;
			height: 38px;
			width: 38px;
			padding: 4px;
			font-size: 27px;
			text-align: center;
			.fa {
				vertical-align: top;
				position: relative;
				left: -2px;
			}
		}
		h3 {
			@include text-ellipsis;
			width: calc(100% - 50px);
			color: #65696f;
			font-size: 12px;
			font-weight: normal;
			padding: 0 10px;
			margin-top: 11px;
			text-transform: uppercase;
		}
	}
}

html.no-cssmask {
	nav.primary-nav {
		.menu {
			& > li {
				&:hover > a span:first-of-type {
					.ico:not(.no-svg) {
						background-color: transparent;
					}
				}
			}
		}
	}
}

html:not(.no-cssmask) {
	nav.primary-nav {
		.ico:not(.no-svg) {
			background-color: #fff;
		}
		.ico.ico-campaign:not(.no-svg) {
			background-color: transparent;
		}
		.disabled {
			.ico:not(.no-svg) {
				&.ico-ratings {
					background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23 25'><g data-name='Layer 2'><g id='Content'><path fill='%235E5E5E' d='M13,11.05A4.95,4.95,0,1,0,18,16,5,5,0,0,0,13,11.05Zm2.13,8.22L13,17.73l-2.13,1.54.82-2.5L9.56,15.23h2.63l.81-2.5.81,2.5h2.63l-2.12,1.54Z'/><polygon fill='%235E5E5E' points='9 0 9 4 13 4 9 0'/><path fill='%235E5E5E' d='M13,9V5H8V0H0V16H6a7,7,0,0,1,7-7Z'/></g></g></svg>")
						no-repeat 4px 3px;
				}
			}
		}
	}
}

html.no-cssmask {
	.menu {
		& > li {
			&:hover > a span:first-of-type {
				span {
					background-color: transparent;
				}
			}
			&.active > a span:first-of-type,
			&.active:hover > a span:first-of-type {
				span {
					background-color: transparent;
				}
			}
		}
	}
	.secondary-nav {
		.page-icon {
			span {
				background-color: transparent;
			}
		}
	}
}

.open-nav {
	nav.primary-nav {
		width: 250px;
		.menu-header h3 {
			opacity: 1;
		}
		.label {
			opacity: 1;
			.badge {
				display: inline;
				position: absolute;
				right: 0.5em;
			}
		}
		.open-button {
			&:after {
				opacity: 0.5;
			}
			.ico-arrow-open {
				transform: rotateY(180deg);
			}
		}
		.menu {
			a {
				width: 100%;
			}
			ul {
				display: block;
			}
		}
		.logo-container {
			.logo {
				width: 100%;
				margin: 0 0 0 5px;
				overflow: hidden;
				opacity: 1;
				filter: unset;
				@include logo-img;
				.logo-lattice {
					@include logo-lattice;
					background-image: url(/assets/images/logo_revup_abx_nav_expanded.svg);
				}
			}
		}
		.revup-abx-product-switcher {
			left: 11px;
			right: 11px;

			&.active {
				.product-switcher-options {
					width: 100%;
				}
			}

			.selected-product-label {
				padding: 8px;

				&.short {
					display: none;
				}

				&.long {
					display: flex;
				}
			}
		}
	}
	section.main-content {
		padding-left: 250px;
		&.no-sidebar {
			padding-left: 0;
		}
	}
	.menu-header {
		h3 {
			display: inline-block;
		}
	}
}

//Link Navigation

.link-nav {
	display: inline-block;
	margin-top: 10px;
	li {
		display: inline-block;
		&.divider {
			padding: 0 10px;
		}
		a {
			color: $jet;
			&.active {
				color: $saphire;
			}
		}
	}
}

// Secondary Nav (Hamburger and Profile Nav)
html:not(.no-cssmask)
	.secondary-nav
	ul.secondary-nav-grid
	> li
	.jobs-nav-gear
	span.ico-container
	.ico {
	//background-color: #333;
}

.secondary-nav {
	& > .ico {
		height: 40px;
		width: 40px;
		margin: 10px;
	}
	button {
		float: left;
		padding: 10px;
		&:focus {
			outline: 0;
		}
	}

	ul.secondary-nav-grid {
		@include flex-container;
		height: 70px;

		& > li {
			@include flex-item(0 1 auto);
			position: relative;
			white-space: nowrap;
			display: flex;
			align-items: center;
			width: 100%;
			&:nth-last-child(2) {
				@include flex-item(1 1 auto);
			}
			&.header-back-name-container {
				flex-shrink: 3;
			}
			&.page-icon-container {
				width: 5em;
			}
			&.ssvi-header-container {
				width: 198px !important;
			}
			&.jobs-nav-container {
				width: 5em;
			}
			&.profile-nav-container {
				width: 250px;
			}
			.profile-user-icon,
			.jobs-nav-gear {
				float: right;
				height: 100%;
				position: relative;
				padding: 0em 0.8em;
				line-height: 0.8em;
				display: flex;
				align-items: center;

				span.ico-container {
					background: transparent;
					width: 36px;
					margin: 0;
				}

				&:hover {
					background-color: #f9f9f9;
				}

				&,
				span.ico-container,
				span.fa:before {
					-webkit-transition: none;
					transition: none;
				}

				&.active {
					background: $sidebar-background-color;

					span.ico-container {
						color: #fff;
					}

					.fa-chevron-down {
						color: #fff;
						transform: rotateX(180deg);
					}
				}

				span.jobs-nav-number {
					position: absolute;
					top: 3.5em;
					padding: 3px 6px 2px;
					font-size: 10px;
					right: 1.5em;

					&.empty {
						padding: 6px 6px;
					}
				}

				> span {
				}
			}
			.profile-user-icon {
				float: none;
				display: flex;
				align-items: center;
				justify-content: center;

				span.ico-container {
					margin-right: 0.8em;
					margin-left: 0.2em;
					min-width: 2.6em;
					color: #222;

					span {
						margin: 3px;
						font-size: 1.65em;
					}
				}
			}

			.upgradeButton {
				border: none;
				color: white;
				font-size: 16px;
				font-weight: bold;
				background-color: #0091cc;
				height: 33px;
				width: 116px;
				border-radius: 50px;
				padding: 0px 16px;
				margin: 0 8px;
				line-height: 33px;

				&:hover {
					background-color: darken(#0091cc, 10%);
				}
			}

			&.upgradePrompt {
				position: absolute;
				border: 1px solid #e8e8e8;
				border-radius: 8px;
				box-shadow: 3px 3px 3px #e8e8e8;
				top: 65px;
				background-color: white;
				padding: 16px;
				right: 100px;
				z-index: 99;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				width: 411px;
				height: 196px;
				justify-content: space-around;

				.upgradeOption {
					display: flex;
					width: 100%;

					.upgradeText {
						transition: all 0ms;
						flex-grow: 1;
						color: #b1b1b1;

						.upgradeTitle {
							transition: all 0ms;
							font-size: 24px;
							line-height: 29px;
							font-weight: 700;
						}
						.upgradeSubtitle {
							transition: all 0ms;
							font-size: 16px;
							font-weight: 700;
							line-height: 19px;
						}
					}

					.upgradeAddToCart {
						color: white;
						background-color: #0091cc;
						border-radius: 50px;
						padding: 4px 16px;
						font-weight: bold;
						visibility: hidden;
						line-height: 54px;
						font-size: 16px;

						&:hover {
							background-color: darken(#0091cc, 10%);
						}
					}

					&:hover {
						.upgradeText {
							color: #606060;
						}
						.upgradeAddToCart {
							visibility: visible;
						}
					}
				}
			}
		}
	}
	.page-icon,
	.page-name,
	.header-back-name,
	.model-name {
		display: inline-block;
		font-family: 'ProximaNova-Semibold', 'Open Sans', sans-serif;
		font-size: 15px;
		letter-spacing: 0px;
		vertical-align: middle;
		text-transform: uppercase;
		color: #555;
		//margin-top: 17px;
		margin-left: 1em;
		//padding-top: 6px;
		transition: none;
		animation: none;
	}
	&.model-details-page {
		.page-icon,
		.page-name,
		.header-back-name,
		.model-name {
			font-size: 15px;
		}
		.page-name {
		}
	}
	.page-icon {
		margin-left: 1.5em;
		span {
			-webkit-transition: all 250ms linear, transform 0s linear;
			-moz-transition: all 250ms linear, transform 0s linear;
			-ms-transition: all 250ms linear, transform 0s linear;
			transition: all 250ms linear, transform 0s linear;
			width: 20px;
			height: 20px;
			&.ico-insights,
			&.ico-targets,
			&.ico-playbook,
			&.ico-ssvi {
				height: 24px;
				width: 24px;
			}
		}
	}
	.ssvi-ico-container {
		margin-left: 48px !important;
		background-color: transparent !important;
		width: 200px !important;
	}
	.header-back-name,
	.model-name {
		@include text-ellipsis;
		position: relative;
		max-width: 500px;
		padding-right: 15px;
		border-right: 1px solid #ededed;
		padding-left: 25px;
		margin-left: 20px;
		font-size: 15px;
		&:before {
			@include font-awesome-inline('\f053');
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			margin-top: 1px;
		}
	}
	.header-tenant-name {
		padding: 20px 20px 20px 52px;
		display: inline-block;
		color: $dark-gray;
		background-image: url('/assets/images/ico-tenant.svg');
		background-size: 30px 30px;
		background-repeat: no-repeat;
		background-position: 12px 50%;
		&:hover {
			cursor: default;
		}
	}
	.profile-nav-container {
		div {
			height: 100%;
			line-height: 100%;
			vertical-align: middle;
		}
	}
	.profile-nav {
		width: 250px;
		z-index: 30;
		& > a {
			padding: 16px 20px 16px 20px;
			display: block;
			color: #555;

			&:hover,
			* {
				-webkit-transition: none;
				-moz-transition: none;
				-o-transition: none;
				transition: none;
			}
			&:after {
				content: '';
				display: table;
				clear: both;
			}
			&:hover {
				background-color: #f9f9f9;
				text-decoration: none;
			}
			&.active {
				background-color: $sidebar-background-color;
				color: #fff;
			}
			img,
			& > div,
			span {
				width: 11em;
				overflow: hidden;
			}
			img {
				width: 30px;
				height: 30px;
				margin: 4px 10px 0 0;
			}
			span {
				margin: 1em 0 0 1em;
				width: 1em;
			}
			span.ico-container,
			ul.profile-nav-info,
			span.fa-chevron-down {
				display: inline-block;
				width: 100%;
			}
			.fa-chevron-down {
				margin: 1em 0.2em 0 0.8em;
				max-width: 1em;
				color: #222;
				transition: transform 166ms ease-in;
			}
			.pd-header-username {
				font-family: 'ProximaNova-Semibold', 'Open Sans', sans-serif;
				font-size: 14px;
				text-overflow: ellipsis;
				overflow: hidden;
				max-width: 150px;
			}
			.tenant-name {
				font-family: 'ProximaNova-Semibold', 'Open Sans', sans-serif;
				font-size: 12px;
				margin-top: 0.25em;
				text-overflow: ellipsis;
				overflow: hidden;
				max-width: 150px;
			}
			.pd-header-downarrow {
				margin-left: 10px;
			}
		}
		ul.profile-nav-dropdown-menu {
			display: block;
			z-index: 20;
			padding-bottom: 1em;
			margin-top: 0;
			width: 100%;
			border: none;
			-webkit-box-shadow: none;
			-moz-box-shadow: none;
			box-shadow: none;
			background: $sidebar-background-color;
			& > li {
				text-align: left;
				border-bottom: none;

				&:hover {
					background: #11455f;
				}

				& > a {
					display: block;
					padding: 4px 28px;
					color: #fff;
					width: inherit;
					&:first-of-type {
						padding-top: 8px;
					}
					&:last-child {
						padding-bottom: 8px;
					}
					&:hover {
						text-decoration: none;
					}
				}
			}
		}
	}
	&:after {
		display: table;
		content: '';
		clear: both;
	}
}
.profile-nav.dropdown > a.open {
	background-image: url('/assets/images/ico-user-profile.svg');
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position: 12px 50%;
}
body.open-nav .primary-nav [data-stooltip] {
	&:hover {
		&:after {
			display: none;
		}
	}
}
.primary-nav [data-stooltip] {
	perspective: 500px;
	&:after {
		visibility: hidden;
		letter-spacing: 1px; /* makes it look more like sidebar nav */
		color: #fff;
		background: $sidebar-background-color;
		transform: rotateY(85deg); /* perspective makes 90deg too far */
		margin-top: -1.8em; /* this isn't great, but translatex(-50%) disables font smoothing/aliasing */
		margin-left: 13px;
		padding: 10px 15px 10px 5px;
		border-radius: 0px;
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;

		-webkit-transition: all 150ms ease-out 150ms, opacity 0ms linear 300ms;
		transition: all 150ms ease-out 150ms, opacity 0ms linear 300ms;

		-webkit-transform-origin: 0% 50%;
		transform-origin: 0% 50%;

		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;

		pointer-events: none;
		opacity: 0;
	}
	&:hover {
		&:after {
			visibility: visible;
			opacity: 1;

			-webkit-transition: none;
			transition: none;

			-webkit-transform: none;
			transform: none;
		}
	}
}
