@import 'common/node_modules/@dnb-uux-design-system/design-tokens/build/styles/_variables.scss';

.sidebar {
	width: 248px;
	height: 100%;
	ul li {
		&:global(.MuiListItem-root) {
			height: auto;
		}
		div:global(.MuiListItemText-root) {
			margin-top: 0px;
			margin-bottom: 0px;
		}
		span:global(.MuiTypography-caption) {
			font-size: 14px;
			line-height: 20px;
		}
	}
	& > :global(.MuiPaper-root.MuiDrawer-paper) {
		border-right: none;
		box-shadow: -1px 0px 0px 0px $color-gray-incidental inset;
	}
}

:global(#newSidebar) {
	box-shadow: -1px 0px 0px 0px $color-gray-incidental inset;
}
