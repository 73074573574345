@import '@dnb-uux-design-system/design-tokens/build/styles/_variables';

.modal-open {
	/*overflow: hidden;*/
}
.modal {
	display: none;
	overflow: hidden;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: $z-depth-modal + 1;
	-webkit-overflow-scrolling: touch;
	outline: 0;
}
.modal.fade .modal-dialog {
	-webkit-transform: scale(0.9, 0.9);
	-ms-transform: scale(0.9, 0.9);
	-o-transform: scale(0.9, 0.9);
	transform: scale(0.9, 0.9);
	-webkit-transition: all 0.15s ease;
	-o-transition: all 0.15s ease;
	transition: all 0.15s ease;
	opacity: 0;
}
.modal.in .modal-dialog {
	-webkit-transform: scale(1, 1);
	-ms-transform: scale(1, 1);
	-o-transform: scale(1, 1);
	transform: scale(1, 1);
	opacity: 1;
}
.modal-open .modal {
	overflow-x: hidden;
	overflow-y: auto;
}
.modal-dialog {
	position: relative;
	width: auto;
	margin: 10px;
	&[disabled] {
		.modal-content {
			.modal-header h4,
			.modal-body,
			.modal-footer {
				opacity: 0.5;
				pointer-events: none;
			}
		}
	}
}
.modal-content {
	position: relative;
	background-color: #ffffff;
	border: 1px solid #999999;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 6px;
	-webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
	box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
	outline: 0;
	overflow: hidden;
}
.modal-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: $z-depth-modal;
	background-color: #000000;
}
.modal-backdrop.fade {
	opacity: 0;
	filter: alpha(opacity=0);
}
.modal-backdrop.in {
	opacity: 0.5;
	filter: alpha(opacity=50);
}

.modal-header,
.modal-body,
.modal-footer {
	padding: 30px;
}
.modal-header,
.modal-footer {
	padding-top: 20px;
	padding-bottom: 20px;
}
.modal-header {
	background: #fff !important;
	border-bottom: 1px solid #e5e5e5;
}
.modal-header .close {
	margin-top: -2px;
	float: right;
	opacity: 0.8;
	font-size: 24px;
	border: none;
	background: transparent;
}
.modal-header .close:hover {
	opacity: 1;
}
.modal-title {
	margin: 0;
	line-height: 1.42857143;
}

.modal-body {
	position: relative;
}

.modal-footer {
	text-align: right;
	border-top: 1px solid #e5e5e5;
	.align-left {
		text-align: left;
	}
}
.modal-footer .btn + .btn {
	margin-left: 5px;
	margin-bottom: 0;
}
.modal-footer .btn-group .btn + .btn {
	margin-left: -1px;
}
.modal-footer .btn-block + .btn-block {
	margin-left: 0;
}
.modal-scrollbar-measure {
	position: absolute;
	top: -9999px;
	width: 50px;
	height: 50px;
	overflow: scroll;
}
@media (min-width: 768px) {
	.modal-dialog {
		width: 600px;
		margin: 10em auto;
	}
	.modal-content {
		-webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
		box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
	}
	.modal-sm {
		width: 300px;
	}
}
@media (min-width: 992px) {
	.modal-lg {
		width: 900px;
	}
}

#tenantSelectionInput {
	width: 100%;
}

/*
Tooltips
========================================== */
.tooltip {
	position: absolute;
	z-index: 1070;
	display: block;
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: normal;
	letter-spacing: normal;
	line-break: auto;
	line-height: 1.42857143;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	white-space: normal;
	word-break: normal;
	word-spacing: normal;
	word-wrap: normal;
	font-size: 12px;
	opacity: 0;
	filter: alpha(opacity=0);
}
.tooltip.in {
	opacity: 0.9;
	filter: alpha(opacity=90);
}
.tooltip.top {
	margin-top: -3px;
	padding: 5px 0;
}
.tooltip.right {
	margin-left: 3px;
	padding: 0 5px;
}
.tooltip.bottom {
	margin-top: 3px;
	padding: 5px 0;
}
.tooltip.left {
	margin-left: -3px;
	padding: 0 5px;
}
.tooltip-inner {
	max-width: 200px;
	padding: 3px 8px;
	color: #ffffff;
	text-align: center;
	background-color: #000000;
	border-radius: 4px;
}
.tooltip-arrow {
	position: absolute;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;
}
.tooltip.top .tooltip-arrow {
	bottom: 0;
	left: 50%;
	margin-left: -5px;
	border-width: 5px 5px 0;
	border-top-color: #000000;
}
.tooltip.top-left .tooltip-arrow {
	bottom: 0;
	right: 5px;
	margin-bottom: -5px;
	border-width: 5px 5px 0;
	border-top-color: #000000;
}
.tooltip.top-right .tooltip-arrow {
	bottom: 0;
	left: 5px;
	margin-bottom: -5px;
	border-width: 5px 5px 0;
	border-top-color: #000000;
}
.tooltip.right .tooltip-arrow {
	top: 50%;
	left: 0;
	margin-top: -5px;
	border-width: 5px 5px 5px 0;
	border-right-color: #000000;
}
.tooltip.left .tooltip-arrow {
	top: 50%;
	right: 0;
	margin-top: -5px;
	border-width: 5px 0 5px 5px;
	border-left-color: #000000;
}
.tooltip.bottom .tooltip-arrow {
	top: 0;
	left: 50%;
	margin-left: -5px;
	border-width: 0 5px 5px;
	border-bottom-color: #000000;
}
.tooltip.bottom-left .tooltip-arrow {
	top: 0;
	right: 5px;
	margin-top: -5px;
	border-width: 0 5px 5px;
	border-bottom-color: #000000;
}
.tooltip.bottom-right .tooltip-arrow {
	top: 0;
	left: 5px;
	margin-top: -5px;
	border-width: 0 5px 5px;
	border-bottom-color: #000000;
}

/*
Popovers
========================================== */
.popover {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1060;
	display: none;
	max-width: 276px;
	padding: 1px;
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: normal;
	letter-spacing: normal;
	line-break: auto;
	line-height: 1.42857143;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	white-space: normal;
	word-break: normal;
	word-spacing: normal;
	word-wrap: normal;
	font-size: 14px;
	background-color: #ffffff;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
	border: 1px solid #cccccc;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 6px;
	-webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.popover.top {
	margin-top: -10px;
}
.popover.right {
	margin-left: 10px;
}
.popover.bottom {
	margin-top: 10px;
}
.popover.left {
	margin-left: -10px;
}
.popover-title {
	margin: 0;
	padding: 8px 14px;
	font-size: 14px;
	background-color: #f7f7f7;
	border-bottom: 1px solid #ebebeb;
	border-radius: 5px 5px 0 0;
}
.popover-content {
	padding: 9px 14px;
}
.popover > .arrow,
.popover > .arrow:after {
	position: absolute;
	display: block;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;
}
.popover > .arrow {
	border-width: 11px;
}
.popover > .arrow:after {
	border-width: 10px;
	content: '';
}
.popover.top > .arrow {
	left: 50%;
	margin-left: -11px;
	border-bottom-width: 0;
	border-top-color: #999999;
	border-top-color: rgba(0, 0, 0, 0.25);
	bottom: -11px;
}
.popover.top > .arrow:after {
	content: ' ';
	bottom: 1px;
	margin-left: -10px;
	border-bottom-width: 0;
	border-top-color: #ffffff;
}
.popover.right > .arrow {
	top: 50%;
	left: -11px;
	margin-top: -11px;
	border-left-width: 0;
	border-right-color: #999999;
	border-right-color: rgba(0, 0, 0, 0.25);
}
.popover.right > .arrow:after {
	content: ' ';
	left: 1px;
	bottom: -10px;
	border-left-width: 0;
	border-right-color: #ffffff;
}
.popover.bottom > .arrow {
	left: 50%;
	margin-left: -11px;
	border-top-width: 0;
	border-bottom-color: #999999;
	border-bottom-color: rgba(0, 0, 0, 0.25);
	top: -11px;
}
.popover.bottom > .arrow:after {
	content: ' ';
	top: 1px;
	margin-left: -10px;
	border-top-width: 0;
	border-bottom-color: #ffffff;
}
.popover.left > .arrow {
	top: 50%;
	right: -11px;
	margin-top: -11px;
	border-right-width: 0;
	border-left-color: #999999;
	border-left-color: rgba(0, 0, 0, 0.25);
}
.popover.left > .arrow:after {
	content: ' ';
	right: 1px;
	border-right-width: 0;
	border-left-color: #ffffff;
	bottom: -10px;
}
