.logo-area {
	position: relative;
	display: flex;
	flex-direction: column;
	align-content: flex-end;
	flex-wrap: wrap;

	.logo-image {
		width: 211px;
		height: 64px;
		background-repeat: no-repeat;
		background-image: url(/assets/images/logo_login_revup_abx.svg);
		margin-bottom: 16px;
	}
	.logo-container {
		margin-left: -120px;
		margin-bottom: 8px;

		h2 {
			text-align: right;
		}
	}
}

@media only screen and (max-width: 480px) {
	.logo-area {
		align-content: center;
		.logo-container {
			margin-left: 0;

			h2 {
				text-align: left;
			}
		}
	}
}

@media only screen and(min-width: 481px) and (max-width: 700px) {
	.logo-area {
		.logo-container {
			margin-left: -150px;
		}
	}
}

.form-signin {
	h1 {
		font-size: 36px;
		margin-bottom: 10px;
	}
	.secondary-actions_ {
		margin-top: 20px;
	}
}

.user-info {
	text-align: center;
	right: 1em;
	white-space: nowrap;

	li {
		font-size: 90%;
		line-height: 15px;
		float: right;
		clear: both;

		.recent-link {
			position: relative;
			display: block;
			font-size: 0.95em;
			text-overflow: ellipsis;
			max-width: 100%;
			text-align: left;
			padding: 0.333em 0.2em;
			overflow: hidden;
			color: #222;
			-webkit-transition: none;
			transition: none;

			&:nth-child(odd) {
				background: #f9f9f9;
			}

			&:first-child {
				border-top: 1px solid #d0d0d0;
			}

			&:last-child {
				border-bottom: none;
			}

			&.default:hover {
				background: #65b7e5;
				color: white;
			}

			&.default:active {
				background: #3989c4;
				cursor: default;
			}

			.fa-spinner {
				float: left;
				width: 1.2em;
				margin-top: 1px;
				margin-left: 2px;
				margin-right: 4px;
				border-radius: 100%;
				border: none;

				&,
				&::before {
					transition: none;
				}
			}
		}

		&.label {
			font-weight: bold;
			font-size: 100%;
			width: 100%;
			text-align: left;

			.default {
				cursor: pointer;
				color: #999;
				font-weight: normal;
				font-size: 100%;
				position: absolute;
				right: 6px;
			}

			.default:hover,
			.default:hover {
				color: #000;
			}
		}

		&.value {
			margin-bottom: 1.4em;
			width: 100%;
		}

		&.control {
			margin-top: 2em;
		}
	}

	hr {
		margin: 2em 0;
	}

	button {
		&.white-button,
		&.blue-button {
			margin-top: 1em;
			display: inline-block;
			width: auto;
		}
	}
}
