.loginForm {
	margin-right: 22%;
}


@media only screen and (max-width: 800px) {
	.loginForm {
		margin-right: 0px;
	}
}
