/*
Simple Tabs
==================================== */
ul.tabs_ {
    @include flex-container;
    border-bottom: 1px solid #d0d0d0;
    font-weight: bold;
    margin-bottom: 15px;
    li {
        @include flex-item(0 1 auto);
        padding: 10px 20px;
        background: #f1f1f1;
        border: 1px solid #d0d0d0;
        position: relative;
        top: 1px;
        cursor: pointer;
        &:first-of-type {
            border-right: 0;
        }
        &.active {
            background: inherit;
            cursor: default;
            border-bottom: 1px solid #fafafa;
        }
        &.disabled {
          color: #b5b5b5;
          &:hover {
            cursor: default;
          }
        }
    }
}


/*
Toggle Switch Tabs
==================================================== */

.toggle-check {
    & > label:first-of-type {
        float: left;
        margin-right: 7px;
        margin-top: 3px;
    }
}
input.toggle-switch:empty
{
    margin-left: -9999px;
}

input.toggle-switch:empty ~ label
{
    position: relative;
    float: left;
    line-height: 1.6em;
    text-indent: 4em;
    margin: 0.2em 0;
    cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input.toggle-switch:empty ~ label:before,
input.toggle-switch:empty ~ label:after
{
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    content: ' ';
    width: 3.6em;
    background-color: #d0d0d0;
    border-radius: 20px;
    -webkit-transition: all 100ms ease-in;
    transition: all 100ms ease-in;
}

input.toggle-switch:empty ~ label:after
{
    width: 16px;
    height: 16px;
    top: 3px;
    bottom: 0.1em;
    margin-left: 3px;
    background-color: #000;
    border-radius: 100%;
}

input.toggle-switch:checked ~ label:after
{
    margin-left: 32px;
}



/*
Link Tabs
==================================================== */
.link-tabs {
  margin: 0;
  padding: 0;
  li {
    display: inline;
    &:after {
      content: " | ";
      color: #bbb;
    }
    &:last-of-type:after {
      content: "";
    }
    a {
      padding: 4px;
      font-size: 16px;
      &.selected {
        color: #333;
        font-family: 'ProximaNova-Bold','Open Sans',sans-serif;
      }
    }
  }
}

/*
Standard Navigation Tabs
================================================== */
section.tabs.navigation:last-child,
.tabs.navigation {
    position: relative;
    background: #E2E3E5;
    margin: 0;

    & > ul {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        border-left: 3px solid #FFF;
        display: flex;

        & > li {
            display: inline-block;
            cursor: pointer;
            border-right: 3px solid #FFF;

            & > a {
                display: inline-block;
                border-top: 3px solid transparent;
                padding: 1em 3em;
                font-size: 12px;
                color: #222;
            }

            &:hover > a {
                //background-color: #ffffff;
                border-top-color: #457EBA;
            }
            &.disabled {
                & > a {
                    color: #8e8e8e;
                }
                &:hover > a {
                    border-top-color: transparent;
                    background-color: #E2E3E5;
                }
            }

            &.active > a {
                background-color: #fafafa;
                border-top-color: #0078A7;
            }
        }
    }
}

/*
Chunky Interior Tabs (My Data, Play List, etc...)
================================================== */
.tabs,
.tabs * {
    -webkit-transition: none;
    transition: none;
}
react-angular-main-component .tabs.interior {
    margin-top: -1px;
}
.tabs.interior {
    @include flex-container;
    position: relative;
    z-index: 1;
    // &:hover {
    //     z-index: 6;
    // }
    background: #ecedee;
    margin-top: 0;
    margin-bottom: 0;
    &.stretched > ul > li {
            @include flex-item(1 1 auto);
            max-width: 100%;
    }
    &:not(.stretched) > ul > li > div {
        position: relative;
    }
    & > ul {
        @include flex-container;
        @include flex-item(1 1 auto);
        border-left: 3px solid #FFF;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        & > li {
            border-right: 3px solid #FFF;
            font-size: 9px;
            position: relative;
            height: 60px;
            cursor: pointer;
            .tab-container {
                margin: 0;
                padding: 11px 20px;
                border-top: 3px solid transparent;
                border-bottom: 1px solid transparent;
                height: calc(100% + 1px);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
                cursor: pointer;
            }
            &:last-child {
                border-right: 3px solid #FFF;
            }
            &:hover:not(:active):not(.active) {
                .tab-container {
                    border-bottom-color: #FFF;
                    z-index: 1;
                }
            }
            &.active {
                background: #FFF;
                pointer-events: none;
                .more-info {
                    pointer-events: all;
                    z-index: 999;
                }
                .tab-container {
                    border-top-color: #0078A7;
                    border-bottom-color: transparent;
                    z-index: 1;
                }
            }
            .count {
                color: #222;
                font-size: 15px;
            }
						.tabCounters {
							display: flex;
							flex-direction: row;
							justify-content: space-between;
						}
						.selectedFiltersContainer {
							color: white;
							background-color: #457eba;
							border-radius: 25px;
							width: 100px;
							text-transform: lowercase;
							display: flex;
							justify-content: center;
							align-items: center;
							font-size: 12px;
							cursor: default;
							.selectedFiltersText {
								margin-left: 5px;
							}
							.deleteSelectedFiltersButton {
								color: white;
								background-color: #457eba;
								border-style: none;
								pointer-events: all;
							}
						}
            .enrichmentCounts {
                    white-space: nowrap;
                    text-transform: uppercase;
                    color: #222;
                    margin-top: .7em;
                    line-height: 1;
                    .count {
                        margin-right: 5px;
                        &:not(:first-child) {
                            margin-left: 8px;
                            padding-left: 9px;
                            border-left: 1px solid #999;
                        }
                    }
                    .count-container {
                        display: inline-block;
                        &:not(:first-child) {
                            margin-left: 10px;
                            padding-left: 9px;
                            border-left: 1px solid #999;
                        }
                    }
                }
            & > .tab-container > h2 {
                position: relative;
                text-transform: uppercase;
                font-size: 11px;
                color: #222;

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    margin-top: -1px;
                    height: 20px;
                    width: 20px;
                    background-size: contain;
                }
            }

            & > .tab-container > h1 {
                position: relative;
                text-transform: none;
                font-size: 14px;
                color: #222;
                line-height: 2;

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    margin-top: -1px;
                    height: 20px;
                    width: 20px;
                    background-size: contain;
                }
            }

            &.disabled {
                cursor: default;
                .count, .enrichmentCounts, & > .tab-container > h2 {
                    color: #929292;
                }
                &:hover, &:active {
                    .tab-container {
                        cursor: default;
                        border-top-color: transparent;
                        border-bottom-color: transparent;
                    }
                }
            }
            &.tab-browse {
                .tab-container > h2 {
                    padding-left: 24px;
                    &:after {
                        height: 16px;
                        width: 16px;
                        background: url('/assets/images/enrichments/tabs/attribute.png') no-repeat center center;
                    }
                }
            }
            &.tab-edit {
                .tab-container > h2 {
                    padding-left: 24px;
                    &:after {
                        height: 16px;
                        width: 16px;
                        background: url('/assets/images/enrichments/tabs/accounts.png') no-repeat center center;
                    }
                }
            }
            &.tab-team {
                .tab-container > h2 {
                    padding-left: 24px;
                    &:after {
                        height: 16px;
                        width: 16px;
                        background: url('/assets/images/enrichments/tabs/contacts.png') no-repeat center center;
                    }
                }
            }
            &.tab-lookup {
                &.active {
                    pointer-events: none;
                }
                h2 {
                    position: relative;
                    top: 50%;
                    transform: translateY(-50%);
                    padding: 0;
                    text-align: center;
                }
            }
            h2, h3 {
                font-weight: normal;
            }
            h2 {
                font-size: 13px;
            }
            h3 {
                font-size: 18px;
                color: white;
            }
            .more-info {
                padding: 2px;
                text-align: center;
                width: 11px;
                height: 11px;
                position: relative;
                top: -1px;
                font-size: 8px;
                margin-left: 1em;
                text-transform: none;
                cursor: pointer;
                .tooltip_{
                    text-align: left;
                }
            }
        }
    }
    &.one-line {
        > ul > li {
            height: 47px;
            > div.tab-container > h2 {
                font-size: 12px;
                padding: 0.35em 1em;
                text-transform: none;
            }
        }
    }
}

.tabs-subheader-info {
    ul {
        display: flex;
        justify-content: flex-start;

        li:first-child {
            margin-left: 0;
        }

        li:last-child {
            margin-right: 0;
        }

        li {
            width: 25%;
            background: #d1d2d3;
            color: #222;
            padding: 1em 1.1em;
            margin: 0 .75em;
            height: 4.7em;
            cursor: pointer;
            &, & * {
                -webkit-transition: none;
                -moz-transition: none;
                transition: none;
            }

            &:hover {
                background-color: #333942;
                &, & * {
                    color: #ddd;
                }
            }

            &.active {
                background-color: #457eba;
                transition: none;
                pointer-events: none;
                &, & * {
                    color: #ddd;
                }
            }

            div {
                padding: 0;
                margin: 0;

                h2 {
                    text-transform: uppercase;
                    margin-bottom: 0.4em;
                }

                h2, div.enrichmentCounts {
                    font-size: 11px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }

                span.count {
                    font-size: 18px;
                    font-weight: normal;
                    padding-right: 0.1em;
                }
            }
        }
    }
}
