section#userManagementContainer {
	.user-management-list {
		position: relative;
		.section-actions {
			position: absolute;
			top: 0;
			right: 0;
		}
		.section-body {
			table {
				tbody {
					word-break: break-word;
					tr > td:last-child {
						@include media('<small') {
							position: relative;
							&:hover {
								.edit-icons {
									display: block;
								}
								.operate-icons-shortcut {
									color: #fff;
									background-color: #222;
								}
							}
						}
					}
				}
			}
		}
		.edit-icons {
			white-space: nowrap;
			@include media('<small') {
				display: none;
				right: 10px;
				position: absolute;
				background-color: #222;
				border-radius: 6px 0 0 6px;
				z-index: 1;
				padding-right: 24px;
				i.fa {
					color: #fff;
				}
			}
		}
		.operate-icons-shortcut {
			display: none;
			background-color: transparent;
			border: none;
			@include media('<small') {
				display: block;
				padding: 0 12px;
				border-radius: 4px 4px 0 0;
			}
		}
		.show-teams {
			position: relative;
			ul {
				display: none;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				bottom: 20px;
				background: #222831;
				font-size: 12px;
				padding: 5px 9px;
				line-height: 16px;
				z-index: 100;
				li {
					text-align: left;
					color: white;
					@include text-ellipsis;
				}
			}
			&:hover {
				ul {
					display: initial;
				}
			}
		}
	}
}
