@import "common/node_modules/@dnb-uux-design-system/design-tokens/build/styles/_variables.scss";
@import './ScheduleContentItem.module.scss';

.OptionContainer {
	display: flex;
	align-items: flex-start;
	margin-top: $marginTop;
	font-size: 12px;
	font-weight: normal;
}

.PriorityInfo {
	flex-direction: column;
	margin-top: 0;
}

div,
div:after,
div:before {
	transition: none;
}

.SelectContainer {
	display: flex;
	align-items: center;
	gap: $spacing-2;
}
