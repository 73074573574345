@import 'common/node_modules/@dnb-uux-design-system/design-tokens/build/styles/_variables.scss';

.toolbar {
	margin-bottom: 16px;
	display: flex;
	justify-content: space-between;
}

.filterBar {
	display: flex;
	align-items: center;
	gap: $spacing-4;
}

.tableContainer {
	width: 100%;
	div:global(.MuiDataGrid-footerContainer) {
		min-height: initial;
	}
}

body:has(.tableContainer) {
	overflow-y: hidden;
}
