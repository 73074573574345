$titleFontSize: 14px;
$titleFontWeight: 600;
$bodyFontSize: 12px;
$marginTop: 8px;

.ScheduleContentItem {
	> div {
		margin-bottom: 16px;
	}
}
