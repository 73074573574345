div.num-edit-row {
	display: flex;
	justify-content: flex-start;
}

.flex-container {
	// padding-top: 10px;
	display: flex;
	// border: 1px solid red;
	flex-wrap: wrap;
	&.isNewRedesign {
		flex-wrap: unset;
		gap: 16px;
		.query-section-item-value {
			padding-top: 6px;
		}
		&.model {
			flex-direction: column;
			justify-content: flex-start;
			gap: 8px;
			.query-section-item-operation {
				align-items: baseline;
			}
		}
	}
	&.time-frame {
		align-items: center;
	}
}
.flex-container-controller {
	display: flex;
	// border: 1px solid green;
	// padding: 10px 0;
	justify-content: space-around;
	align-items: baseline;
	height: 100%;
	width: 100%;

	& .set,
	& .unset {
		max-height: 30px;
	}
}

// @media only screen and (max-width: 1000px) {
//     .flex-container-controller{
//         flex-direction: column;
//         justify-content: center;

//     }
// }
