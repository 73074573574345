.selectWrapper {
	position: relative;
	display: flex;
	align-items: center;
}

.selectWrapper button {
	appearance: none;
	border: 0;
	font: inherit;
	color: inherit;
	background: transparent;
	text-align: left;
}

.selectDropdown {
	position: absolute;
	top: 100%;
	right: 0;
	z-index: 2;
	background-color: #222831;
	border-radius: 0 0 0.3em 0.3em;
	color: white;
	padding: 1em 1em 0;
	width: 15em;
	overflow-y: auto;
	max-height: calc(100vh - 20em);
	transition: 0;
}

.selectDropdownSearch {
	appearance: none;
	width: 100%;
	border: 1px solid white;
	border-radius: 0.2em;
	background-color: transparent;
	font: inherit;
	color: inherit;
	padding: 0.2em 0.8em;
	margin-bottom: 1em;
}

.selectOption {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1em;
}

.textEllipsis {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

