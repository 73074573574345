$columns-gap: 8px;
$header-height: 50px;
$row-height: 50px;
$max-span: 12;
$cell-padding:8px;
$font-size:12px;
@mixin display-grid {
    /*Defines the dyplay grid for IE11*/
    display: -ms-grid;
    /*Defines the dyplay grid for the other browsers*/
    display: grid;
}

@mixin columns-grid {
    /*Defines the columns for IE11*/
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    /*Defines the columns for the other browsers*/
    grid-template-columns: repeat($max-span, minmax(10px, 1fr));
    // border: 2px solid red;
}

.dark-row {
    background: #F9F9F9;
}
.white-row{
    background-color: white;
}

.le-grid {
    @include display-grid;
    @include columns-grid;
    font-size: $font-size;
    // background: rgba(128, 128, 128, 0.261);
    height: 100%;
    
    .le-header{
        border-top: 1px solid rgba(197, 197, 197, 0.631);
        border-bottom: 1px solid rgba(197, 197, 197, 0.631);
        border-radius: 3px;
        background-color: white;
        font-weight: bold;
    }

    .le-row {
        @include display-grid;
        @include columns-grid;
        // background-color: white;
        -ms-grid-column: 1;
        -ms-grid-column-span: 12;
        grid-column: span 12;
        // border: 1px solid red;
        min-height: $row-height;
    }
    .le-grid:nth-child(even){
        background: rgb(249, 246, 246);
    }
   
    .le-col-start-1 {
        -ms-grid-column: 1;
    }
    .le-col-start-2 {
        -ms-grid-column: 2;
    }
    .le-col-start-3 {
        -ms-grid-column: 3;
    }
    .le-col-start-4 {
        -ms-grid-column: 4;
    }
    .le-col-start-5 {
        -ms-grid-column: 5;
    }
    .le-col-start-6 {
        -ms-grid-column: 6;
    }
    .le-col-start-7 {
        -ms-grid-column: 7;
    }
    .le-col-start-8 {
        -ms-grid-column: 8;
    }
    .le-col-start-9 {
        -ms-grid-column: 9;
    }
    .le-col-start-10 {
        -ms-grid-column: 10;
    }
    .le-col-start-11 {
        -ms-grid-column: 11;
    }
    .le-col-start-12 {
        -ms-grid-column: 12;
    }
    .le-col-span-1 {
        -ms-grid-column-span: 1;
        grid-column: span 1;
    }
    .le-col-span-2 {
        -ms-grid-column-span: 2;
        grid-column: span 2;
    }
    .le-col-span-3 {
        -ms-grid-column-span: 3;
        grid-column: span 3;
    }
    .le-col-span-4 {
        -ms-grid-column-span: 4;
        grid-column: span 4;
    }
    .le-col-span-5 {
        -ms-grid-column-span: 5;
        grid-column: span 5;
    }
    .le-col-span-6 {
        -ms-grid-column-span: 6;
        grid-column: span 6;
    }
    .le-col-span-7 {
        -ms-grid-column-span: 7;
        grid-column: span 7;
    }
    .le-col-span-8 {
        -ms-grid-column-span: 8;
        grid-column: span 8;
    }
    .le-col-span-9 {
        -ms-grid-column-span: 9;
        grid-column: span 9;
    }
    .le-col-span-10 {
        -ms-grid-column-span: 10;
        grid-column: span 10;
    }
    .le-col-span-11 {
        -ms-grid-column-span: 11;
        grid-column: span 11;
    }
    .le-col-span-12 {
        -ms-grid-column-span: 12;
        grid-column: span 12;
    }

    .le-cell {
        text-overflow: ellipsis;
        overflow: hidden; 
        height: $row-height;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: $cell-padding;
        padding-right: $cell-padding;
    }
    .le-cell-centered{
        justify-content: space-around;
    }
    .le-cell-right-align {
        justify-content: flex-end;
    }
}