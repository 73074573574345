.barChart {
	& > .bars {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		height: 120px;
		& > li {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			flex-direction: column;
			height: 100%;
			width: 15%;
			margin: 0 2px;
			text-align: center;
			& > div {
				flex: 0 0 1;
				&:nth-child(1),
				&:nth-child(3) {
					height: 22px;
				}
			}
			.bar {
				background-color: rgba(0, 0, 0, 0.5);
				width: 100%;
				min-height: 2px;
			}
			&.bucket-aplus {
				.value {
					color: #70bf4a;
				}
				.bar {
					background-color: #70bf4a;
				}
			}
			&.bucket-a {
				.value {
					color: #70bf4a;
				}
				.bar {
					background-color: #70bf4a;
				}
			}
			&.bucket-b {
				.value {
					color: #33bdb7;
				}
				.bar {
					background-color: #33bdb7;
				}
			}
			&.bucket-c {
				.value {
					color: #cd80bc;
				}
				.bar {
					background-color: #cd80bc;
				}
			}
			&.bucket-d {
				.value {
					color: #8e71b2;
				}
				.bar {
					background-color: #8e71b2;
				}
			}
			&.bucket-e {
				.value {
					color: #457eba;
				}
				.bar {
					background-color: #457eba;
				}
			}
			&.bucket-f {
				.value {
					color: #9c9c9c;
				}
				.bar {
					background-color: #9c9c9c;
				}
			}
		}
	}
}

.inactive {
	.barChart {
		& > .bars > li {
			.value {
				color: #bbb;
			}
			.bar {
				border-bottom-color: #ddd;
				background-color: #ddd;
			}
		}
	}
}

.attribute-stats {
	& > div {
		position: relative;
		width: 100%;
		height: 100%;
	}
	.row-container {
		position: relative;
		width: 100%;
		height: 100%;

		.bkt-item {
			display: flex;
			align-items: center;
			background-color: transparent;
			border: none;
			width: 100%;
			color: var(--category-color);
            cursor: default;

			.flex-graph-container {
				height: 20px;
			}

			.add-icon {
				display: none;
			}
		}

		.mouse-over {
			display: none;
			height: 100%;
			width: 100%;
			position: absolute;
			right: 0;
			top: 0;
			border: none;
			opacity: 0.5;
			color: white;
		}

		&:hover {
			.mouse-over {
				display: block;
			}

			.add-icon {
				display: block;
			}
		}

		.nostats-container {
			text-align: center;
			padding: 6px;
			font-size: 12px;
			color: #80808078;
			text-transform: uppercase;
		}
	}
	.row-container:nth-child(even) {
		background-color: #eff3f4;
	}
	.flex-row {
		display: flex;
		flex-wrap: nowrap;
		padding: 5px;
		.flex-item {
			text-align: center;
			width: 100px;
		}
		.flex-item-left {
			text-align: left;
		}
		.flex-item-right {
			text-align: right;
		}
		.flex-graph-container {
			flex-grow: 3;

			.bar-graph {
				height: 100%;
			}
		}
		.flex-value {
			max-width: 50px;
		}
		.flex-lable {
			max-width: 60px;
			margin-right: 5px;
		}
		.flex-actions {
			max-width: 16px;
		}
	}
	.header {
		padding-bottom: 0px;
		padding-top: 0px;
		background-color: white;
		color: #80808078;
		font-size: 10px;
		text-transform: uppercase;
	}
	.content-item {
		font-size: 12px;
		text-transform: uppercase;
	}

	.vlines-container {
		position: absolute;
		pointer-events: none;
		height: 100%;
		width: 100%;
		top: 18px;
		.vline {
			width: 1px;
			height: 100%;
			position: absolute;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			& > div {
				flex: 1;
				border: 1px solid #d0d1d0;
			}
			& > span {
				font-size: 10px;
				margin-left: -5px;
			}
		}
		.flex-row {
			height: 100%;
			align-items: stretch;
			padding: 0px;
			.flex-graph-container {
				margin-right: 5px;
			}
		}
		.flex-item {
			position: relative;
		}
	}
}
