.wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
}

.pageSize {
	margin-right: 40px;
	width: auto;
}

.page {
	margin-right: 32px;
	width: auto;
}

.input {
	border: 1px solid lightgrey;
	border-radius: 6px;
	padding: 2px 10px;
	height: 26px;
	max-width: 6em;
	margin: 1px;
	font: inherit;
	color: inherit;
}

.input:focus {
	border: 2px solid #30749f;
}

.button {
	display: block;
	width: 44px;
	height: 35px;
	appearance: none;
	border: none;
	border-radius: 50%;
	text-align: center;
	background-color: transparent;
	transition: background-color 0.15s ease-in-out;
	font-size: 20px;
	line-height: 1;
	padding: 0;
	color: #656769;
}

.button:disabled {
	color: #c2c3c3;
}

.button:hover {
	background-color: #efeff0;
}

.button:focus {
	border: 2px solid #30749f;
}
