.results-page {
	padding: 0 24px;
	transition: none;
	width: calc(100vw - 30px);
}

.open-nav .results-page {
	width: calc(100vw - 300px);
}

.ShowFilter.results-page {
	width: calc(100vw - 360px);
}
