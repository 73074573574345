.dropdown {
	position: relative;

	> ul {
		display: none;
		z-index: 2;
		background-color: $pearl-white;
		text-align: left;
		position:absolute;
		right: 0px;
		border: $cobalt solid 1px;
		-webkit-box-shadow: 0px 2px 1px -2px $onyx;
		-moz-box-shadow:    0px 2px 1px -2px $onyx;
		box-shadow:         0px 2px 1px -2px $onyx;
		li span,
		li a {
			display: block;
			padding: 4px 12px;
			width: 150px;
		}
	}
	> a.active {
		background-color: $palladium;
	}
}

.select-menu {
	position: relative;
	display: inline-block;
	vertical-align: top;
	// &.ng-search {
	//     z-index: 11;
	// }
	button {
		vertical-align: top;
		padding: 0em 1em;

		span.label-icon {
			margin-right: 0.4em;
			margin-left: 0 !important;
			padding-left: 0;
			vertical-align: middle;
		}

		&.select-label {
			border-top-right-radius: 1px;
			border-bottom-right-radius: 1px;
			margin: 0;
			padding-right: 1em;
			-webkit-transition: none;
			transition: none;
			animation: none;

			&.open {
				border-bottom-left-radius: 0;
				background-color: #222;
				color: #fff;
				border-color: #222;
				-webkit-transition: none;
				transition: none;
				animation: none;
			}

			span {
				margin-left: 0.75em;
			}
		}

		&.select-more {
			border-top-left-radius: 1px;
			border-bottom-left-radius: 1px;
			margin-left: 1px;
			margin-right: 0;
			margin-top: 0;
			margin-bottom: 0;
			&.open {
				background-color: #222;
				color: #fff;
				border-color: #222;
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
	}

	ul {
		border-radius: 6px;
		border-top-right-radius: 1px;
		border-top-left-radius: 1px;
		margin-top: 1px;
		cursor: pointer;

		li {

		}
	}

	&.white-select-menu.sort-select {
		button,
		button span,
		button span:after,
		button span:before {
			animation: none;
			transition: none;
		}
		button {
			border-radius: 0;
			border-color: #636567;

			&.select-label {
				border-top-left-radius: 6px;
				border-bottom-left-radius: 6px;
				border-right: none;

				&.open {
					background-color: #222;
					border-color: #222;
					border-bottom-color: #222;
					color: #fff;
					border-bottom-left-radius: 0px;
				}
			}

			&.select-more {
				border-top-right-radius: 6px;
				border-bottom-right-radius: 6px;
				margin-left: 0;
				margin-top: 0;
				margin-bottom: 0;
				&.open {
					background-color: #222;
					border-color: #222;
					border-bottom-color: #222;
					border-left-color: #fff;
					color: #fff;
					border-bottom-right-radius: 0px;
				}
			}
		}

		ul {
			border-top-left-radius: 0;
			left: 0;
			right: auto; // for IE
			right: initial;
			border-left: 1px solid #222;

			li {
				@include flex-container;
				text-align: left;
				width: 100%;
				margin: 0;
				span, a {
					@include flex-item(1 1 auto);
					display: inline-block;
				}
				i {
					@include flex-item(0 1 auto);
					margin-top: 5px;
					margin-right: 0;
					margin-left: 0;
				}
				&.show-tooltip.top>.tooltip_.small {
					bottom: calc(1.5em + 5px);
				}
			}
		}

		&.hover-leave-sort-select {
			button.select-more {
				&.open {
					border-left: none;
				}
			}
			.model-menu {
				margin-top: 0px;
			}
		}
	}
	&.white-select-menu {
		button.select-label {
			min-width: 100px;
			text-align: left;
		}
		ul.model-menu {
			min-width: 150px;
		}
	}

	&.white-select-menu.filter-select {
		button,
		button span,
		button span:after,
		button span:before {
			animation: none;
			transition: none;
		}

		&:hover button {
			//background-color: #f1f1f1;
		}

		button.select-label {
			border-right: none;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			margin-right: 0;

			&.open {
				background-color: #222;
				color: #fff;
				border-color: #222;
				border-bottom-left-radius: 0;
			}
		}

		button.select-more {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			margin-left: 0;
			margin-top: 0;
			margin-bottom: 0;
			&.open {
				background-color: #222;
				color: #fff;
				border-color: #222;
				border-left-color: #fff;
				border-bottom-left-radius: 0;
			}
		}

		span {
			margin-right: 0em;
			margin-left: 0em;
			&.badge {
				margin-left: 10px;
			}
		}

		ul {
			border-top-left-radius: 0;
			left: 0;
			right: auto; // for IE
			right: initial;
			border-left: 1px solid #222;

			li {
				@include flex-container;
				display: flex;
				text-align: left;
				width: 100%;
				margin: 0;
				align-items: baseline;
				//   padding-top: 0;
				justify-content: space-between;

				span, a {
					@include flex-item(1 1 auto);
					display: inline-block;
				}

				i {
					@include flex-item(0 1 auto);
				}

				span {
					@include flex-item(0 1 auto);
					display: inline-block;
					//   margin-top: 3px;
					//   padding: 5px 7px;
					min-width: 3em;
				}
			}
		}
	}
	&.disabled,
	&[disabled] {
		pointer-events: none;
		opacity: .5;
	}
}

.model-menu {
	position: absolute;
	right: 0;
	z-index: 200;
	background: #222;
	padding: 10px 0;
	font-size: 16px;
	-webkit-border-bottom-left-radius: 6px;
	-moz-border-bottom-left-radius: 6px;
	border-bottom-left-radius: 6px;
	-webkit-border-top-left-radius: 6px;
	-moz-border-top-left-radius: 6px;
	border-top-left-radius: 6px;
	font-family:'ProximaNova-Regular','Open Sans',sans-serif;
	li {
		list-style: none;
		padding: 0.3em 1em;
		text-align: right;
		color: #eee;
		white-space: nowrap;
		font-size: 12px;
		&, & span {
			transition: none;
		}
		&:hover {
			background: #000;
			span:not(.badge) {
				color: #457EBA;
				font-weight: bold;
			}
			span.badge {
				font-weight: normal;
			}
		}
		i {
			color: #999;
			margin-left: .5em;
			width: 1em;
			text-align: center;
			vertical-align: middle;
			&.ico-ratings {
				background-size: 11px;
				opacity: 0.5;
			}
		}
		&:last-of-type {
			margin-bottom: 0;
		}
		&.disabled,
		&[disabled] {
			opacity: .5;
			pointer-events: none;
			&:hover {
				background: transparent;
				color: inherit;
			}
		}
	}
	a:link, a:visited {
		color: #fff;
	}

	&.scroll-height {
		max-height: 410px;
		overflow-y: scroll;
	}
}

.le-fixed-tile-menu {
	.toggle-button {
		height: 2.3em;
		width: 3em;
		font-size: 14px;
		background: transparent;
		border: none;
		font: inherit;
		&.open {
			background: #222;
			color: #fff;
			border-radius: 4px;
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
}

.fixed-tile-menu.model-menu {
	position: relative;
	li {
		padding: 0.1em 1em;
		button {
			width: auto;
			color: inherit;
		}
	}
	.fa.fa-ellipsis-h {
		font-size: 150%;
	}
	.edit {
		position: inherit;
	}
	.disabled {
		pointer-events: none;
		opacity: 0.25;
	}
	button {
		background: transparent;
		border: none;
		font: inherit;
	}
}

.fixed-menu {
	&.react-tooltip {
		padding: 0;
		border: none !important;
	}
	&.__react_component_tooltip.show {
		opacity: 1;
	}
	&.place-top {
		margin-top: 5px !important;
	}
	&.place-bottom {
		margin-top: 0px !important;
	}
}
