#ExplorerTabsView {
	@include flex-container;
	position: relative;
	z-index: 3;
	border-top: 1px solid #d0d1d0;
	border-bottom: 1px solid #d0d1d0;
	background: #efefef;
	margin-top: 0;
	margin-bottom: 2em;
	& > ul {
		@include flex-container;
		@include flex-item(1 1 auto);
		border-left: 1px solid #d0d1d0;
		max-width: 1200px;
		margin: 0 auto;
		width: 100%; /* for IE */
		& > li {
			@include flex-item(1 1 auto);
			border-right: 1px solid #d0d1d0;
			font-size: 9px;
			position: relative;
			height: 73px;
			min-width: 237px;
			max-width: 320px;
			cursor: pointer;
			.tab-container {
				margin: 0;
				padding: 14px 20px;
				border-top: 5px solid transparent;
				border-bottom: 1px solid transparent;
				height: calc(100% + 1px);
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 2;
				cursor: pointer;
			}
			&:last-child {
				border-right: 1px solid #d0d1d0;
			}
			&.active {
				background: #fafafa;
				.tab-container {
					border-top-color: orange;
					border-bottom-color: transparent;
					background: #fafafa;
					z-index: 1;
					//cursor: default;
				}
			}
			.count {
				color: #26649b;
				font-size: 18px;
			}
			&.tab-edit {
				min-width: 315px;
				.enrichmentCounts {
					white-space: nowrap;
					text-transform: uppercase;
					.count {
						margin-right: 5px;
						&:not(:first-child) {
							margin-left: 5px;
							padding-left: 5px;
							border-left: 1px solid #d0d1d0;
						}
					}
				}
			}
			& > .tab-container > h2 {
				position: relative;
				padding-left: 28px;
				&:after {
					content: '';
					display: block;
					position: absolute;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
					margin-top: -1px;
					height: 20px;
					width: 20px;
					background-size: contain;
				}
			}
			&.tab-browse {
				.tab-container > h2 {
					padding-left: 24px;
					&:after {
						height: 13px;
						width: 17px;
						background: url('/assets/images/enrichments/tabs/lattice-data-cloud-attributes.png')
							no-repeat center center;
					}
				}
			}
			&.tab-edit {
				.tab-container > h2 {
					padding-left: 24px;
					&:after {
						height: 16px;
						width: 17px;
						background: url('/assets/images/enrichments/tabs/enrichment-icon.png')
							no-repeat center center;
					}
				}
			}
			&.tab-team {
				.tab-container > h2 {
					padding-left: 28px;
					&:after {
						height: 20px;
						width: 20px;
						background: url('/assets/images/ico-salesforce-black.png') no-repeat
							center center;
					}
				}
			}
			&.tab-lookup {
				&.active {
					pointer-events: none;
				}
				h2 {
					position: relative;
					top: 50%;
					transform: translateY(-50%);
					padding: 0;
					text-align: center;
				}
			}
			h2,
			h3 {
				font-weight: normal;
			}
			h2 {
				font-size: 14px;
			}
			h3 {
				font-size: 18px;
				color: white;
			}
			.more-info {
				padding: 0;
				text-align: center;
				width: 12px;
				height: 12px;
				position: realtive;
				top: -1px;
				.tooltip_ {
					text-align: left;
					h3 {
						margin-bottom: 25px;
					}
				}
			}
		}
	}
}
