@import './TextColumnItem.module.scss';
$IconSize: 22px;

.NameColumnItem {
	display: flex;
	align-items: center;
	overflow: hidden;
}

.SystemType {
	width: $IconSize;
	height: $IconSize;
}
