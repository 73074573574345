@import './header/header.component.scss';
@import './subheader/subheader.component.scss';
@import './category/category.component.scss';
@import './list/list.component.scss';
@import './activate/activate.component.scss';
@import './enable/enable.component.scss';
@import './edit/edit.component.scss';
@import './edit/filters/edit-filters.component.scss';
@import './edit/list/edit-list.component.scss';
@import './groups/groups.component.scss';
@import './sidebar/sidebar.component.scss';

.attr-list {
	margin-top: 1.5em;

	@mixin box-shadow($r, $g, $b) {
		box-shadow: 0px 0px 11px 2px rgba($r, $g, $b, 0.33);
	}

	li.attr-back-text,
	li.attr-text {
		position: relative;
		top: 0.1em;
		font-size: 16px;
		margin-right: 0.75em;
	}

	li.attr-back-text {
		font-size: 15px;

		span {
			padding-right: 0.75em;
			margin-right: 0.75em;
			cursor: pointer;
			border-right: 1px solid #222;
			margin-left: -0.35em;

			&:hover {
				text-decoration: underline;
			}

			i.fa-arrow-left {
				margin-top: 0.45em;
				margin-left: 0.5em;
				margin-right: 0.5em;
				font-size: 0.9em;
				vertical-align: top;
			}
		}
	}

	.controls,
	.filters {
		display: block;
		height: 2.2em;

		> * {
			display: inline-block;
		}

		.attr-text-category {
			font-size: 20px;
		}

		.attr-button-selected-text {
			font-size: 10px;
		}

		.attr-tiny-uppercase {
			text-transform: uppercase;
			font-size: 9px;
			position: relative;
			bottom: 0;
		}
	}

	.attr-pagination-top {
		height: 3.2em;
		text-align: right;
		.attr-pagination {
			display: inline-block;
		}
	}

	.attr-pagination-bottom {
		height: 2.2em;
		margin-top: 1em;
	}

	.controls-container {
		margin: 1em 0;
		display: flex;
		attr-filters {
			flex: 1 1 auto;
		}
	}
}

// still used on the Attributes Groups tab
.filters {
	.dropdown-container {
		position: relative;
		margin: 0 4px;

		&.open button {
			background: #1a1a1a;
			color: #fafafa;
			border-bottom-right-radius: 0;
		}

		&:not(.open) button.selected {
			background-color: #457eba;
			border: 1px solid #457eba;
			color: #eee;
		}

		ul {
			position: absolute;
			bottom: 0;
			right: 1px;
			transform: translateY(100%);
			background: #1a1a1a;
			color: #fafafa;
			white-space: nowrap;
			padding: 10px 5px;
			border-radius: 4px;
			border-top-right-radius: 0;
			display: none;
			z-index: 2;

			li {
				padding: 0.3em 1em 0.4em 0.6em;

				input {
					position: relative;
					top: 2px;
				}

				&.disabled {
					label {
						color: #aaa;
					}
				}
			}
		}

		&.open ul.dropdown {
			display: inline-block;
		}
	}
}
