@import 'common/node_modules/@dnb-uux-design-system/design-tokens/build/styles/_variables.scss';

.ActionButton {
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	background-color: transparent;
	width: 36px;
	height: 36px;
	border-radius: 50%;
}

.ActionButtonPressed {
	vertical-align: middle;
	background-color: $color-gray-secondary-alpha-2;
}

div.MenuBody.AddWeight {
	font-size: 12px;
	line-height: initial;
	padding: 20px 12px;
	border-radius: 0;
	width: 210px;
	opacity: 1;
}
