.explorer-tile {
	color: var(--category-color);
	border-color: var(--category-color) !important;

	&:hover,
	&.active-glow.active {
		box-shadow: 0px 0px 20px -5px var(--category-color);
	}

	& .category-icon-container {
		border-color: var(--category-color);

		& .category-icon {
			-webkit-mask-size: 24px !important;
			mask-size: 24px !important;
		}
	}

	& .background-color {
		background-color: var(--category-color);
	}

	& li.attribute .column.checkspace i {
		color: var(--category-color);
	}
	& li.attribute:not(.no-edit):hover {
		background-color: var(--category-color) !important;
	}
}
