.ScheduleDailyComponent {
	> div {
		display: flex;
		align-items: center;

		&:first-child {
			margin-bottom: 8px;
		}

		> * {
			margin: 0 3px;
			&:first-child {
				margin-left: 0;
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}
}
