/*
Base
========================================================= */
$header-color: $onyx;

@font-face {
	font-family: "ProximaNova-Regular";
	src: url("../../lib/fonts/ProximaNova-Regular-webfont.eot"); /* IE9 Compat Modes */
	src: url("../../lib/fonts/ProximaNova-Regular-webfont.eot?#iefix")
			format("embedded-opentype"),
		/* IE6-IE8 */ url("../../lib/fonts/ProximaNova-Regular-webfont.woff2")
			format("woff2"),
		/* Super Modern Browsers */
			url("../../lib/fonts/ProximaNova-Regular-webfont.woff")
			format("woff"),
		/* Pretty Modern Browsers */
			url("../../lib/fonts/ProximaNova-Regular-webfont.ttf")
			format("truetype"),
		/* Safari, Android, iOS */
			url("../../lib/fonts/ProximaNova-Regular-webfont.svg#svgFontName")
			format("svg"); /* Legacy iOS */
}
@font-face {
	font-family: "ProximaNova-RegularItalic";
	src: url("../../lib/fonts/ProximaNova-RegularItalic-webfont.eot"); /* IE9 Compat Modes */
	src: url("../../lib/fonts/ProximaNova-RegularItalic-webfont.eot?#iefix")
			format("embedded-opentype"),
		/* IE6-IE8 */
			url("../../lib/fonts/ProximaNova-RegularItalic-webfont.woff2")
			format("woff2"),
		/* Super Modern Browsers */
			url("../../lib/fonts/ProximaNova-RegularItalic-webfont.woff")
			format("woff"),
		/* Pretty Modern Browsers */
			url("../../lib/fonts/ProximaNova-RegularItalic-webfont.ttf")
			format("truetype"),
		/* Safari, Android, iOS */
			url("../../lib/fonts/ProximaNova-RegularItalic-webfont.svg#svgFontName")
			format("svg"); /* Legacy iOS */
}
@font-face {
	font-family: "ProximaNova-Semibold";
	src: url("../../lib/fonts/ProximaNova-Semibold-webfont.eot"); /* IE9 Compat Modes */
	src: url("../../lib/fonts/ProximaNova-Semibold-webfont.eot?#iefix")
			format("embedded-opentype"),
		/* IE6-IE8 */ url("../../lib/fonts/ProximaNova-Semibold-webfont.woff2")
			format("woff2"),
		/* Super Modern Browsers */
			url("../../lib/fonts/ProximaNova-Semibold-webfont.woff")
			format("woff"),
		/* Pretty Modern Browsers */
			url("../../lib/fonts/ProximaNova-Semibold-webfont.ttf")
			format("truetype"),
		/* Safari, Android, iOS */
			url("../../lib/fonts/ProximaNova-Semibold-webfont.svg#svgFontName")
			format("svg"); /* Legacy iOS */
}
@font-face {
	font-family: "ProximaNova-SemiboldItalic";
	src: url("../../lib/fonts/ProximaNova-SemiboldItalic-webfont.eot"); /* IE9 Compat Modes */
	src: url("../../lib/fonts/ProximaNova-SemiboldItalic-webfont.eot?#iefix")
			format("embedded-opentype"),
		/* IE6-IE8 */
			url("../../lib/fonts/ProximaNova-SemiboldItalic-webfont.woff2")
			format("woff2"),
		/* Super Modern Browsers */
			url("../../lib/fonts/ProximaNova-SemiboldItalic-webfont.woff")
			format("woff"),
		/* Pretty Modern Browsers */
			url("../../lib/fonts/ProximaNova-SemiboldItalic-webfont.ttf")
			format("truetype"),
		/* Safari, Android, iOS */
			url("../../lib/fonts/ProximaNova-SemiboldItalic-webfont.svg#svgFontName")
			format("svg"); /* Legacy iOS */
}
@font-face {
	font-family: "ProximaNova-Bold";
	src: url("../../lib/fonts/ProximaNova-Bold-webfont.eot"); /* IE9 Compat Modes */
	src: url("../../lib/fonts/ProximaNova-Bold-webfont.eot?#iefix")
			format("embedded-opentype"),
		/* IE6-IE8 */ url("../../lib/fonts/ProximaNova-Bold-webfont.woff2")
			format("woff2"),
		/* Super Modern Browsers */
			url("../../lib/fonts/ProximaNova-Bold-webfont.woff") format("woff"),
		/* Pretty Modern Browsers */
			url("../../lib/fonts/ProximaNova-Bold-webfont.ttf")
			format("truetype"),
		/* Safari, Android, iOS */
			url("../../lib/fonts/ProximaNova-Bold-webfont.svg#svgFontName")
			format("svg"); /* Legacy iOS */
}
html {
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	height: 100%;
}
body {
	color: $color-gray-primary;
	font-size: 14px !important;
	font-family: $font-family-sans-serif;
	font-weight: normal !important;
	line-height: 1.6 !important;
	letter-spacing: normal !important;
}

/*
Headers
========================================================= */
h1,
h2 {
	line-height: 1.1;
}
h3,
h4 {
	line-height: 1.3;
}
h1 {
	font-size: 150%;
	@include media(">small") {
		font-size: 200%;
	}
	@include media(">medium") {
		font-size: 250%;
	}
	@include media(">large") {
		font-size: 300%;
	}
}
h2 {
	font-size: 120%;
	@include media(">small") {
		font-size: 150%;
	}
	@include media(">medium") {
		font-size: 200%;
	}
	@include media(">large") {
		font-size: 300%;
	}
}
h3 {
	font-size: 110%;
	@include media(">small") {
		font-size: 120%;
	}
	@include media(">medium") {
		font-size: 150%;
	}
	@include media(">large") {
		font-size: 180%;
	}
}
h4 {
	font-size: 180%;
}
h5 {
	font-size: 130%;
}

h2 {
	font-size: 1.6em;
}
.h3-sub {
	font-size: 1.1em;
	font-style: italic;
	color: #aaa;
	text-transform: none;
}

/*
Body Copy
========================================================= */
.smaller {
	font-size: 13px;
}
.smallest {
	font-size: 10px;
}
.italic {
	font-style: italic;
}
.bold {
	font-weight: 700;
}
.block-margins {
	margin: 1em 0;
}
.blue-text {
	color: $blue-text;
}
.orange-text {
	color: $orange-text;
}
.gray-text {
	color: #909090;
}
.green-text {
	color: $jade;
}
.success-text {
	color: $jade;
}
.error-text,
.red-text {
	color: $ruby;
}
.text-centered,
.text-center {
	text-align: center;
}
.text-right {
	text-align: right;
}
.text-left {
	text-align: left;
}

.disabled-text {
	color: #909090;
	.orange-text,
	.blue-text {
		color: #909090;
	}
}

.text-ellipsis {
	word-wrap: break-word;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

/*
Text Selection Styles
====================================================== */
::selection {
	background: #1fb5ad;
	color: #fff;
}
::-moz-selection {
	background: #1fb5ad;
	color: #fff;
}

/*
Links
========================================================= */
a {
	text-decoration: none;
	pointer: hand;
	cursor: pointer;
	transition: none;
	-webkit-transition: none;

	&,
	&:link,
	&:visited {
		color: $saphire-three;
	}
	&:hover {
		color: $saphire-two;
	}
	&:active {
		color: $saphire;
	}
}
a.secondary {
	&:link,
	&:visited {
		color: $jet;
	}
	&:hover {
		color: $saphire-two;
	}
	&:active {
		color: $saphire;
	}
}
a.tertiary {
	&:link,
	&:visited {
		color: $cobalt;
	}
	&:hover {
		color: $saphire-two;
	}
	&:active {
		color: $saphire;
	}
}
a.emphasized {
	&:link,
	&:visited {
		color: $amber;
	}
	&:hover {
		color: $amber-two;
	}
	&:active {
		color: $amber-three;
	}
}

/*
Badges
========================================================= */
.badge {
	display: inline-block;
	min-width: 10px;
	padding: 3px 10px;
	font-size: 12px;
	line-height: 1;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	background-color: #e7e7e7;
	color: #222;
	&.gray-badge {
		color: #222;
		background-color: #e7e7e7;
	}
	&.darkgray-badge {
		background-color: #555;
		color: #fff;
	}
	&.green-badge {
		background-color: #71bc51;
		color: #fff;
	}
	&.olive-badge {
		background-color: #129e4e;
		color: #fff;
	}
	&.blue-badge {
		background-color: #3989c4;
		color: #fff;
	}
	&.orange-badge {
		background-color: #ef6a30;
		color: #fff;
	}
	&.gold-badge {
		background-color: #ef9d18;
		color: #fff;
	}
	&.purple-badge {
		background-color: #9f6fe2;
		color: #fff;
	}
}
