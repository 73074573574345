.filter-date-range {
	&,
	& ul {
		display: block;
		width: 100%;
	}

	.date-range-label {
		display: inline-block;
		font-weight: bold;
		margin-bottom: 0.5em;
	}

	.date-range-container {
		display: flex;
		align-items: center;

		.date-range-input {
			width: 100%;

			> div {
				border: 1px solid #ccc;
				height: 2em;
				background: white;
				color: #666;
				padding: 0.15em 0 0 0.5em;
				cursor: pointer;

				i {
					color: #999;
					margin-top: 0.3em;
					margin-right: 0.5em;
				}

				.date-range-calendar {
					position: fixed;
					z-index: 1;
					transform: translate(40%, -50.3%);
					box-shadow: 2px 2px 4px 1px #eee;
					border-radius: 8px;
					border: none;
					background: transparent;

					&,
					& * {
						transition: none;
					}

					div.filter-date-range-pikaday {
						border-radius: 8px;

						table.pika-table {
							margin: 0;

							button.pika-button {
								text-align: center;
							}
						}
					}

					.calendar-close {
						position: absolute;
						top: 50%;
						left: -1.8em;
						font-size: 10pt;
						z-index: 10000;
						padding: 4px 5px 6px;
						background: white;
						width: 1.8em;

						&,
						& * {
							transition: none;
						}

						&:hover {
							color: #ef6a30;
						}
					}
				}
			}
		}

		.date-range-string {
			font-weight: bold;

			> div {
				cursor: pointer;
				display: flex;

				i {
					margin-top: 0.3em;
					margin-right: 0.5em;
				}
			}
		}

		.date-range-separator {
			width: 5em;
			text-align: center;
		}
	}
}
