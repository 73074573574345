.snackbar {
	position: fixed;
	bottom: 32px;
	transform: translateX(-50%);
	left: 50%;
	white-space: nowrap;
	max-width: 100%;
	overflow: auto;
	text-overflow: ellipsis;
	padding: 4px 16px;
	box-shadow: 0px 4px 8px rgba(34, 40, 49, 0.4);
	border-radius: 8px;
	// set z-index the same as nav
	z-index: 20;
}

.success {
	background-color: #f1f9ee;
	border: 1px solid #75bf51;
	&::before {
		content: '\f058';
		font-family: FontAwesome;
		color: #75bf51;
		margin-right: 10px;
	}
}

.info {
	background-color: #f0f5fa;
	border: 1px solid #689cca;
	&::before {
		content: '\f05a';
		font-family: FontAwesome;
		color: #689cca;
		margin-right: 10px;
	}
}

.warn {
	background-color: rgba(250, 193, 102, 0.1);
	border: 1px solid #fac166;
	&::before {
		content: '\f06a';
		font-family: FontAwesome;
		color: #fac166;
		margin-right: 10px;
	}
}

.error {
	background-color: rgba(201, 0, 50, 0.1);
	border: 1px solid #c90032;
	&::before {
		content: '\f071';
		font-family: FontAwesome;
		color: #c90032;
		margin-right: 10px;
	}
}
