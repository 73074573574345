.le_modal {
	display: none;
	position: fixed;
	z-index: 33;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	overflow: auto;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1000;

	.le-orange-style {
		background-color: orange;
	}
	/* Modal Content Box */
	.le-modal-content {
		position: fixed;
		top: 50vh;
		left: 50vw;
		max-height: 80vh;
		transform: translate(-50%, -50%);
		font-family: 'ProximaNova-Regular', Arial, sans-serif;
		display: flex;
		flex-direction: column;
		background-color: #fefefe;
		border-radius: 5px;
		border-radius: 6px;

		&.sm {
			width: 420px;
		}
		&.md {
			width: 640px;
		}
		&.lg {
			width: 860px;
		}
		.le-modal-header {
			display: flex;
			flex-direction: row-reverse;
			align-items: center;
			border-top-left-radius: 6px;
			border-top-right-radius: 6px;
			min-height: 4em;

			/* The Close Button */
			.close {
				font-size: 28px;
				font-weight: bold;
				padding: 2px;
				padding-right: 1em;
				&:hover,
				&:focus {
					color: #666;
					text-decoration: none;
					cursor: pointer;
					-webkit-transition: none;
					transition: none;
				}
			}
			.le-title-container {
				font-size: 20px;
				width: 90%;
				margin: 0 auto;
				margin-left: 1.4em;
				display: flex;
				align-items: center;

				.ico-container {
					background-color: white;
				}
				.title-icon {
					// padding-right: 10px;
				}
				.le-title {
					font-size: 16pt;
					width: 100%;
					overflow: hidden;
					text-overflow: ellipsis;
					height: 1.6em;
					white-space: nowrap;
				}
			}
		}
		.le-modal-body {
			border-top: 1px solid rgba(153, 152, 152, 0.405);
			border-bottom: 1px solid rgba(153, 152, 152, 0.405);
			overflow: auto;
			padding: 10px;
			padding: 2em 2em;

			p {
				margin-bottom: 1em;
			}

			ul {
				list-style: disc;
				margin-left: 1.2em;
				margin-bottom: 1em;

				&:last-child {
					margin-bottom: 0;
				}

				li {
					word-break: break-all;
					.tooltip_ {
						word-break: normal;
					}
				}
			}

			b,
			bold {
				font-weight: bold;
			}

			br {
				display: none;
			}

			.le-modal-message {
				display: flex;
				font-size: 26px;
				font-family: 'ProximaNova-Regular', Arial, sans-serif;
			}

			.le-modal-spinner {
				display: flex;
				justify-content: center;
				height: 100%;
				padding: 20px;
			}
		}
		.le-modal-footer {
			display: flex;
			flex-direction: row-reverse;
			align-items: center;
			justify-content: center;
			padding: 0.75em 2em;
			min-height: 6em;
			gap: 1em;

			// TODO: Once the DNBButton fullWidth gets fixed delete this.
			> span {
				width: 100%;
			}

			> button {
				width: 50%;
				padding: 1em 0;

				&.white-button:hover {
					border-color: #457eba;
				}

				&.white-button:active {
					border-color: #999;
				}
			}
		}
	}

	&.opened:last-child {
		display: block;
		opacity: 1;
		//animation-name: fadeInOpacity;
		//animation-iteration-count: 1;
		//animation-timing-function: ease-in;
		//animation-duration: .12s;
	}

	&.closed {
		display: none;
	}

	strong {
		font-weight: bold;
	}

	@keyframes fadeInOpacity {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
}
