@import 'common/node_modules/@dnb-uux-design-system/design-tokens/build/styles/_variables.scss';

.Placeholder {
	height: 36px;
	margin: 4px 0;
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	border-radius: 8px;
	background: $color-background-white;
	color: $color-gray-secondary;
	border: 1px dashed $color-gray-secondary;
}
