.attributeOverviewTable {
	border: none;
	color: #fff;
	margin: 0;
	font-size: 10px;
	& tbody {
		border: none;
	}
	& tr {
		border: none;
		// avoid bg-color by _tables.scss
		background-color: initial !important;
	}
}

.title {
	font-weight: 600;
	font-size: 16px;
	margin-left: 10px;
}
