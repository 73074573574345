@import './tree/edit/QueryTreeItemEdit.module.scss';

div.advancedQueryBuilder {
	position: relative;

	strong {
		font-weight: bold;
	}

	.parent-segment-container {
		margin-bottom: 2.4rem;
		font-size: 1.6rem;
		font-weight: 700;
		>.parent-segment-button {
			font-size: inherit;
			font-weight: 550;
			margin-top: 1.6rem;
			color: #0078a7;
			border-color: #0078a7;
		}
	}

	.query-section-item-value {
		strong {
			max-width: 15em;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			display: inline-block;
			vertical-align: bottom;
		}
	}
	.dateAttribute,.transactionAttribute {
		.query-section-item-value {
			strong {
				max-width: unset;
			}
		}
	}

	ul.queryTreeRoot
		> li
		div
		> ul
		> li:not(.querySectionItemBoxActivity)
		div
		> ul
		> li:not(.querySectionItemBoxActivity)
		div
		> ul
		> li.querySectionItemEnd
		span.addOperator {
		display: none;
	}

	ul.queryTreeRoot
		> li
		div
		> ul
		> li.querySectionItemBoxActivity
		div
		> ul
		> li
		div
		> ul
		> li
		div
		> ul
		> li.querySectionItemEnd
		span.addOperator {
		display: none;
	}

	ul.queryTreeRoot
		> li
		div
		> ul
		> li
		div
		> ul
		> li.querySectionItemBoxActivity
		div
		> ul
		> li
		div
		> ul
		> li.querySectionItemEnd
		span.addOperator {
		display: none;
	}

	ul.queryTreeRoot
		> li.querySectionItemBoxSubtree
		div
		> ul
		> li.querySectionItemBoxSubtree
		div
		> ul
		> li.querySectionItemEnd
		span.addEventBlock {
		display: none;
	}

	ul.queryTreeRoot
		> li.querySectionItemBoxSubtree
		div
		> ul
		> li.querySectionItemBoxSubtree
		div
		> ul
		> li.querySectionItemBoxSubtree
		div
		> ul
		> li.querySectionItemEnd
		span.addEventBlock {
		display: none;
	}

	ul.queryTreeRoot
		> li.querySectionItemBoxSubtree
		div
		> ul
		> li.querySectionItemEnd
		span.addEventBlock {
			display: inline;
		}

	ul.queryTreeRoot
		> li.querySectionItemBoxSubtree
		div
		> ul
		> li.querySectionItemBoxSubtree
		div
		> ul
		> li.querySectionItemEnd
		span.addEventBlock {
			display: none;
		}

	ul.queryTreeRoot
		> li.querySectionItemBoxSubtree
		div
		> ul
		> li.querySectionItemBoxSubtree
		div
		> ul
		> li.querySectionItemBoxSubtree
		div
		> ul
		> li.querySectionItemEnd
		span.addEventBlock {
			display: none;
		}

	span.addOperator {
		button.AddIcon.Activity {
			display: none;
		}
	}

	div.query-rules-header {
		div.query-rules-header-infotext {
			margin-top: 0.5em;
		}

		div.query-rules-header-defaultbucket {
			margin: 0.5em 0;

			select {
				margin-left: 0.5em;
			}
		}
	}

	ul.query-rules-buckets-container {
		display: block;

		li.query-rules-buckets-bucket {
			display: inline-block;
			text-align: center;
			width: 15%;
			margin: 1em 1% 0.5em;

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}

			&.active div.bucket-box {
				position: relative;
				background: white;
				box-shadow: 0px 0px 11px 2px rgba(0, 255, 224, 0.33);
				border-color: #33bdb7;

				&:before {
					box-shadow: inherit;
					content: '';
					display: block;
					position: absolute;
					left: calc(50% - 10px);
					bottom: -11px;
					height: 20px;
					width: 20px;
					background: inherit;
					transform: rotate(45deg);
					z-index: -1;
				}

				&:after {
					content: '';
					display: block;
					position: absolute;
					left: calc(50% - 10px);
					bottom: -11px;
					height: 20px;
					width: 20px;
					background: inherit;
					transform: rotate(45deg);
					border-bottom-width: 1px;
					border-bottom-style: solid;
					border-right-width: 1px;
					border-right-style: solid;
					border-color: inherit;
					z-index: 0;
				}
			}

			div.bucket-box {
				background: #fafafa;
				padding: 1.5em 0;
				border: 1px solid #ddd;
				border-radius: 3px;

				.bucket-label {
				}

				.bucket-resource {
					text-transform: uppercase;
					font-size: 0.85em;
				}

				.bucket-resource-count {
					.count-number {
					}

					.count-percentage {
					}
				}
			}

			div.bucket-rulecount {
				margin: 1.5em 0 1em;
			}
		}
	}

	h2 {
		display: block;
		margin-bottom: 0.4em;

		span.query-rules-count {
			display: inline-block;
			margin: 0 0.1em;
		}
	}
	.queryAlgorithm {
		margin-bottom: 24px;
		display: flex;

		.query-algorithm-display {
			background: white;
			border: 1px solid #ccc;
			border-radius: 3px;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			padding: 0.5em;
			width: 100%;
			margin-right: 0.25em;
		}

		.query-algorithm-undo {
			padding: 0 10px;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}

	.button-group {
		margin: 0 0.5em 0 0em;

		button {
			border-radius: 0px;
			border-color: rgb(169, 169, 169);
		}

		& > .button:last-child:not(:first-child),
		& > .dropdown-toggle:not(:first-child) {
			border-bottom-left-radius: 0;
			border-top-left-radius: 0;
		}

		& > .button:first-child:not(:last-child):not(.dropdown-toggle) {
			border-bottom-right-radius: 0;
			border-top-right-radius: 0;
		}

		.button.icon-button,
		button.button.icon-button,
		a.button.icon-button,
		.button a:link.icon-button,
		.button a:focus.icon-button,
		.button a:visited.icon-button {
			padding: 0;
			font-size: 10pt;
			vertical-align: baseline;

			&.white-button:hover,
			&.white-button:focus {
				background-color: white;
			}

			&.light-gray-button {
				background: #aaa;
			}

			span {
				display: inline-block;
				padding: 0.1em 0.4em 0;
				color: #000;
			}

			i {
				display: inline-block;
				margin: 0.25em 0.2em 0.3em;
				color: #eee;
			}

			&:last-child i {
				margin-left: 0.2em;
			}
		}
	}
	.queryHelperTextOne {
		margin-top: 1em;
		margin-bottom: 0.5em;
		margin-left: 0.15em;
		margin: 24px 0;
	}
}

.queryBuilderContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 0 1.5em;
}

.stickyQuery {
	position: sticky;
	top: 48px;
	z-index: 10;
	background-color: white;
	&.queryButtonGroup {
		top: 108px;
	}
}

.querySectionBlock {
	border: 1px solid #cbcdce;
	border-radius: 8px;
	padding: 16px;
	overflow: unset;
	.query-section-icon {
		display: flex;
		align-items: center;
		font-size: 20px;
		font-weight: 650;
		gap: 8px;
		advanced-query-icon-directive {
			button {
				padding: 4px;
			}
		}
	}
}
