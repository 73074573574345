div.le-notice {
	pointer-events: all;
	div.messaging {
		position: fixed;
		top: 33%;
		left: 50%;
		transform: translate(-50%, -50%) rotateY(90deg);
		z-index: 10000;
		pointer-events: none;
		transition: all 333ms ease;
		transform-origin: center center;
		opacity: 0;
		border-radius: 4px;
		pointer-events: all;

		&.visible {
			opacity: 1;
			transform: translate(-50%, -50%) rotateY(0deg);
		}
	}
}
