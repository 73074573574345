// Gray
$black: #000000;
$dark-gray: #222222;
$jet: #333333;
$onyx: #464646;
$black-titanium: #676767;
$gun-metal: #888888;
$cobalt: #bbbbbb;
$silver: #d0d0d0;
$palladium: #e7e7e7;
$white-gold: #f1f1f1;
$pearl-white: #ffffff;

// Blue
$saphire: #467dba;
$saphire-two: #629acc;
$saphire-three: #3488c6;
$saphire-four: #26639b;
$aqua-marine: #6ce3ff;
$dark-blue: #0c385d;
$blue-summary-list: #093f65;
$blue-text: #467dba;
$deep-blue: #022339;
$secondary-nav-blue: #81909c;
$primary-active-nav: #497eb8;

// Orange
$amber: #f16a22;
$amber-two: #ff8c36;
$amber-three: #ffbd48;
$amber-four: #cc5200;
$gold: #ffbd48;
$gold-two: #ffda7d;
$gold-three: #ffe67b;
$gold-four: #ef9d18;
$orange-text: #ef6a31;

// Green
$jade: #6fbe4a;
$jade-two: #8ed162;
$jade-three: #a2e264;
$jade-four: #129e4e;

// Purple
$amethyst: #7b4bb2;
$amethyst-two: #8d66c6;
$amethyst-three: #9f6fe2;
$amethyst-four: #663399;

// Red
$ruby: #d0242f;
$ruby-two: #e83a3a;
$ruby-three: #f75959;
$ruby-four: #b50b20;
