@import "common/node_modules/@dnb-uux-design-system/design-tokens/build/styles/_variables.scss";

$generalMargin: 32px;

.personalizationComponent {
	min-width: 1200px;
	font-family: Avenir;
	color: $color-gray-primary;
	margin: 20px 64px;

	.commonHeader {
		&.headerType {
			margin-bottom: 24px;
			text-align: center;
		}
	}

	.personalizationBanner {
		margin-bottom: 24px;
	}

	.successBanner {
		margin-top: 24px;
	}

	.personalizationContainer {
		width: 100%;
		display: flex;
		flex-direction: column;
		margin-bottom: 100px;

		.personalizationContainerContent {
			width: 100%;
			margin-top: 20px;
		}

		.personalizationSegment {
			.segmentTitle {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 24px;

				.segmentTitleLeft {
					.segmentTitleRightNumber {
						display: flex;
						flex-direction: row;
						align-items: baseline;
						margin-right: 16px;
					}
				}

				.segmentTitleRight {
					display: flex;
					flex-direction: row;
					align-items: center;

					.segmentTitleSearch {
						display: flex;
						flex-direction: row;
					}
				}
			}
		}

		.personalizationAttributeGroup {
			margin-top: 24px;

			.attributeGroup {
				width: 500px;
				margin: 0 auto;

				.selectGroup {
					display: flex;
					align-items: center;
					margin-top: 8px;
					margin-bottom: 24px;

					.selectGroupLeft {
						button {
							span {
								width: 310px;
								text-overflow: ellipsis;
								white-space: nowrap;
								overflow: hidden;
							}
						}

						.selectGroupOption {
							width: 320px;
							white-space: normal;
						}
					}

					.attributesContent {
						display: flex;
						flex-direction: row;
						align-items: baseline;
					}
				}
			}

			.attributeGroupList {
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				align-items: center;

				.attributeCard {
					width: calc((100% - 80px) / 6);
					margin: 0 8px 12px;
					padding: 14px;

					&:nth-child(6n) {
						margin-right: 0;
					}

					&:nth-child(6n+1) {
						margin-left: 0;
					}

					.attributeCardContent {
						text-overflow: ellipsis;
						white-space: nowrap;
						overflow: hidden;
					}
				}
			}
		}
	}

	.personalizationButtonGroup {
		position: fixed;
		bottom: 0;
		left: 0;
		height: 86px;
		margin: 0 $generalMargin;
		width: calc(100% - #{2 * $generalMargin});
		background-color: $color-background-white;
		z-index: 10;

		.personalizationButtons {
			height: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.personalizationButtonsLeft {
				margin-left: 165px;
			}

			.personalizationButtonsRight {
				margin-left: auto;
			}
		}
	}

	.personalizationProcessing {
		margin: 108px auto;
		width: 360px;
		text-align: center;
	}
}
