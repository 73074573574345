@import '../../../../node_modules/@dnb-uux-design-system/design-tokens/build/styles/_variables.scss';

.container {
	background-color: $color-background-white;
	margin-top: 4px;
}

.scroller {
	border-bottom: $border-thin $color-gray-incidental;
}

div:global(.MuiTabs-flexContainer).flexContainer {
	border-bottom: none;
}
